<div class="container">
    <h2 mat-dialog-title> {{ updateFlag ? 'Update Product Plan' : 'Create Product Plan' }} </h2>
<form class="form" [formGroup]="productPricingForm" #formDirective="ngForm" novalidate>
    <div class="row">
        <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%;">
              <mat-label>Select a Product</mat-label>
              <mat-select formControlName="idProduct"  required>
                <mat-option
                  *ngFor="let product of productList; let i = index"
                  [value]="product.idProduct"
                >
                  {{ product?.productName }}</mat-option
                >
              </mat-select>
              <mat-error
            *ngIf="
              !productPricingForm.controls['idProduct'].valid &&
              productPricingForm.controls['idProduct'].touched">
            Product is mandatory
          </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%;">
              <mat-label>Select a Duration</mat-label>
              <mat-select formControlName="idProductDuration"  required>
                <mat-option
                  *ngFor="let duration of productDurationList; let i = index"
                  [value]="duration.idProductDuration"
                >
                  {{ duration?.durationName }} - ({{ duration?.duration}} Day)</mat-option
                >
              </mat-select>
              <mat-error
            *ngIf="
              !productPricingForm.controls['idProductDuration'].valid &&
              productPricingForm.controls['idProductDuration'].touched">
             Duration is mandatory
          </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%;">
              <mat-label>Select a Amount</mat-label>
              <mat-select formControlName="idProductAmount"  required>
                <mat-option
                  *ngFor="let amount of productAmountList; let i = index"
                  [value]="amount.idProductAmount"
                >
                  Rs.{{ amount?.amount }}</mat-option
                >
              </mat-select>
              <mat-error
            *ngIf="
              !productPricingForm.controls['idProductAmount'].valid &&
              productPricingForm.controls['idProductAmount'].touched">
             Amount is mandatory
          </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%;">
              <mat-label>Promotional Message</mat-label>
              <input matInput  formControlName="promoText" type="text">
            </mat-form-field>
          </div>
          <div class="col-md-8">
            <mat-form-field  appearance="outline" style="width: 100%;">
              <mat-label>Plan Description</mat-label>
              <textarea matInput  formControlName="planDescription" > </textarea>
              <mat-icon (click)="insertDesc()" matSuffix style="color: grey; cursor: pointer; font-size: 18px;
              padding-left: 10px;" title="Insert Default Description">file_copy</mat-icon>
             <mat-hint> Use '##' as a markdown symbol for creating bullet points in plan description.</mat-hint>
             <mat-error
             *ngIf="
               !productPricingForm.controls['planDescription'].valid &&
               productPricingForm.controls['planDescription'].touched">
              Plan description is mandatory
           </mat-error>
            </mat-form-field>
          </div>
    </div>
</form>

<div mat-dialog-actions>
    <div class="row" style="width: 100%;">
        <div class="col-sm-3" style="text-align: left;">
            <button mat-raised-button color="warn" mat-dialog-close >Close</button>
        </div>
        <div class="col-sm-3"></div>
        <div class="col-sm-3"></div>
        <div class="col-sm-3 " style="text-align: right;">
            <button mat-raised-button color="primary" [disabled]="!productPricingForm.valid" (click)="submit()">
              {{updateFlag ? 'Update':'Submit'}}
            </button>
        </div>
    </div>
  
  </div>

</div>
