import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { VideoService } from "./video.service";
import { ToastrService } from "ngx-toastr";
import { DomSanitizer } from "@angular/platform-browser";
import { ClipboardModule } from '@angular/cdk/clipboard'
import { catchError, map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
export interface Tags {
  name: string;
}

@Component({
  selector: "app-video-editing-model",
  templateUrl: "./video-editing-model.component.html",
  styleUrls: ["./video-editing-model.component.css"],
})
export class VideoEditingModelComponent implements OnInit {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  posterUrl: string;
  pdfURL: string;
  langPdfURL: string;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  Tagging: Tags[] = [{ name: "Demo" }];
  classData: any;
  CourseData: string[] = ["Academic Course", "Extra-Curricular", "Free-Videos"];
  subjectData: any;
  chapterData: any;

  typesOfList: string[] = [
    "Playlist-1",
    "Playlist-2",
    "Playlist-3",
    "Playlist-4",
    "Playlist-5",
  ];

  playlistFalg: boolean = false;
  linkPage: boolean = false;
  productLines: any;
  videoSrc: any;
  showVideoFlag: boolean;
  videoLinks: any = [];
  languageList: any;
  idProduct: any;
  idSyllabus :any;
  idState :any;
  productData: any;
  prodFlag: boolean;
  subFlag: boolean;
  videoUpdateFlag: any;
  academicUpdateVideoData: any;
  idOfflineVideoCourse: any;
  syllabusData: any;
  stateData:any;
  syllabusFlag :any;
  classFlag: boolean;
  generatedValue:string;
  generatedValueFlag:boolean = false;
  insertValue: any=[];
  insertValueFlag :boolean=false;
  progress:number;

  @ViewChild('pdfFile')
  pdfFile: ElementRef;


  filteredOptions: Observable<Headings[]>;
  headings: any = [];
  constructor(
    private _formBuilder: FormBuilder,
    public toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private serv: VideoService,
    private dialogRef: MatDialogRef<VideoEditingModelComponent>,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    if (this.data.offData != null) {
      this.videoUpdateFlag = true;

      if (this.data.linkUpload) {
        this.linkPage = true;
      }
      
      this.academicUpdateVideoData = this.data.offData;
      console.log("data",data);
      console.log(this.academicUpdateVideoData);
  
      this.firstFormGroup = this._formBuilder.group({
        topic: [this.academicUpdateVideoData.topic, [Validators.required,Validators.maxLength(40)]],
        productLine: ["5"],
        idSyllabus:[this.academicUpdateVideoData.idSyllabus, Validators.required],
        idState:[this.academicUpdateVideoData.idState, Validators.required],
        classStandard:[this.academicUpdateVideoData.idClassStandard, Validators.required],
        subject:[this.academicUpdateVideoData.idSubject, Validators.required],
        chapter:[this.academicUpdateVideoData.idSubjectChapter, Validators.required],
        videoSeqNum: [
          this.academicUpdateVideoData.videoSeqNumber,
          Validators.required,
        ],
        description: [this.academicUpdateVideoData.description],
        language: [""],
        videoLinkId: [""],
      });

      this.secondFormGroup = this._formBuilder.group({
        videoId:[this.academicUpdateVideoData.videoEnLink, Validators.required],
        heading:[this.academicUpdateVideoData.heading, Validators.required],
        question:[this.academicUpdateVideoData.question, Validators.required],
        answer:[this.academicUpdateVideoData.answer, Validators.required],
        pdfURL:[""]
        
        
      });
      this.pdfURL=this.academicUpdateVideoData.pdfUrl;

      this.thirdFormGroup = this._formBuilder.group({
        language: [null, Validators.required],
        videoId:[null, Validators.required],
        heading:[null, Validators.required],
        question:[null, Validators.required],
        answer:[null, Validators.required],
        insertText: [null],
        langPdf :[null]
      });

      this.idSyllabus =this.academicUpdateVideoData.idSyllabus;
      this.idState = this.academicUpdateVideoData.idState;
      this.classFlag=true;

      // debugger 
      this.idProduct =  this.academicUpdateVideoData.idProduct;
      this.videoLinks = this.academicUpdateVideoData.videoLinks;
      this.getAllSubjects(this.academicUpdateVideoData.idClassStandard);
      this.getSubjects(this.academicUpdateVideoData.idSubject);
      this.getAllSyllabus();
      this.getAllState();
      this.getAllproductLines();
      this.getAllClassStandard();
      this.getAllLanguage();
      this.getSubjects(this.academicUpdateVideoData.idSubject);

      
      this.idOfflineVideoCourse = this.data.idOfflineVideoCourse;
     this.getHeadings(this.academicUpdateVideoData.idSubjectChapter);
    } 
    
    else {
      if (this.data.linkUpload) {
        this.linkPage = true;
      }

      this.firstFormGroup = this._formBuilder.group({
        topic: ["", Validators.required],
        productLine: ["5"],
        idState:["",Validators.required],
        idSyllabus: ["", Validators.required],
        classStandard: ["", Validators.required],
        subject: ["", Validators.required],
        chapter: ["", Validators.required],
        videoSeqNum: ["",Validators.required],
        description: [null],
        
      });

      this.secondFormGroup = this._formBuilder.group({
        videoId: ["", Validators.required],
        heading: ["", [Validators.required, Validators.maxLength(100)]],
        question: ["", [Validators.required,Validators.maxLength(500)]],
        answer: ["", [Validators.required, Validators.maxLength(1500)]],   
        pdfURL:[""]   
      });

      this.thirdFormGroup = this._formBuilder.group({
        language: ["", Validators.required],
        videoId: ["", Validators.required],
        heading: ["", Validators.required],
        question: ["", Validators.required],
        answer: ["", Validators.required],  
        insertText: [""],
        langPdf:[""]

      });

      this.getAllproductLines();
      this.getAllClassStandard();
      this.getAllLanguage();
      this.getAllSyllabus();
      this.getAllState();

    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      this.Tagging.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  remove(fruit: Tags): void {
    const index = this.Tagging.indexOf(fruit);

    if (index >= 0) {
      this.Tagging.splice(index, 1);
    }
  }

  ngOnInit() {
    this.filteredOptions = this.secondFormGroup.controls["heading"].valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.headings.slice())
    );
  }

  getSubjects(event) {
    if (event != null && event != "") {
      this.chapterData = null;
      this.getProduct(
        5,
        this.firstFormGroup.controls["classStandard"].value,
        event,
        this.firstFormGroup.controls["idSyllabus"].value,
        this.firstFormGroup.controls["idState"].value
      );

      this.serv.getallChapter(event,  this.firstFormGroup.controls["classStandard"].value, this.firstFormGroup.controls["idSyllabus"].value,this.firstFormGroup.controls["idState"].value).subscribe(
        (res: any) => {
          if (res.data != null && res.statusCode == "200") {
            this.playlistFalg = true;
            this.chapterData = res.data;
          }
        },
        (error) => {
          this.toaster.error(error.error.message, "An Error Occured!");
        }
      );
    }
  }

  getAllproductLines() {
    this.serv.getallProductLines("OFFLINE_VIDEO").subscribe((res) => {
      if (res != null) {
        this.productLines = res;
      }
    });
  }

  getAllClassStandard() {
    this.serv.getClassStandard().subscribe((res: any = []) => {
      if (res.data != null) {
        this.classData = res.data;

        for (let i = 0;i<this.classData.length;i++) 
        {
          if (this.classData[i].idClassStandard == 4)
          {
            this.classData.splice(i,1);
          }
        }
      }
    });
  }

  getAllSyllabus() {
    this.serv.getAllSyllabus().subscribe((res: any = []) => {
      if (res.data != null) {
        this.syllabusData = res.data;

        for (let i = 0; i < this.syllabusData.length; i++) {
          if (this.syllabusData[i].syllabusName === "NA") {
            this.syllabusData.splice(i, 1);
          }
        }
      }
    });
  }
  getAllState() {
    this.serv.getAllState().subscribe((res: any = []) => {
      if (res.data != null) {
        this.stateData = res.data;
        for (let i = 0; i < this.stateData.length; i++) {
          if (this.stateData[i].state === "NA") {
            this.stateData.splice(i, 1);
          }
        }
      }
    });
  }

  getAllSubjects(event) {
    this.subjectData = [];
    this.chapterData =null;
    this.subFlag = false;
    let idSyb = this.firstFormGroup.controls["idSyllabus"].value;
    let idState = this.firstFormGroup.controls["idState"].value;
    if (idSyb == null)
    {
      this.toaster.error("Please select any syllabus.");
      return 
    }

      if (idState == null)
    {
      this.toaster.error("Please select any state.");
      return 
    }
    if (event != null) {
      this.serv.getAcademicSubjects(event,idSyb,idState).subscribe(
        (res: any = []) => {
          if (res.data != null) {
            this.subjectData = res.data;
            this.subFlag = true;
            if (!this.videoUpdateFlag)  this.firstFormGroup.controls["subject"].setValue(-1);
          }
        },
        (error) => {
          this.toaster.error(error.error.message, "An Error Occured!");
        }
      );
    }
  }

  createVideo() {
    if (this.videoUpdateFlag) {
      if (this.firstFormGroup.valid && this.secondFormGroup.valid) {
        let request = {
          idProduct: this.idProduct,
          topic: this.firstFormGroup.controls["topic"].value,
          description: this.firstFormGroup.controls["description"].value,
          idProductGroup: -1,
          videoEnLink: this.secondFormGroup.controls["videoId"].value,
          idSubjectChapter: this.firstFormGroup.controls["chapter"].value,
          idSubject: this.firstFormGroup.controls["subject"].value,
          videoSeqNumber: this.firstFormGroup.controls["videoSeqNum"].value,
          idClassStandard: this.firstFormGroup.controls["classStandard"].value,
          videoLinks: this.videoLinks,
          heading: this.secondFormGroup.controls["heading"].value,
          question: this.secondFormGroup.controls["question"].value,
          answer: this.secondFormGroup.controls["answer"].value,
          pdfUrl:this.pdfURL,
          language: this.thirdFormGroup.controls["language"].value
        };

          console.log("object",request);
        this.serv.updateVideo(request, this.idOfflineVideoCourse).subscribe(
          (res: any = []) => {
            if (res.data != null) {
              this.dialogRef.close({ data: res.data });
            }
          },
          (error) => {
            this.toaster.error(error.error.message, "An Error Occured!");
          }
        );
      }
    } else {
      if (this.firstFormGroup.valid && this.secondFormGroup.valid) {
        
        let request= {
          idProduct:this.idProduct,
          topic:this.firstFormGroup.controls["topic"].value ,
          description:this.firstFormGroup.controls["description"].value ,
          idProductGroup:-1 ,
          videoEnLink:this.secondFormGroup.controls["videoId"].value ,
          idSubjectChapter:this.firstFormGroup.controls["chapter"].value ,
          idSubject: this.firstFormGroup.controls["subject"].value,
          videoSeqNumber:this.firstFormGroup.controls["videoSeqNum"].value ,
          idClassStandard:this.firstFormGroup.controls["classStandard"].value ,
          heading:this.secondFormGroup.controls["heading"].value ,
          question:this.secondFormGroup.controls["question"].value ,
          answer: this.secondFormGroup.controls["answer"].value,
          pdfUrl:this.pdfURL,
          videoLinks: this.videoLinks
      }
      console.log("create-obj",request);
        this.serv.createVideo(request).subscribe(
          (res: any = []) => {
            if (res.data != null) {
              this.dialogRef.close({ data: res.data });
            }
          },
          (error) => {
            this.toaster.error(error.error.message, "An Error Occured!");
          }
        );
      }
    }
  }

  getVideoStreaminginfo(): any {
    let videoId = this.secondFormGroup.controls["videoId"].value;
    this.showVideoFlag = false;
    if (videoId != null && videoId != "") {
      this.serv.getVideoStreaminginfo(videoId).subscribe(
        (res: any) => {
          if (res.data != null) {
            this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
              "https://player.vdocipher.com/playerAssets/1.x/vdo/embed/index.html#otp=" +
                res.data.otp +
                "&playbackInfo=" +
                res.data.playbackInfo
            );
            this.showVideoFlag = true;
            return true;
          }
        },
        (error) => {
          this.toaster.error(error.error.message, "An Error Occured!");
          this.secondFormGroup.controls["videoId"].setErrors({ required: true });
        }
      );
    }
  }

  getAllLanguage() {
    this.serv.getAllLanguages().subscribe((res: any = []) => {
      if (res.data != null) {
        this.languageList = res.data;
      }
    });
  }

  addVideoLink() {
    if(this.thirdFormGroup.valid){
    let lang = this.thirdFormGroup.controls["language"].value;
    let vid = this.thirdFormGroup.controls["videoId"].value;
    let head =this.thirdFormGroup.controls["heading"].value;
    let ques = this.thirdFormGroup.controls["question"].value;
    let ans = this.thirdFormGroup.controls["answer"].value;
    let pdfUrl=this.langPdfURL;
    if (lang == null || lang == "") {
      this.toaster.error("Please Select Vali Language", "Valid Data Required");
      return;
    }

    if (vid == null || vid == "") {
      this.toaster.error("Video Id Cannot Be Empty!", "Valid Data Required");
      return;
    }

    if (head == null || head == "") {
      this.toaster.error("Heading Cannot Be Empty!", "Valid Data Required");
      return;
    }

    if (ques == null || ques == "") {
      this.toaster.error("Question Cannot Be Empty!", "Valid Data Required");
      return;
    }

    if (ans == null || ans == "") {
      this.toaster.error("Answer Id Cannot Be Empty!", "Valid Data Required");
      return;
    }

    // check combinations here
    for (let i = 0; i < this.videoLinks.length; i++) {
      if (this.videoLinks[i].language === lang) {
        this.toaster.error(
          "Duplicate Language Data found!",
          "Valid Data Required"
        );
        return;
      }
    }

    this.serv.getVideoStreaminginfo(vid).subscribe(
      (res: any) => {
        if (res.data != null) {
          // this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vdocipher.com/playerAssets/1.x/vdo/embed/index.html#otp="+res.data.otp+"&playbackInfo="+res.data.playbackInfo);
          // this.showVideoFlag=true;
          let object = {
            language: lang,
            link: vid,
            heading: head,
            question: ques,
            answer : ans ,
            pdfURL: this.langPdfURL
          };
          this.videoLinks.push(object);
          this.thirdFormGroup.reset();
          this.thirdFormGroup.markAsPristine();
          this.thirdFormGroup.markAsUntouched();
          this.thirdFormGroup.clearValidators();
          this.thirdFormGroup.updateValueAndValidity()

        }
      },
      (error) => {
        console.warn(error);
        this.toaster.error(error.error.message, "An Error Occured!");
        this.thirdFormGroup.controls['videoId'].setErrors({ required: true });
      }
    );
    }

    else{
      return ;
    }
  }

  removeSub(subChip, index) {
    this.videoLinks.splice(index, 1);
  }

  getProduct(idProductLine, idClassStandard, idSubject, idSyllabus, idState) {
    this.prodFlag = false;

    this.serv
      .getProductByProductLine(idProductLine, idClassStandard, idSubject, idSyllabus, idState)
      .subscribe(
        (res: any = []) => {
          if (res.data != null) {
            this.productData = res.data;
            console.log(this.productData);
            this.idProduct = res.data.idProduct;
            console.log(this.idProduct)
            this.prodFlag = true;
          }
        },
        (error) => {
          console.warn(error);
          this.toaster.error(error.error.message, "An Error Occured!");
        }
      );
  }
  setSyllabus(event){
    this.idSyllabus = event;
    this.classFlag = true;
    this.firstFormGroup.controls["classStandard"].reset();
    this.subFlag = false;
    this.playlistFalg = false;
    this.firstFormGroup.controls["subject"].reset();
    this.productData = null;
    this.prodFlag = false;
    this.firstFormGroup.controls["chapter"].reset();
    this.idProduct = null;
  }

  setState(event){
    this.idState = event;
    this.syllabusFlag = true;
    this.firstFormGroup.controls["idSyllabus"].reset();
    this.classFlag = false;
    this.firstFormGroup.controls["classStandard"].reset();
    this.subFlag = false;
    this.playlistFalg = false;
    this.firstFormGroup.controls["subject"].reset();
    this.productData = null;
    this.prodFlag = false;
    this.firstFormGroup.controls["chapter"].reset();
    this.idProduct = null;
  }
  generateStringValue()
  { 
   let heading =  this.secondFormGroup.controls["heading"].value;
   let question =  this.secondFormGroup.controls["question"].value;
   let answer =  this.secondFormGroup.controls["answer"].value;

    if ( (heading != null && heading !="") && (question != null && question !="") 
    && (answer != null && answer !="") )
    { 
      this.generatedValue =  heading +":::"
      + question +":::"
      + answer ;
      this.generatedValueFlag = true;
    }
   
  }

  insertText()
  { 
   let text = this.thirdFormGroup.controls["insertText"].value;

   if(this.insertText !=null){
     let insertValue:any= text.split(":::");
     let heading :any = insertValue[0];
     let question:any = insertValue[1];
     let answer :any = insertValue[2];  
    this.thirdFormGroup.controls["heading"].setValue(heading);
    this.thirdFormGroup.controls["question"].setValue(question);
    this.thirdFormGroup.controls["answer"].setValue(answer);
    
   }
   
  }
  onNavigateTranslator(){
    window.open("https://translate.google.co.in/", "_blank");
}

uploadPdf(fileInput:any){

  this.pdfURL=null;
  const file = (fileInput.target as HTMLInputElement).files[0];
    console.log(file);
    var formData: any = new FormData();
    formData.append("liveClassPdf", file);
    console.log("formdata",formData);
    this.http.post('/api/v1/liveClass/uploadPdf', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)).subscribe((event: HttpEvent<any>) => {
        
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            console.log('Pdf Uploaded Successfully!', event.body);
            if (event.body.statusCode === 201 || event.body.statusCode === 200) {
              this.toaster.success("Pdf Uploaded Successfully!", "Uploaded");
              this.pdfURL= event.body.data;
              console.log("pdf",this.pdfURL);
              // window.location.reload();
            }
            else {
              this.toaster.error(event.body.message, "Error");
            }
          // setTimeout(() => {
          //   this.progress = 0;
          // }, 1500);
  
        }
      })
      
}


uploadLanguagePdf(fileInput:any){

  this.langPdfURL=null;
  const file = (fileInput.target as HTMLInputElement).files[0];
    console.log(file);
    var formData: any = new FormData();
    formData.append("liveClassPdf", file);
    console.log("formdata",formData);
    this.http.post('/api/v1/liveClass/uploadPdf', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)).subscribe((event: HttpEvent<any>) => {
        
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            console.log('Pdf Uploaded Successfully!', event.body);
            if (event.body.statusCode === 201 || event.body.statusCode === 200) {
              this.toaster.success("Pdf Uploaded Successfully!", "Uploaded");
              this.langPdfURL= event.body.data;
              console.log("Laanguage pdf",this.langPdfURL);
              // window.location.reload();
            }
            else {
              this.toaster.error(event.body.message, "Error");
            }
          // setTimeout(() => {
          //   this.progress = 0;
          // }, 1500);
  
        }
      })
      
}
errorMgmt(error: HttpErrorResponse) {
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}

removepdf() {
  this.pdfURL = null;
  this.secondFormGroup.controls["pdfURL"].setValue("");
  this.secondFormGroup.controls["pdfURL"].reset();
}

getHeadings(idChapter)
{
  if ( this.idProduct == null)
  {
    this.toaster.warning("no product selected");
    return null;
  }
  this.headings  = [];
  this.http.get("/api/v1/offlineCourse/headings?idProduct="+this.idProduct+"&idSubjectChapter="+idChapter).subscribe(
    (res: any) => {
     this.headings = res.data;
     console.log(this.headings); 
    },
    (err: any) => {
      this.headings = [];
    }
  );

}

displayFn(heading: Headings): string {
  return heading && heading.heading ? heading.heading : '';
}
private _filter(name: string): Headings[] {
  const filterValue = name.toLowerCase();

  return this.headings.filter(option => option.heading.toLowerCase().includes(filterValue));
}

}


export interface Headings 
{
  heading:string;
}