import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
/**
------- Public Members -------

public autoAdvancePlaylist: boolean
whether to start playing the next video in queue

public autoplay: Boolean
public bufferLength: number
the player position till which video will play if network connection breaks

public currentTime: number
this is a read-only property currently. Use the seek method to change currentTime.

public ended: boolean
public errorMessage: string
public fullscreen: boolean
public hasError: boolean
public idle: boolean
public isBuffering: boolean
public loop: Boolean
public muted: boolean
if the volume is 0 or muted

Note that the volume can be non-zero when muted is true

public playbackRate: number
public queueLength: number
number of videos in queue check queuePosition

public queuePosition: number
the position of current video in the queue starting from 0. If a video is playing and addToQueue method is called, the queueLength becomes 2 and queuePosition is still 0. When video goes to next video, the queuePosition will become 1;

public status: *
Status can be [-1, 0, 1, 2, 3]

-1: video is still loading
0: ready to play
1: playing
2: paused
3: ended
public get statusText: String
can be one of loading,loaded,playing,paused,ended

public title: *
public totalCovered: number
public totalCoveredArray: number[]
public totalPlayed: number
public volume: number





 --------Public functions------


public	
addEventListener(event: string, callback: function(eventName: string, payload: object): void): function

add an event listener Note that you can add multiple event listeners to the same event and they will all be called in the order they were added list of events

load
play
pause
seeking
progress
ended
changeVolume
mpmlLoad
public	
addToQueue(embedInfo: embedInfoType)

adds a new video to the end of queue

public	
canPlayType(type: String): string

always false the player object like to emulate an actual HTML5 player but is not capable to directly play third-party videos.

public	
chooseTrack(trackId: number)

selects a track to play Not implemented

public	
emitEvent(eventName: String, eventPayload: any?)

emit event.

public	
injectThemeHtml(htmlStr: String, warn: boolean)

inject html as a string into the theme.

public	
load(embedInfo: embedInfoType)

leaves the queue unchanged but starts playing another video the queuePosition remains the same.

public	
mute()

mutes the video

public	
nextMedia()

starts playing the next video in queue

public	
pause()

pauses a playing video

public	
play()

starts the video or resume a paused video

public	
playIndexFromList(idx: number)

plays a particular video from queue given its index

public	
previousMedia()

starts playing the previous video in queue

public	
seek(position: number)

Seeks to a specified time in the video.

public	selectQualityTrack(trackId: Number)

chooses quality by trackId

public	
setAdaptive(isAdaptive: boolean)

enable auto-adaptive switching of bitrates

public	
setPlaybackRate(rate: number)

changes the speed of player among the available possible speed

public	
setVolume(volume: number)

sets the volume of video.

public	
unmute()

*/

// creating global property
declare var VdoPlayer: any;
@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.css"],
})
export class VideoPlayerComponent implements OnInit {
  // acessing id tag through viewChild
  @ViewChild("embedBox") MyDOMElement: ElementRef;
  video: any;
  public videoMeta: any;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data != null) {
      this.videoMeta = this.data;
    }

    let refreshId = setInterval(() => {
      if (this.video.status == 2) {
      }

      if (this.video.status == 3) {
        clearInterval(refreshId);
        this.dialogRef.close();
      }
    }, 1000);
  }

  ngOnInit(): void {
    // creating new instance of videoPlaye
    this.video = new VdoPlayer({
      otp: this.videoMeta.video_otp,
      playbackInfo: btoa(
        JSON.stringify({
          videoId: this.videoMeta.video_id,
        })
      ),
      theme: this.videoMeta.video_theme,
      // the container can be any DOM element on website
      container: document.querySelector("#embedBox")
      
    });
    
    //use this event listener to call public functions
    this.video.addEventListener(`load`, () => {
      this.video.play();
      this.video.seek(this.videoMeta.prev_position_timing);
    });
    
    let formContainer;
  

    this.video.addEventListener('mpmlLoad', () => {
      this.video.injectThemeHtml('<div class="form-container"></div>')
      formContainer = document.querySelector('.form-container');
    });
  /*
  this for demo testing 
    this.video.addEventListener('progress', () => {
      
    
      if (this.videoMeta.videoDuration - this.video.currentTime <= 7) {
        this.video.pause();
        createOptionOption();
      }
    });
 
    
    */
    let createOptionOption = () => {
      formContainer.innerHTML = "";
      let form = document.createDocumentFragment();
      let question = document.createElement('p');
      question.innerHTML = 'This is demo question element created for testing.';
      let option1 = document.createElement('button');
      option1.innerHTML = 'Seek to 25th second';
      let option2 = document.createElement('button');
      option2.innerHTML = 'Seek to 33rd second';
      option1.className = 'button clickable';
      option2.className = 'button clickable';
      form.appendChild(question);
      form.appendChild(option1);
      form.appendChild(option2);
      let submitOption = (answer) => {
        console.log('submitted answer to server: ', answer);
        this.video.play();
        if (answer === 1) this.video.seek(25);
        if (answer === 2) this.video.seek(33);
        formContainer.style.display = 'none';
      };
      option1.addEventListener('click', () => submitOption(1));
      option2.addEventListener('click', () => submitOption(2));
      formContainer.appendChild(form);
      formContainer.style.display = 'initial';
    };


    
  }
  ngOnDestroy() {
    let time = this.video.currentTime;
    this.dialogRef.close({ data: time });
  }
}
