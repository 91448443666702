<div class="container">
    <h2 mat-dialog-title>Create Product Duration </h2>
<form class="form" [formGroup]="productDurationForm" #formDirective="ngForm" novalidate>
    <div class="row">
        <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%;">
              <mat-label>Enter Product duration</mat-label>
              <input matInput  formControlName="duration" type="text">
              <mat-hint> Number of days.</mat-hint>
            </mat-form-field>
            <mat-error
             *ngIf="
               !productDurationForm.controls['duration'].valid &&
               productDurationForm.controls['duration'].touched">
               Product duration is mandatory
           </mat-error>
          </div>

          <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%;">
              <mat-label>Enter  Duration Name</mat-label>
              <input matInput  formControlName="durationName" type="text">
              <mat-error
             *ngIf="
               !productDurationForm.controls['durationName'].valid &&
               productDurationForm.controls['durationName'].touched">
               Product Duration Name is mandatory
           </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%;">
              <mat-label>Enter Duration Code</mat-label>
              <input matInput  formControlName="durationCode" type="text">
              <mat-error
             *ngIf="
               !productDurationForm.controls['durationCode'].valid &&
               productDurationForm.controls['durationCode'].touched">
              Product durationCode is mandatory
           </mat-error>
            </mat-form-field>
          </div>

    </div>


</form>

<div mat-dialog-actions>
    <div class="row" style="width: 100%;">
        <div class="col-sm-3" style="text-align: left;">
            <button mat-raised-button color="warn"  mat-dialog-close>Close</button>
        </div>
        <div class="col-sm-3"></div>
        <div class="col-sm-3"></div>
        <div class="col-sm-3 " style="text-align: right;">
            <button mat-raised-button color="primary" (click)="submit()"> {{updateFlag ? 'Update':'Submit'}}</button>
        </div>
    </div>
   
  </div>

</div>
