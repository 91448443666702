import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AddQuizQuestionService {
  public baseUrl: any = "/api/v1/quiz";
  httpOptions: any;

  constructor(private http: HttpClient,
    private router: Router,
    private toaster: ToastrService) {
      let accesstoken = localStorage.getItem("accessToken");
      if (
        accesstoken != null &&
        accesstoken != "" &&
        typeof accesstoken != undefined
      ) {
        this.httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
            "No-Auth": "True",
          }),
        };
      }
     }

     createQuestionandAnswers(idQuiz,udm) {
      return this.http.post("/api/v1/quiz/"+idQuiz+"/question/create", udm,this.httpOptions);
    }

    getQuizDetails(idClassStandard,idProductLine,udm) {
      return this.http.post("/api/v1/quiz/getQuizDetails/academic/"+idClassStandard+"/"+idProductLine, udm,this.httpOptions);
    }

    getAllsyllabus() {   
      return this.http.get("/api/v1/lead/syllabusLists");
    }
  
}
