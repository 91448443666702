import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingFooterComponent } from '../landing/landing-footer/landing-footer.component';
import { LandingNavigationComponent } from '../landing/landing-navigation/landing-navigation.component';
import { MatMenuModule } from '@angular/material/menu';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimeFormat } from '../offline-courses/TimeFormat';
import { WeOfferComponent } from '../landing/we-offer/we-offer.component';
import { MobileDownloadComponent } from '../landing/mobile-download/mobile-download.component';
import { MatCardModule } from '@angular/material/card';
import { SafeHtmlPipe } from '../pipes/InnerhtmlStyle.pipe';

@NgModule({
  imports: [
    CommonModule, 
    MatMenuModule,
    NgbModule,
    MatCardModule,
  ],
  declarations: [LandingFooterComponent,LandingNavigationComponent,SafeHtmlPipe,MobileDownloadComponent],
  exports:[LandingFooterComponent,LandingNavigationComponent,MobileDownloadComponent,SafeHtmlPipe],
  
})
export class SharedmoduleModule { }
