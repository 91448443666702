import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import Chart from 'chart.js';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;



@Component({
  selector: 'app-user-analytics-info',
  templateUrl: './user-analytics-info.component.html',
  styleUrls: ['./user-analytics-info.component.css'],

})
export class UserAnalyticsInfoComponent implements OnInit, AfterViewInit {
  analytics: any;
  fromTime: any;
  toTime: any;
  startDate:any;
  endDate:any;
  isPickerClosed:boolean=true;
  userLiveCountData: any[];
  liveCountChart: Chart;
  idHour: any;
  hour:any;
  maxDate: Date;
  minDate:Date;
  from: string = 'Choose from Month and year'; // Define and assign values
  to: string = 'Choose to Month and year'; 
  // chosenDateFrom: any = '';
  // chosenDateTo: any = '';
  selectedTimeType: string = 'hourly';
  selectedHour:any;
  datasets = [
    { label: 'Mobile Client', id: 'mobileClientBarChart' },
    { label: 'Web Client', id: 'webClientBarChart' },
    { label: 'Mobile Client Idle', id: 'mobileClientIdleBarChart' },
    { label: 'Web Client Idle', id: 'webClientIdleBarChart' },
    { label: 'Total Metrics', id: 'totalMetricsBarChart' },
  ];
  hourData = [
   
    {
      idHour: 3,
      hour: 'past 3 hrs',
    },
    {
      idHour: 6,
      hour: 'past 6 hrs',
    },
    {
      idHour: 12,
      hour: 'past 12 hrs',
    },
    {
      idHour: 24,
      hour: 'past 24 hrs',
    },
  ];
  dateRange = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });



 

  constructor(private http: HttpClient, public datepipe: DatePipe,
  ) {
    this.maxDate = new Date();
    this.minDate = new Date ("2024-01-01")

  }
  onClose() {
    this.isPickerClosed= true
    this.dateRangeChange(this.startDate, this.endDate);

    
  }
  ngOnInit(): void {
    const currentTime = moment();

    this.toTime = currentTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    this.fromTime = currentTime.subtract(6, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');;

    this.idHour = 6
    this.getUserLiveCountData();

    this.dateRange.valueChanges.subscribe(range => {
      this.startDate = range.start;
      this.endDate = range.end;
      
    });
  }

  ngAfterViewInit() {
    // Implement any post-view initialization logic here
  }

  setHour(event) {
    
    this.selectedTimeType = 'hourly'
    this.selectedHour = event
    const currentTime = moment();

    this.toTime = currentTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    this.fromTime = currentTime.subtract(event, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');;


      this.getUserLiveCountData();

    
  }
  downloadCanvas() {
    const canvas = document.getElementById('liveCountChart') as HTMLCanvasElement;
    const link = document.createElement('a');
    link.href = canvas.toDataURL('image/png');
    link.download = 'chart.png';
    link.click();
  }
  selectedDay(event: MatDatepickerInputEvent<Date>) {
    this.hour = 24;
  
    // Get the selected day as local date and time
    const selectedDayLocal = moment(event.value).set({ hour: 23, minute: 59, second: 59 });
    
    // Convert to UTC
    const selectedDayUtc = selectedDayLocal.clone();
    
    // Calculate the previous day in UTC
    const previousDayUtc = selectedDayUtc.clone().subtract(1, 'days');
  
    const selectedDayFormatted = selectedDayUtc.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const previousDayFormatted = previousDayUtc.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  
  
    this.toTime = selectedDayFormatted;
    this.fromTime = previousDayFormatted;
    this.getUserLiveCountData();
  }
  
 dateRangeChange(start: Moment, end: Moment) {
  this.isPickerClosed = false
  // Ensure the start date is interpreted as local and set time to start of the day
  const startLocal = moment(start).startOf('day').local();
  
  // Ensure the end date is interpreted as local and set time to end of the day
  const endLocal = moment(end).endOf('day').local();
  
  // Convert local dates to UTC
  const startUtc = startLocal.clone().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  const endUtc = endLocal.clone().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');



  this.fromTime = startUtc;
  this.toTime = endUtc;
  this.getUserLiveCountData();
}

  
handleFromDateSelected(date) {
  const timestamp = date
  .startOf('month')
  .hour(23)
  .minute(59)
  .second(0)
  .millisecond(0)
  .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');   
   console.log('Timestamp in ISO 8601 Format:', timestamp);
  this.fromTime = timestamp
  if(this.toTime!=='')
    this.getUserLiveCountData()

  // Process the selected date as needed in the parent component
}
handleDateToSelected(date){
  const timestamp = date
  .startOf('month')
  .hour(23)
  .minute(59)
  .second(0)
  .millisecond(0)
  .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');   
   console.log('Timestamp in ISO 8601 Format:', timestamp);
  if(this.fromTime !== ''){
    this.toTime = timestamp
    this.getUserLiveCountData();

  }else{
    alert("Please select from")
  }    


}

  Refresh() {}

  getUserLiveCountData() {
    this.http.get(`/api/v1/analytics/getUserLiveCountTimelyBasis?type=${this.selectedTimeType}&from=${this.fromTime}&to=${this.toTime}`).subscribe(
      (res: any) => {
        if (res['statusCode'] === 200 && res.data) {
          //this.userLiveCountData = res.data.slice(0, 7);
          this.userLiveCountData = res.data

          this.displayLiveCountLineChart();
          this.fromTime =''
          this.toTime =''
        }
      },
      (err) => {
        console.error(err.message);
      }
    );
  }

  displayLiveCountLineChart() {
    const dateList = this.userLiveCountData.map((item) => item.createdAt);
    const datasets = [
      { label: 'Mobile Client', data: this.userLiveCountData.map((item) => item.mobileClient) },
      { label: 'Web Client', data: this.userLiveCountData.map((item) => item.webClient) },
      { label: 'Mobile Client Idle', data: this.userLiveCountData.map((item) => item.mobileClientIdle) },
      { label: 'Web Client Idle', data: this.userLiveCountData.map((item) => item.webClientIdle) },
      { label: 'Total Metrics', data: this.userLiveCountData.map((item) => item.totalMetrics) },
    ];

    if (this.liveCountChart) {
      this.liveCountChart.destroy();
    }

    const liveCountCanvas: HTMLCanvasElement = document.getElementById('liveCountChart') as HTMLCanvasElement;
    const liveCountCtx = liveCountCanvas.getContext('2d');
    let timeUnit 
    switch (this.selectedTimeType) {
      case 'hourly':
         timeUnit = 'hour';
        break;
      case 'Daily':
         timeUnit = 'day';
        break;
      case 'Weekly':
         timeUnit = 'week';
        break;
      case 'Monthly':
        timeUnit = 'month';
        break;
      default:
    }

    const tooltipFormat = this.selectedTimeType === 'hourly' ? 'HH:mm' : 'll HH:mm';

    this.liveCountChart = new Chart(liveCountCtx, {
      type: 'line',
      data: {
        labels: dateList,
        datasets: datasets.map((dataset) => ({
          label: dataset.label,
          data: dataset.data,
          fill: true,
          backgroundColor:this.getColor(dataset.label)+'80',
          borderColor: "#526D82",
          borderWidth: 1,
        })),
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: timeUnit,
                displayFormats: {
                  hour: 'DD/MM/YYYY HH:mm', // For hourly data
                  day: 'DD/MM/YYYY', // For daily data
                  week: 'DD-MMM-YY', // For weekly data
                  month: 'MM/YYYY', // For monthly data
                },
                tooltipFormat: tooltipFormat,
              },
              scaleLabel: {
                display: true,
                labelString: 'Time',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: true,
                labelString: 'Count',
              },
            },
          ],
        },
      },
    });
  }

  getColor(status) {
    let color
    switch(status){
      case 'Mobile Client':
            color='#E6B9A6'
            break
      case 'Web Client':
            color='#7D8ABC'
            break
      case 'Mobile Client Idle':
            color= '#BBE9FF'
            break
      case 'Web Client Idle':
            color ='#006989'
            break
      case 'Total Metrics':
            color='#80AF81'
            break
    }
    return color;
  }

  changeTimeType(timeType: string) {
    this.selectedHour = '';
    this.idHour = -1;
    this.selectedTimeType = timeType;
    if(timeType === 'hourly'){
      const currentTime = moment();

      this.toTime = currentTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  
      this.fromTime = currentTime.subtract(6, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');;
  
      this.idHour = 6
    }
   else if(timeType === 'Daily'){
      // Get the current date and time
const currentDate = moment().startOf('day');

// Calculate the previous day and the day before that
const previousDay = currentDate.clone().subtract(7, 'days');

// Convert to UTC and format
const previousDayUtc = previousDay.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
const currentDay = currentDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
this.fromTime = previousDayUtc
this.toTime = currentDay

    }else if(timeType==='Weekly'){

// Get the current date and time
const currentTime = moment();

// Calculate the date 7 weeks ago from the current time
const startTime = currentTime.clone().subtract(4, 'weeks');

// Convert to UTC and format
const startTimeUtc = startTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
const currentTimeUtc = currentTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
this.fromTime = startTimeUtc
this.toTime = currentTimeUtc

    }else if(timeType === 'Monthly'){
      const currentTime = moment();

// Calculate the date 7 weeks ago from the current time
const startTime = currentTime.clone().subtract(6, 'months');

// Convert to UTC and format
const startTimeUtc = startTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
const currentTimeUtc = currentTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
this.fromTime = startTimeUtc
this.toTime = currentTimeUtc
    }
    this.getUserLiveCountData();
  }
}
