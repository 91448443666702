import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { CreateQuizService } from "src/app/quiz/create-quiz/create-quiz.service";

@Component({
  selector: "app-add-subject-chapter",
  templateUrl: "./add-subject-chapter.component.html",
  styleUrls: ["./add-subject-chapter.component.css"],
})
export class AddSubjectChapterComponent implements OnInit {
  subjectChapterForm: FormGroup;
  subjectData: any;
  classStandardList: any;
  syllabusData: any;
  states: any;
  idClassStandard: any;
  idSubject: any;
  idState: any;
  idSyllabus: any;
  updateFlag: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddSubjectChapterComponent>,
    private quizSer: CreateQuizService
  ) {
    console.log(data);
    if (data.updateFlag) {
      this.updateFlag = true;
      this.updateForm();
      this.getAllSubjects();
    } else {
      this.idClassStandard =
        this.data.idClassStandard == null
          ? null
          : this.data.idClassStandard.idClassStandard;
      this.idSubject =
        this.data.idSubject == null ? null : this.data.idSubject.idSubject;
      this.idState =
        this.data.idState == null ? null : this.data.idState.idState;
      this.idSyllabus =
        this.data.idSyllabus == null ? null : this.data.idSyllabus.idSyllabus;
      this.createForm();


      if (this.idClassStandard != null && this.idState != null && this.idSyllabus != null)
      {
        this.getAllSubjects();
      }
    }
    this.getAllStates();
    this.getAllClassStandard();
    this.getSyllabus();

   

  
    
  }

  ngOnInit(): void {}

  createForm() {
    this.subjectChapterForm = this.formBuilder.group({
      idClassStandard: [this.idClassStandard, Validators.required],
      idSubject: [this.idSubject, Validators.required],
      idState: [this.idState, Validators.required],
      idSyllabus: [this.idSyllabus, Validators.required],
      sortOrder: [null, Validators.required],
      chapterName: [null, [Validators.required, Validators.maxLength(100)]],
    });
  }

  getAllSubjects() {

    let idClassStandard = this.subjectChapterForm.controls["idClassStandard"].value;
    let idState = this.subjectChapterForm.controls["idState"].value;
    let idSyllabus = this.subjectChapterForm.controls["idSyllabus"].value;

    if (idSyllabus == null)
    {
      this.toaster.error("Please Select Syllabus", "An Error Occured!");
      return;
    }

    if (idState == null)
    {
      this.toaster.error("Please Select State", "An Error Occured!");
      return;
    }

    if (idClassStandard == null) 
    {
      this.toaster.error("Please Select Class Standard", "An Error Occured!");
      return;
    }


    this.quizSer.getAcademicSubjects(idClassStandard, idSyllabus, idState).subscribe(
      (res: any = []) => {
        this.subjectData = res.data;
        console.log("subjects");
        console.log(res.data);
      },
      (error) => {
        this.toaster.error(error.error.message, "An Error Occured!");
      }
    );
  }

  getAllClassStandard() {
    this.quizSer.getClassStandard().subscribe(
      (res: any = []) => {
        this.classStandardList = res.data;
        for (let i = 0; i < this.classStandardList.length; i++) {
          if (this.classStandardList[i].idClassStandard == 4) {
            this.classStandardList.splice(i, 1);
          }
        }
      },
      (error) => {
        this.toaster.error(error.error.message, "An Error Occured!");
      }
    );
  }

  getSyllabus() {
    this.quizSer.getAllsyllabus().subscribe((result: any) => {
      this.syllabusData = result.data;
      for (let i = 0; i < this.syllabusData.length; i++) {
        if (this.syllabusData[i].idSyllabus == 4) {
          this.syllabusData.splice(i, 1);
        }
      }
    });
  }

  getAllStates() {
    this.quizSer.getAllState().subscribe((result: any) => {
      this.states = result.data;
      for (let i = 0; i < this.states.length; i++) {
        if (this.states[i].idState == 6) {
          this.states.splice(i, 1);
        }
      }
    });
  }

  callSubmit() {
    if (this.subjectChapterForm.valid) {
      if (this.updateFlag) {
        this.data.sortOrder =
          this.subjectChapterForm.controls["sortOrder"].value;
        this.data.chapterName =
          this.subjectChapterForm.controls["chapterName"].value;
        console.log(this.data);
        this.quizSer
          .updateSubjectChapters(this.data)
          .subscribe((result: any) => {
            this.toaster.success("Chapter updated sucessfully.", "Sucess");
            this.dialogRef.close({ data: result.data });
          });
      } else {
        this.quizSer
          .saveSubjectChapters(this.subjectChapterForm.getRawValue())
          .subscribe((result: any) => {
            this.toaster.success("Chapter created sucessfully.", "Sucess");
            this.dialogRef.close({ data: result.data });
          });
      }
    }
  }

  updateForm() {
    this.subjectChapterForm = this.formBuilder.group({
      idClassStandard: [this.data.idClassStandard, Validators.required],
      idSubject: [this.data.idSubject, Validators.required],
      idState: [this.data.idState, Validators.required],
      idSyllabus: [this.data.idSyllabus, Validators.required],
      sortOrder: [this.data.sortOrder, Validators.required],
      chapterName: [
        this.data.chapterName,
        [Validators.required, Validators.maxLength(100)],
      ],
    });
    //this field Will be unavailable for user to edit
    this.subjectChapterForm.controls["idClassStandard"].disable();
    this.subjectChapterForm.controls["idSubject"].disable();
    this.subjectChapterForm.controls["idState"].disable();
    this.subjectChapterForm.controls["idSyllabus"].disable();
  }
}
