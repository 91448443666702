import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
@Injectable({
  providedIn: "root",
})
export class ModeratorSocialService {
  httpOptions: any;

  constructor(private http: HttpClient, private router: Router, private toaster: ToastrService) {}

  getAllSocialVideos(activeStatus, pageNumber) {
    return this.http.get("/api/v1/moderator/videos?activeFlag=" + activeStatus + "&pageNumber=" + pageNumber, this.httpOptions);
  }

  updateStatus(reviewSocialVideoDTO) {
    return this.http.put("/api/v1/moderator/review-video", reviewSocialVideoDTO, this.httpOptions);
  }

  getVidepObject(idSocialVideo) {
    return this.http.get("/api/v1/moderator/video-info?idSocialVideo=" + idSocialVideo, this.httpOptions);
  }

  upDatePlayStatus(idSocialVideo, status) {
    return this.http.put("/api/v1/moderator/play-status?idSocialVideo=" + idSocialVideo + "&playFlag=" + status, null, this.httpOptions);
  }

  deleteSocialVideo(idSocialVideo) {
    return this.http.delete("/api/v1/social-video/id/" + idSocialVideo, this.httpOptions);
  }

  updateSocialVideoStatus(status: boolean,id: number) {
    return this.http.post(`api/v1/social-video/update-social-video-status?idSocialVideo=${id}&status=${status}`, null);
  }
}
