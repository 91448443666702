import { ComponentRef, Injectable, Injector } from "@angular/core";
import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { LoadingSpinnerComponent } from "../loading-spinner/loading-spinner.component";
import { ActivatedRoute } from "@angular/router";

export class LoadingOverlayRef {
  constructor(private overlayRef: OverlayRef) {}

  close(): void {
    this.overlayRef.dispose();
  }
}

@Injectable()
export class LoadingService {
  constructor(
    private injector: Injector,
    private overlay: Overlay,
    private route: ActivatedRoute
  ) {}

  open(isSkipLoader:any): LoadingOverlayRef {
    //check whether the request is need to show loader
    if (isSkipLoader != null) {
      return null;
    }
    else if (
      window.location.pathname === "entertainment/social-video" ||
      window.location.pathname.replace(/[0-9]/g, "") === "entertainment/social-video/"
      ||  window.location.pathname === "/notification"
    ) {
      return null;
    } else {
      const overlayRef = this.createOverlay();
      const dialogRef = new LoadingOverlayRef(overlayRef);
      const overlayComponent = this.attachDialogContainer(
        overlayRef,
        dialogRef
      );

      return dialogRef;
    }
  }

  private createOverlay(): OverlayRef {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      positionStrategy,
    });

    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(
    overlayRef: OverlayRef,
    dialogRef: LoadingOverlayRef
  ): LoadingSpinnerComponent {
    const injector = this.createInjector(dialogRef);
    const containerPortal = new ComponentPortal(
      LoadingSpinnerComponent,
      null,
      injector
    );
    const containerRef: ComponentRef<LoadingSpinnerComponent> =
      overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(dialogRef: LoadingOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(LoadingOverlayRef, dialogRef);

    return new PortalInjector(this.injector, injectionTokens);
  }
}
