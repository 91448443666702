
<div class="container">
    <h2 mat-dialog-title>  {{updateFlag == true ? 'Edit Academic Chapter' : 'Create Academic Chapter'}}</h2>

    <form  [formGroup]="subjectChapterForm" novalidate>

    <div class="row" style="margin-top:2%">

        <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%">
              <mat-label>Chapter  Name</mat-label>
              <input
                matInput
                placeholder="Enter Chapter Name"
                formControlName="chapterName"
                required
              />
  
              <mat-error
                *ngIf="subjectChapterForm.controls['chapterName'].errors?.required"
                >Chpater Name is mandatory.</mat-error
              >
              <mat-error
                *ngIf="subjectChapterForm.controls['chapterName'].errors?.maxlength"
                >Maximum Character Length is 100.</mat-error
              >
            </mat-form-field>
          </div>

      

        <div class="col-md-4">
            <mat-form-field   appearance="outline" style="width: 100%">
                <mat-label>Select a Syllabus</mat-label>
                <mat-select formControlName="idSyllabus" required>
                    <mat-option *ngFor="let syl of syllabusData; let i = index" [value]="syl.idSyllabus">
                        {{syl.syllabusName}}</mat-option>
                </mat-select>
                <mat-error
                *ngIf="
                  !subjectChapterForm.controls['idSyllabus'].valid &&
                  subjectChapterForm.controls['idSyllabus'].touched
                "
              >
                Please select Syllabus
              </mat-error>
            </mat-form-field>
        </div>

        <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%" >
                <mat-label>Select State</mat-label>
                <mat-select formControlName="idState"   required>
                  <mat-option
                    *ngFor="let stat of states; let i = index"
                    [value]="stat.idState"
                  >
                    {{ stat.state }}</mat-option
                  >
                </mat-select>
                <mat-error
                  *ngIf="
                    !subjectChapterForm.controls['idState'].valid &&
                    subjectChapterForm.controls['idState'].touched
                  "
                >
                  Please select state
                </mat-error>
              </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field  appearance="outline" style="width: 100%">
              <mat-label>Select a Class</mat-label>
              <mat-select formControlName="idClassStandard" required (selectionChange)="getAllSubjects()">
                  <mat-option *ngFor="let class of classStandardList; let i = index"
                      [value]="class.idClassStandard">
                      {{ class.classStandadName }}</mat-option>
              </mat-select>
              <mat-error
              *ngIf="
                !subjectChapterForm.controls['idClassStandard'].valid &&
                 subjectChapterForm.controls['idClassStandard'].touched
              "
            >
              Please select ClassStandard.
            </mat-error>
          </mat-form-field>
      </div>


        <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%">
                <mat-label>Select a Subject</mat-label>
                <mat-select formControlName="idSubject" 
                    required>
                    <mat-option *ngFor="let subject of subjectData; let i = index" [value]="subject.idSubject">
                        {{ subject.subjectName }}</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    !subjectChapterForm.controls['idSubject'].valid &&
                    subjectChapterForm.controls['idSubject'].touched
                  "
                >
                  Please select Subject
                </mat-error>
            </mat-form-field>
        </div>

      

        <div class="col-md-4">
            <mat-form-field  appearance="outline"  style="width: 100%" >
              <mat-label>Sort Order</mat-label>
              <input
                type="number"
                matInput
                placeholder="Ex: 5"
                formControlName="sortOrder"
                min="0"
                required
              />
  
              <mat-error
                *ngIf="subjectChapterForm.controls['sortOrder'].errors?.pattern"
                >Invalid Number!</mat-error
              >
              <mat-error
                *ngIf="subjectChapterForm.controls['sortOrder'].errors?.required"
                >Sort Order Mandatory.</mat-error>
            </mat-form-field>
          </div>
       
    </div>



    
<mat-dialog-actions align="end">
    <button  mat-raised-button color="warn" type="button" mat-dialog-close>Cancel</button>
    <button  (click)="callSubmit()" mat-raised-button color="primary"  type="button"  [disabled]="!subjectChapterForm.valid">{{updateFlag == true ? 'Update' : 'Create'}}</button>
  </mat-dialog-actions>
</form>


</div>
