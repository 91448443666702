import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "Video Upload",
    icon: "mdi mdi-view-dashboard",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/component/video/upload",
        title: "Video upload",
        icon: "fas fa-upload",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/video/edit",
        title: "Edit Videos",
        icon: "fas fa-edit",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },

      {
        path: "/component/video/upload-category-image",
        title: "Upload Category Image",
        icon: "fas fa-upload",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/video/upload-discover-video",
        title: "Upload Disover Video",
        icon: "fas fa-upload",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },

       {
        path: "/component/watchVideos",
        title: "Watch Academic Videos",
        icon: "fas fa-video",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/social-video-admin",
        title: "Social Videos",
        icon: "fas fa-video",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },

    ],
  },
  {
    path: "",
    title: "Quiz",
    icon: "mdi mdi-view-dashboard",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "",
        title: "Academic Quiz",
        icon: "mdi mdi-view-dashboard",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [
          {
            path: "/component/createquiz",
            title: "Create Quiz",
            icon: "mdi mdi-comment-question-outline",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
          {
            path: "/component/editquiz",
            title: "Edit Quiz",
            icon: "mdi mdi-comment-question-outline",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],   
          }
        ],
      },
      {
        path: "",
        title: "Extracurricular Quiz",
        icon: "mdi mdi-view-dashboard",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [
          {
            path: "/component/create-eca-quiz",
            title: "Create ECA Quiz",
            icon: "mdi mdi-comment-question-outline",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
          {
            path: "/component/edit-eca-quiz",
            title: "Edit ECA Quiz",
            icon: "mdi mdi-comment-question-outline",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],   
          }
        ],
      },
      {
        path: "",
        title: "ExamPreparation Quiz",
        icon: "mdi mdi-view-dashboard",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [
          {
            path: "/component/create-ep-quiz",
            title: "Create EP Quiz",
            icon: "mdi mdi-comment-question-outline",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
          {
            path: "/component/edit-ep-quiz",
            title: "Edit EP Quiz",
            icon: "mdi mdi-comment-question-outline",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],   
          }
        ],
      },
      {
        path: "",
        title: "VCT Quiz",
        icon: "mdi mdi-view-dashboard",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [
          {
            path: "/component/create-vct-quiz",
            title: "Create VCT Quiz",
            icon: "mdi mdi-comment-question-outline",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
          {
            path: "/component/edit-vct-quiz",
            title: "Edit VCT Quiz",
            icon: "mdi mdi-comment-question-outline",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],   
          }
        ],
      },
      {
        path: "",
        title: "Analytics",
        icon: "mdi mdi-view-dashboard",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [
          {
            path: "/component/subject-quiz-analytics",
            title: "Subject Quiz Analytics",
            icon: "mdi mdi-comment-question-outline",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
          {
            path: "/component/chapter-quiz-analytics",
            title: "Chapter Quiz Analytics",
            icon: "mdi mdi-comment-question-outline",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],   
          }
        ],
      }
    ],
  },
  {
    path: "",
    title: "Staff",
    icon: "mdi mdi-account-multiple",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/component/viewStaff",
        title: "Teacher",
        icon: "mdi mdi-account-plus",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/addBlogger",
        title: "Blogger",
        icon: "mdi mdi-account-plus",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/add-student",
        title: "Student",
        icon: "mdi mdi-account-plus",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/addTelecallerOrModerator",
        title: "Telecaller",
        icon: "mdi mdi-account-plus",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "",
        title: "Vendors",
        icon: "mdi mdi-account-star",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [
          {
            path: "/component/marketer",
            title: "Vendor Details",
            icon: "mdi mdi-account-multiple",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
          {
            path: "/component/create-vendor",
            title: "Create Vendor",
            icon: "mdi mdi-account-plus",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
        ],
      },
      {
        path: "/component/createWebexPool",
        title: "Create WebEx Pool",
        icon: "mdi mdi-plus-circle-outline",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/assignYoutubeMasterToStaff",
        title: "Youtube Credential",
        icon: "fas fa-video",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      { 
        path: "",
        title: "Live Class",
        icon: "fas fa-video",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [ 
          {
            path: "/component/live-class-info",
            title: "List Of All Live Class",
            icon: "mdi mdi-book-open",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
          {
          path: "/component/live-class-creation",
          title: "Create Live Class",
          icon: "mdi mdi-plus-circle-outline",
          class: "",
          ddclass: "",
          extralink: false,
          submenu: [],
        },
        {
          path: "/component/eca-live-class-creation",
          title: "Create ECA Live Class",
          icon: "mdi mdi-plus-circle-outline",
          class: "",
          ddclass: "",
          extralink: false,
          submenu: [],
        },
       ],
      },
    ],
  },
  {
    path: "",
    title: "Configuration",
    icon: "fas fa-cogs",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/component/product-group",
        title: "Product-Group",
        icon: "fas fa-boxes",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/product-group",
        title: "Product",
        icon: "fas fa-archive",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [
          {
            path: "/component/product-pricing-meta",
            title: "Product Meta",
            icon: "fas fa-cogs",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
          {
            path: "/component/product-pricing",
            title: "Product Plans",
            icon: "mdi mdi-shopping",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
         
          {
            path: "/component/live-product",
            title: "Live Tutorials",
            icon: "fas fa-video",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
          {
            path: "/component/academic-product",
            title: "Academic",
            icon: " fas fa-book",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
          {
            path: "/component/exam-preparation",
            title: "Exam Preparation",
            icon: " fas fa-book",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
          {
            path: "/component/extracurricular-product",
            title: "Extracurricular",
            icon: "fas fa-child",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
          {
            path: "/component/vct-product",
            title: "Vista's Challenger Test ",
            icon: "fas fa-child",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
        ],
      },
      {
        path: "/component/subjects",
        title: "Subject List",
        icon: "fas fa-book",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "",
        title: "Subject-Chapter",
        icon: "fas fa-book",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [
          {
            path: "/component/subject-chapters",
            title: "Academic",
            icon: " fas fa-book",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          },
          {
            path: "/component/eca-subject-chapters",
            title: "Extracurricular",
            icon: "fas fa-book",
            class: "",
            ddclass: "",
            extralink: false,
            submenu: [],
          }
        ],
      },
      {
        title: "Coupon",
        icon: "fas fa-book",
        class: "",
        ddclass: "",
        extralink: false,
        path: "/component/coupon",
        submenu: [],
      }
    ],
  },

  {
    path: "",
    title: "Batch",
    icon: "mdi mdi-book-open",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/component/batch/batchLists",
        title: "Batch Lists",
        icon: "mdi mdi-book-open",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/createLiveCourse",
        title: "Add Academic-Batch",
        icon: "mdi mdi-plus-circle-outline",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      // {
      //   path: "/component/createExtraCurricularLiveCourse",
      //   title: "Add ECA-Batch",
      //   icon: "mdi mdi-plus-circle-outline",
      //   class: "",
      //   ddclass: "",
      //   extralink: false,
      //   submenu: [],
      // },
    ],
  },

  // {
  //   path: "",
  //   title: "Demo Classes",
  //   icon: "mdi mdi-book-multiple",
  //   class: "has-arrow",
  //   ddclass: "",
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: "/component/telecallerAssignmentOfClass",
  //       title: "Assign Demo class",
  //       icon: "mdi mdi-file-send",
  //       class: "",
  //       ddclass: "",
  //       extralink: false,
  //       submenu: [
  //         {
  //           path: "/component/telecallerAssignmentOfClass",
  //           title: "Academic",
  //           icon: "mdi mdi-file-send",
  //           class: "",
  //           ddclass: "",
  //           extralink: false,
  //           submenu: [],
  //         },
  //         {
  //           path: "/component/assignExtraCurricularDemoClass",
  //           title: "Extra-Curricular",
  //           icon: "mdi mdi-file-send",
  //           class: "",
  //           ddclass: "",
  //           extralink: false,
  //           submenu: [],
  //         },
  //       ],
  //     },
      
  //     {
  //       path: "",
  //       title: "Demo Class",
  //       icon: "fas fa-tv",
  //       class: "",
  //       ddclass: "",
  //       extralink: false,
  //       submenu: [
  //         {
  //           path: "/component/demoClass",
  //           title: "Academic Class",
  //           icon: "",
  //           class: "",
  //           ddclass: "",
  //           extralink: false,
  //           submenu: [],
  //         },
  //         {
  //           path: "/component/eca-demo-class",
  //           title: "ECA Class",
  //           icon: "",
  //           class: "",
  //           ddclass: "",
  //           extralink: false,
  //           submenu: [],
  //         }
  //       ],
  //     }

  //   ],
  // },

 
  {
    path: "",
    title: "User Records",
    icon: "mdi mdi-clipboard-account",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/component/order-details",
        title: "Order Details",
        icon: "mdi mdi-shopping",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },  
      {
        path: "/component/invoice/logs",
        title: "Invoice Generation Logs",
        icon: "mdi mdi-book-open",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },   
      {
        path: "/component/order-querys",
        title: "Order Querys",
        icon: "mdi mdi-comment-multiple-outline",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },     
    
      {
        path: "/component/listAllUsers",
        title: "User Lists",
        icon: "fas fa-user",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "component/list-of-contacts-for-support",
        title: "Contacted List",
        icon: "mdi mdi-comment-multiple-outline",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
        {
        path: "/component/global-notification",
        title: "Global Notification",
        icon: "mdi mdi-eye",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/bulk-signup",
        title: "Bulk SignUp",
        icon: "mdi mdi-book-open",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/bulk-manual-subscription",
        title: "Bulk Subscripiton",
        icon: "mdi mdi-book-open",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/batch-manual-subscription",
        title: "Batch Subscripiton",
        icon: "mdi mdi-book-open",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/subscribed-user-details",
        title: " Subscripiton Details",
        icon: "mdi mdi-book-open",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
    ],
  },

  {
    path: "",
    title: "Reports",
    icon: "mdi mdi-clipboard-account",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/component/user-reports",
        title: "User Subscription Reports",
        icon: "mdi mdi-shopping",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/user-profile-report",
        title: "User Profile Reports",
        icon: "mdi mdi-shopping",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },   
      {
        path: "/component/offline-videos-report",
        title: "Video Reports",
        icon: "mdi mdi-comment-multiple-outline",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },  
      {
        path: "/component/user-analytics-info",
        title: "User Analytics Info",
        icon: "mdi mdi-shopping",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },    
      // {
      //   path: "/component/moderator_social",
      //   title: "Review Social Videos",
      //   icon: "mdi mdi-eye",
      //   class: "",
      //   ddclass: "",
      //   extralink: false,
      //   submenu: [],
      // },
     
    ],
  },

];

export const ROUTESStudent: RouteInfo[] = [

 
  {
    path: "",
    title: "Profile",
    icon: " fas fa-th-large",
    class: "has-arrow",
    ddclass: "two-column",
    extralink: false,
    submenu: [
      {
        path: "/component/user/profile",
        title: "User Profile",
        icon: "fas fa-user",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/quiz/studentprogress",
        title: "My Progress",
        icon: "mdi mdi-airplay",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },

      {
        path: "/component/notifications",
        title: "Notifications",
        icon: " fas fa-inbox",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },

      // {
      //   path: "/component/recorded",
      //   title: "Recorded Sessions",
      //   icon: "fas fa-video",
      //   class: "",
      //   ddclass: "",
      //   extralink: false,
      //   submenu: [],
      // },


      /*  {
          path: "/component/student/profile",
          title: "My Offline Courses",
          icon: "mdimdi-airplay",
          class: "",
          ddclass: "",
          extralink: false,
          submenu: [],
        },*/

    ],
  },
  {
    path: "",
    title: "Social",
    icon: "mdi mdi-account-multiple",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/social_videos/createvideos",
        title: "Create Videos",
        icon: "mdi mdi-account-plus",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/social_videos/vistavideos",
        title: "Vista videos",
        icon: "mdi mdi-play-box-outline",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      }

    ],
  },

  {
    path: "",
    title: "Demo Classes",
    icon: "mdi mdi-book-multiple",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/component/join-demo-class",
        title: "Join Demo Class",
        icon: "mdi mdi-file-send",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      }
    ],
  },

];

export const ROUTESSTAFF: RouteInfo[] = [

  {
    path: "",
    title: "Batches",
    icon: "mdi mdi-inbox-arrow-down",
    class: "has-arrow",
    ddclass: "two-column",
    extralink: false,
    submenu: [
      {
        path: "/apps/batchDetails",
        title: "Batch Details",
        icon: "mdi mdi-account-switch",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/apps/recorded",
        title: "Recorded Details",
        icon: "mdi mdi-arrow-right-drop-circle-outline",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },

      // {
      //   path: "/quiz/assignquiz",
      //   title: "Assign Quiz",
      //   icon: "mdi mdi-share",
      //   class: "",
      //   ddclass: "",
      //   extralink: false,
      //   submenu: [],
      // },

      // {
      //   path: "/quiz/quizresult",
      //   title: "Quiz result",
      //   icon: "mdi mdi-comment-question-outline",
      //   class: "",
      //   ddclass: "",
      //   extralink: false,
      //   submenu: [],
      // },

      // {
      //   path: "/quiz/createbatchquiz",
      //   title: "Ceate Batch Quiz",
      //   icon: "mdi mdi-comment-question-outline",
      //   class: "",
      //   ddclass: "",
      //   extralink: false,
      //   submenu: [],
      // },
      // {
      //   path: "/component/sendnotifications",
      //   title: "Send Notifications",
      //   icon: "mdi mdi-comment-question-outline",
      //   class: "",
      //   ddclass: "",
      //   extralink: false,
      //   submenu: [],
      // }

    ],
  },


  {
    path: "",
    title: "Profile",
    icon: " fas fa-th-large",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/component/user/profile",
        title: "User Profile",
        icon: "fas fa-user",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      }

      

    ],
  },

  {
    path: "",
    title: "Live Streaming",
    icon: "fas fa-video",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/component/teacher-start-live-webinar",
        title: "Youtube Live Streaming",
        icon: "fas fa-video",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
    
    ]
  },
  {
    path: "",
    title: "Start Demo Class",
    icon: "mdi mdi-book-open",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/component/lead/startleadbatch",
        title: "Academic",
        icon: "mdi mdi-book-open",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/lead/startDemoClassExtraCurricular",
        title: "Extra-Curricular",
        icon: "mdi mdi-book-open",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      }
    ]
  },

  {
    path: "",
    title: "Academic Videos",
    icon: "fas fa-video",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/component/watchVideos",
        title: "Watch Video",
        icon: "fas fa-video",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
    
    ]
  }

];


export const ROUTESPARENT: RouteInfo[] = [

  {
    path: "",
    title: "Profile",
    icon: " fas fa-th-large",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/component/user/profile",
        title: "User Profile",
        icon: "fas fa-user",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/component/user/kids",
        title: "My Kids",
        icon: "fas fa-users",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },

      {
        path: "/component/notifications",
        title: "Notifications",
        icon: " fas fa-inbox",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },

      {
        path: "/component/createstudent",
        title: "Create Student",
        icon: "fas fa-child",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },

    ],
  },
  {
    path: "",
    title: "Social",
    icon: "mdi mdi-account-multiple",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/social_videos/createvideos",
        title: "Create Videos",
        icon: "mdi mdi-account-plus",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/social_videos/vistavideos",
        title: "Vista videos",
        icon: "mdi mdi-play-box-outline",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      }

    ],
  },


  {
    path: "/component/teacherSearch",
    title: "Search Teachers",
    icon: "mdi mdi-account-multiple",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "",
    title: "Demo Clases",
    icon: "mdi mdi-book-multiple",
    class: "has-arrow",
    ddclass: "",
    extralink: false,
    submenu: [
      {
        path: "/component/join-demo-class",
        title: "Join Demo Class",
        icon: "mdi mdi-file-send",
        class: "",
        ddclass: "",
        extralink: false,
        submenu: [],
      },
    ],
  },
]

export const TelecallerROUTES: RouteInfo[] = [

  {
    path: "/component/telecaller-landing-page",
    title: "Academic Demo Class Leads",
    icon: "mdi mdi-book-multiple",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [],
  },
  // {
  //   path: "/component/telecaller-extracur-landing-page",
  //   title: "EC Demo Class Leads",
  //   icon: "mdi mdi-book-multiple",
  //   class: "",
  //   ddclass: "",
  //   extralink: false,
  //   submenu: [],
  // },
  {
    path: "/component/telecallerAssignmentOfClass",
    title: "Assigning Academic Demo Class",
    icon: "mdi mdi-book-multiple",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/component/assignExtraCurricularDemoClass",
    title: "Assigning Extra Curricular Demo Classes",
    icon: "mdi mdi-book-multiple",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [],
  },
  

];

export const BloggerROUTES: RouteInfo[] = [
  {
    path: "/component/blog-landing",
    title: "Blog List",
    icon: "mdi mdi-blogger",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/component/blog-category",
    title: "Blog Category",
    icon: "mdi mdi-collage",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/component/social-video",
    title: "Social Videos",
    icon: "mdi mdi-collage",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [],
  }
];

export const MODERATOR_ROUTES: RouteInfo[] = [
 

  // {
  //   path: "",
  //   title: "Social Videos",
  //   icon: "mdi mdi-book-open",
  //   class: "has-arrow",
  //   ddclass: "",
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: "/component/moderator_social",
  //       title:"Review Videos",
  //       icon: "mdi mdi-eye",
  //       class: "",
  //       ddclass: "",
  //       extralink: false,
  //       submenu: [],
  //     }
  //   ]

  // },
  {
    path: "/component/social-video",
    title: "Social Videos",
    icon: "mdi mdi-collage",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [],
  }
  
    

  
];

export const SALESADMIN_ROUTES: RouteInfo[] = [
  {
    path: "/component/sales-admin",
    title: "Demo 1",
    icon: "mdi mdi-blogger",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/component/sales-admin",
    title: "Demo 2",
    icon: "mdi mdi-blogger",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/component/sales-admin",
    title: "Demo 3",
    icon: "mdi mdi-blogger",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [],
  },
];
  

export const MARKETER_ROUTES: RouteInfo[] = [
  {
    path: "/component/marketer",
    title: "Vendor Details",
    icon: "mdi mdi-account-multiple",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/component/create-vendor",
    title: "Create Vendor",
    icon: "mdi mdi-account-plus",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [],
  },
  // {
  //   path: "/component/sales-admin",
  //   title: "Demo 3",
  //   icon: "mdi mdi-blogger",
  //   class: "",
  //   ddclass: "",
  //   extralink: false,
  //   submenu: [],
  // },
];

