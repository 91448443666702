<mat-form-field class="example-full-width" appearance="fill">
  <mat-label>{{hint}}</mat-label>

    <input matInput [matDatepicker]="dp" placeholder="Month and Year" [formControl]="date" [max]="maxDate" [min]="minDate">
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp
                    startView="multi-year"
                    (yearSelected)="chosenYearHandler($event)"
                    (monthSelected)="chosenMonthHandler($event, dp)"
                    panelClass="example-month-picker">
    </mat-datepicker>
  </mat-form-field>
  