import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-ovc-quiz-batch-upload-modal',
  templateUrl: './ovc-quiz-batch-upload-modal.component.html',
  styleUrls: ['./ovc-quiz-batch-upload-modal.component.css']
})
export class OVCQuizBatchUploadModalComponent implements OnInit {
  idQuiz = null;
  quizContentType = null;
  file: File;
  logs;
  disableUploadButton: boolean = false;
  validCount: number = 0;
  constructor(private http: HttpClient,
    private router: Router,
    private toaster: ToastrService,@Inject(MAT_DIALOG_DATA) public data: any) { 
      this.idQuiz= data.idQuiz;
      this.quizContentType = data.quizType


    }
  ngOnInit(): void {
  }
  fileStore(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.file= file ;
  }
  verifyFile(){
    if(this.file == null)
    {
     this.toaster.warning("Please choose file before uploading.");
     return ;
    }
    this.logs=[];

         let formData: any = new FormData();
 
     formData.append("batchFile", this.file);
    
 
     this.http.post("/api/v1/quiz/validate-batch-upload-files?idQuiz="+this.idQuiz, formData).subscribe(
       (res: any) => {
         if (res.statusCode == 200) {
          //  this.toaster.success("Video Upload success ", "Success");
          this.logs=res.data.log;
          this.validCount=1;
         }
       },
       (err) => {
         console.error(err);
         this.logs=err.error.data.log;
         this.validCount=1;
         
       }
     );
  }
  uploadFile() {

    if(this.file == null)
    {
     this.toaster.warning("Please choose file before uploading.");
     return ;
    }
 
    if (this.validCount <= 0)
    {
     this.toaster.warning("Please verify the file once , before proceeding for uploading.");
     return ;
    }
 
    this.logs=[];
 
     let formData: any = new FormData();
 
     formData.append("batchFile", this.file);
    
    if(this.quizContentType === 'academic'){
      this.http.post("/api/v1/quiz/batch-quiz-upload?idQuiz="+this.idQuiz, formData).subscribe(
        (res: any) => {
          if (res.statusCode == 201) {
            this.toaster.success(res.message, "Success");
            this.logs=res.data.log;
            this.disableUploadButton=true;
          }
        },
        (err) => {
          console.error(err);
          this.logs=err.error.data.log;
          // this.toaster.error(err.message);
        }
      );
    }else {
      this.http.post("/api/v1/quiz/batch-eca-quiz-upload?idQuiz="+this.idQuiz, formData).subscribe(
        (res: any) => {
          if (res.statusCode == 201) {
            this.toaster.success(res.message, "Success");
            this.logs=res.data.log;
            this.disableUploadButton=true;
          }
        },
        (err) => {
          console.error(err);
          this.logs=err.error.data.log;
          // this.toaster.error(err.message);
        }
      );
    }
   
   }
}
