//Added By Ahmed
import { Injectable, OnInit } from "@angular/core";

import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthguardService implements CanActivate {
  constructor(private routes: Router, public toaster: ToastrService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (localStorage.getItem("accessToken") != null) {
      if (this.tokenExpired(localStorage.getItem("accessToken"))) {
        console.log("User token expired");
        this.toaster.info("Your Session Got Expired!");
        this.routes.navigate(["/logout"]);
        return false;
      } else {
        for (let index = 0; index < next.data.roles.length; index++) {
          if (next.data.roles[index] === localStorage.getItem("role")) {
            return true;
          }
        }

        if (localStorage.getItem("role") === "Admin") {
          this.routes.navigate(["/component/dashboard"]);
          return false;
        }
         else if (localStorage.getItem("role") === "Teacher") {
          this.routes.navigate(["/apps/batchDetails"]);
          return false;
        }
         else if (localStorage.getItem("role") === "Telecaller") {
          this.routes.navigate(["/component/telecaller-landing-page"]);
          return false;
        } 
        else if (localStorage.getItem("role") === "Blogger") {
          this.routes.navigate(["/component/blog-landing"]);
          return false;
        }
        else if (localStorage.getItem("role") === "Blogger") {
          this.routes.navigate(["/component/blog-landing"]);
          return false;
        }
        else if (localStorage.getItem("role") === "Moderator") {
          this.routes.navigate(["/component/social-video"]);
          return false;
        }
        else if (localStorage.getItem("role") === "SalesAdmin") {
          this.routes.navigate(["/component/sales-admin"]);
          return false;
        }
        else if (localStorage.getItem("role") === "Marketer") {
          this.routes.navigate(["/component/marketer"]);
          return false;
        }
         else {
          this.routes.navigate(["/authentication/login"]);
          return false;
        }
      }
    } else {
      for (let index = 0; index < next.data.roles.length; index++) {
        if (next.data.roles[index] === "Anonymous") {
          return true;
        }
      }
      this.routes.navigate(["/authentication/login"]);
      return false;
    }
  }

  private tokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split(".")[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
}
