import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-subject-creation-modal",
  templateUrl: "./subject-creation-modal.component.html",
  styleUrls: ["./subject-creation-modal.component.css"],
})
export class SubjectCreationModalComponent implements OnInit {
  subjectForm: FormGroup;
  updateFlag = false;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public toaster: ToastrService,
    private dialogRef: MatDialogRef<SubjectCreationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data != null) {
      if (data.updateFlag) {
        this.updateFlag = true;
        console.log(data);
        this.updateForm();
      } else {
        this.createForm();
      }
    } else {
      this.createForm();
    }
  }

  ngOnInit(): void {}

  updateForm() {
    this.subjectForm = this.formBuilder.group({
      header: [
        this.data.header,
        [Validators.required, Validators.maxLength(100)],
      ],
      description: [
        this.data.description,
        [Validators.required, Validators.maxLength(1000)],
      ],
      videoURL: [
        this.data.videoURL,
        [Validators.required, Validators.maxLength(500)],
      ],
      color: [
        this.data.color,
        [Validators.required, Validators.maxLength(100)],
      ],
      subjectName: [
        this.data.subjectName,
        [Validators.required, Validators.maxLength(45)],
      ],
    });
  }

  createForm() {
    this.subjectForm = this.formBuilder.group({
      header: [null, [Validators.required, Validators.maxLength(100)]],
      description: [null, [Validators.required, Validators.maxLength(1000)]],
      videoURL: [null, [Validators.required, Validators.maxLength(500)]],
      color: [null, [Validators.required, Validators.maxLength(100)]],
      subjectName: [null, [Validators.required, Validators.maxLength(45)]],
    });
  }

  callSubmit() {
    if (this.subjectForm.valid) {
      if (this.updateFlag) {
        console.log(this.subjectForm.value);
        this.http
          .put("/api/v1/subject/" + this.data.idSubject, this.subjectForm.value)
          .subscribe(
            (res: any) => {
              this.toaster.success("Subject updated sucessfully.", "Sucess");
              this.dialogRef.close({ data: res.data });
            },
            (err) => {
              console.error(err["error"].message);
              this.toaster.error(err["error"].message);
            }
          );
      } else {
        console.log(this.subjectForm.value);
        this.http.post("/api/v1/subject/", this.subjectForm.value).subscribe(
          (res: any) => {
            this.toaster.success("Subject created sucessfully.", "Sucess");
            this.dialogRef.close({ data: res.data });
          },
          (err) => {
            console.error(err["error"].message);
            this.toaster.error(err["error"].message);
          }
        );
      }
    }
  }
}
