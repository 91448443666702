import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RouteInfo } from './sidebar.metadata';
import { ROUTES,ROUTESStudent,ROUTESSTAFF,ROUTESPARENT, MODERATOR_ROUTES } from './menu-items';


@Injectable({
    providedIn: 'root'
})
export class SidebarService {

   

    public screenWidth: any;
    public collapseSidebar: boolean = false;
    public fullScreen: boolean = false;

    // MENUITEMS: RouteInfo[] = ROUTES;

    // items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);    

    MENUITEMS: RouteInfo[] = ROUTES;

    items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);

    constructor() {
        
        if(localStorage.getItem('role')=="Admin"){
            this.MENUITEMS = ROUTES; 
            this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);
        }

        if(localStorage.getItem('role')=="Student"){
            this.MENUITEMS = ROUTESStudent; 
            this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);
        }

        if(localStorage.getItem('role')=="Teacher"){
            this.MENUITEMS = ROUTESSTAFF; 
            this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);
        }

        if(localStorage.getItem('role')=="Parent"){
            this.MENUITEMS = ROUTESPARENT; 
            this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);
        }

        if(localStorage.getItem('role')=="Moderator"){       
           
            this.MENUITEMS = MODERATOR_ROUTES; 
            this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);         
        }
        
       
    }
}
