import { Component, OnInit,Inject,PLATFORM_ID,ViewChild} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {MatDialogRef,MAT_DIALOG_DATA} from "@angular/material/dialog";

import { empty, from } from 'rxjs';
import { AddQuizQuestionService } from  '../quiz/add-quiz-question/add-quiz-question.service';
import { AddQuizQuestionComponent } from '../quiz/add-quiz-question/add-quiz-question.component';
import { CreateQuizService } from '../quiz/create-quiz/create-quiz.service';

@Component({
  selector: 'app-edit-quiz-question',
  templateUrl: './edit-quiz-question.component.html',
  styleUrls: ['./edit-quiz-question.component.css']
})
export class  EditQuizQuestionComponent implements OnInit {

  q : any=[];
  at: any=[];
  ac : any=[]
  a: any=[]; 
  answerArray : any=[] ;
  count: number =0;
  answerLength : number = 0;
  optionCount : number = 0;
  idQuiz: number = 0;
  quizData: any;
  syllabus: any;
  diagramLoc: any;
  selectedQueType: any='';
  questionTabOpen: boolean = false;
  updateFlag: boolean;
  modules: { toolbar: (string[] | { header: number; }[] | { list: string; }[] | { script: string; }[] | { indent: string; }[] | { header: (number | boolean)[]; }[] | ({ color: any[]; background?: undefined; } | { background: any[]; color?: undefined; })[] | { font: any[]; }[] | { align: any[]; }[])[]; };
 


  constructor(public dialogRef: MatDialogRef<EditQuizQuestionComponent>,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public toaster: ToastrService,
    private quizSer: CreateQuizService,

    private questionSer: AddQuizQuestionService) {
      if (this.data != null) {
        this.quizData=this.data;
        if (this.quizData.updateFlag){
          this.updateFlag = true;
          this.updateQuestionFeild(this.quizData.data);
          console.log("data",this.quizData);
        }
      }  

     }
  updateQuestionFeild(data: any) {
   this.q.noOfoptions=data.numberOptions;
   this.q.questionTitle=data.questionTitle;
   this.q.questionType=data.questionType;
   this.q.marks=data.marks;
   this.diagramLoc=data.diagramLoc;
   this.q.questionText=data.questionText;
   this.q.answerText=data.answerText;
   this.answerArray= data.answers;
   this.a=data.answers;
  }

  ngOnInit(): void {
    this.getSyllabus();
    this.modules = {
      'toolbar': [
        ['italic', 'underline', 'strike'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean'],
        ['link'],
      ],
    }

  }
  onSelectionChange(event){
 
    // this.q.questionTitle = event.value
    switch(event.value){

      case 'Objective':
        this.q.questionTitle  = 'Choose the best answer'
        this.q.noOfoptions= 4
        this.q.marks=1
              break;
      case 'TrueOrFalse':
        this.q.questionTitle  = 'True Or False'
        this.q.noOfoptions= 2
        this.q.marks=1

           break;
      case 'OneWord':
        this.q.questionTitle  = 'Fill in the blanks'
        this.q.noOfoptions= 1        
        this.q.marks=1


          break;
      case 'ProblemSolving':
        this.q.questionTitle  = 'Problem Solving'
        this.q.noOfoptions= 4
        this.q.marks=3

            break;
      case 'Descriptive':
        this.q.questionTitle  = 'Answer the following'
        this.q.noOfoptions= 6
        this.q.marks=5

              break;
      default:
        break;
    }
    this.valuechange(this.q.noOfoptions)

  }

  Save(q, at, ac) {
    debugger;
    this.a = [];
    let flag: any;

    if (!this.q.questionType) {
      this.toaster.error("Select Question Type");
      return;
    }
    if (!this.q.questionTitle) {
      this.toaster.error("Select Question Title");
      return;
    }
    if (this.q.noOfoptions < 1) {
      this.toaster.error("Select proper Number of options");
      return;
    }

    if (!this.q.questionText) {
      this.toaster.error("Select Question Text");
      return;
    }

    for (let i = 0; i < at.length; i++) {
      if (ac[i] == true) {
        flag = true;
      } else flag = false;

      let udm = {
        textFieldValue: at[i],
        fieldId: i + 1,
        correctValueFlag: flag,
      };
      this.a.push(udm);
    }

    if (this.idQuiz == undefined && this.idQuiz == null) {
      this.toaster.error("Please add Quiz");
      return;
    }

    if (this.q.questionType == "Objective") {
      if (this.count > 1) {
        this.toaster.error(
          "You cannot add more than one correct answer for the objective type"
        );
        return;
      } else if (this.count == 0) {
        this.toaster.error("Atleast one option should be right");
        return;
      }
    }

    if (this.q.questionType == "Subjective") {
      let count = this.CheckCount(this.q.answerText, ":text");
      if (count != this.q.noOfoptions) {
        this.toaster.error(
          "Check your subjective answer which is not matching to the No of Options."
        );
        return;
      }
    }

    let finaludm = {
      questionText: this.q.questionText,
      answers: this.a,
      answerText: this.q.answerText,
      questionType: this.q.questionType,
      numberOptions: this.q.noOfoptions,
      questionTitle: this.q.questionTitle.trim(),
      marks: this.q.marks,
      diagramLoc:(this.diagramLoc != null || this.diagramLoc != undefined) ? this.diagramLoc.trim() : null
    };

    this.quizSer.createQuestionandAnswers(this.quizData.idQuiz, finaludm).subscribe(
      (res) => {
        console.error("questins");
        console.error(res);
        this.q = [];
        this.at = [];
        this.ac = [];
        this.a = [];
        this.count = 0;
        this.questionTabOpen = false;
        this.answerArray = [];
        this.diagramLoc = null;
        //this.getQuestionList(this.idQuiz);

        this.toaster.success("Quiz Question and answer added succesfully");
      },
      (error) => {
        this.toaster.error(error.error.message, "An Error Occured!");
      }
    );
  }

  valuechange(event){
    this.answerArray=[]
    for(let i=0; i<event; i++){
      this.answerArray[i] = i;
      }
  }

  checkCorrectAnswer(event){
 
    if(event.checked == true)
    this.count = this.count+1
    else
    this.count = this.count-1

    if(this.q.questionType == "Objective")
    {
      if(this.count>1){
        this.toaster.error("You cannot add more than one correct answer for the objective type");
      }
    }
  }

  //To check :text occurences
  CheckCount(main_str, sub_str) {
      main_str += '';
      sub_str += '';

      if (sub_str.length <= 0) 
      {
          return main_str.length + 1;
      }

      let subStr : any;
        subStr = sub_str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        return (main_str.match(new RegExp(subStr, 'gi')) || []).length;
  }

  getSyllabus(){
    this.questionSer.getAllsyllabus().subscribe((result: any) => {
     this.syllabus = result.data;     
     console.error("Syllabus");
     console.error(this.syllabus)
    });

  }

  update()
  {
    let finaludm =
    {
      "idQuizQuestion": this.quizData.data.idQuizQuestion,
      "questionText": this.q.questionText,
      "questionActiveFlag": this.quizData.data.questionActiveFlag,
      "answerText": this.q.answerText,
      "questionType": this.quizData.data.questionType,
      "numberOptions": this.quizData.data.numberOptions,
      "diagramLoc":  this.diagramLoc,
      "quiz":this.quizData.data.quiz,
      "answers":this.answerArray,
      "questionTitle":this.q.questionTitle,
      "marks":this.quizData.data.marks

    }

    this.quizSer.updateQuestionandAnswers(this.quizData.data.idQuizQuestion, finaludm).subscribe(
      (res) => {
        this.toaster.success("Quiz Question and answer Updated succesfully");
        this.dialogRef.close();
      },
      (error) => {
        this.toaster.error(error.error.message, "An Error Occured!");
      }
    );
   
  }
}
