import { Routes } from "@angular/router";
import { FullComponent } from "./layouts/full/full.component";
import { LogoutComponent } from "./authentication/logout/logout.component";

export const Approutes: Routes = [
  // {
  //   path: "",
  //   component: LandingComponent,
  //   canActivate: [AuthguardService],
    // data: {
    //   roles: ["Student", "Parent", "Anonymous"],
    //   title:"Vista's Learning - Join Now!. Free online live classes for classes 1-12",
    //   description:"Vista's Learning is a one-stop solution for the intellectual development of a child through pre-recorded video classes and free online live classes. So, hurry up! Sign in now.",
    //   keywords:"private classes, pre-recorded video classes, free online live classes, Vista's Learning"
    // },
  // },
 
  // {
  //   path: "bookfreeclass/:category",
  //   component: BookFreeClassComponent,
  //   canActivate: [AuthguardService],
  //   data: {
  //     roles: ["Student", "Parent", "Anonymous"],
  //   },
  // },
  // {
  //   path: "enterprise-subscription",
  //   component: EnterpriseSubscriptionComponent,
  //   canActivate: [AuthguardService],
    // data: {
    //   roles: ["Student", "Parent", "Anonymous"],
    // },
  // },
  {
    path: "",
    component: FullComponent,
    children: [
      { path: "", redirectTo: "/authentication/login", pathMatch: "full" 
      },
      // {
      //   path: "dashboard",
      //   redirectTo: "/homepage",
      // },
    
      {
        path: "component",
        loadChildren: () =>
          import("./component/component.module").then(
            (m) => m.ComponentsModule
          ),
      },
      // {
      //   path: "ecom",
      //   loadChildren: () =>
      //     import("./ecommerce/ecom.module").then((m) => m.EcomModule),
      // },
      

      {
        path: "apps",
        loadChildren: () =>
          import("./apps/apps.module").then((m) => m.AppsModule),
      },
    ],
  },
  // {
  //   path: "cart",
  //   loadChildren: () =>
  //     import("./cart/cart.module").then((m) => m.CartModule),
  // },
  // {
  //   path: "liveclass",
  //   loadChildren: () =>
  //     import("./live-class/live-class.module").then((m) => m.LiveClassModule),
  // },
  // {
  //   path: "redirection",
  //   loadChildren: () =>
  //     import("./redirection/redirection.module").then((m) => m.RedirectionModule),
  // },
  // {
  //   path:"student-personal-coaching",
  //   loadChildren: () =>
  //   import("./student-personal-coaching/student-personal-coaching.module").then((m) => m.StudentPersonalCoachingModule),
  // },
  // {
  //   path:"user-subscription",
  //   loadChildren: () =>
  //   import("./user-subscriptions/user-subscriptions.module").then((m) => m.UserSubscriptionsModule),
  // },
  // {
  //   path:"user",
  //   loadChildren: () =>
  //   import("./user/user.module").then((m) => m.UserModule),
  // },
  // {
  //   path: "teacher",
  //   loadChildren: () =>
  //     import("./teacher/teacher.module").then((m) => m.TeacherModule),
  // },
  // {
  //   path: "social-video",
  //   loadChildren: () =>
  //     import("./entertainment/entertainment.module").then((m) => m.EntertainmentModule),
  // },
  {  
        path: "authentication",
        loadChildren: () =>
          import("./authentication/authentication.module").then(
            (m) => m.AuthenticationModule),
  },
  
  { path: "logout", component: LogoutComponent },
  // {
  //   path: "join-demo-class-new",
  //   component: StudentJoinDemoClassNewComponent,
  //   canActivate: [AuthguardService],
  //   data: {
  //     title: "Join Demo Class",
  //     roles: ["Student", "Parent"],
  //   },
  // },
//  {
//     path: "support",
//     loadChildren: () =>
//       import("./support/support.module").then((m) => m.SupportModule),
//   },
  // {
  //   path: "discover-videos",
  //   loadChildren: () =>
  //     import("./discovervideo/discovervideo.module").then((m) => m.DiscovervideoModule),
  // },
  // {
  //   path: "academic-progress",
  //   loadChildren: () =>
  //     import("./academic/academic.module").then((m) => m.AcademicModule),
  // },
  // {

  //   path: "extra-curricular",
  //   loadChildren: () =>
  //     import("./eca/eca.module").then((m) => m.ECAModule),
  // },
//  {
//     path: "blog",
//     loadChildren: () =>
//       import("./blog/blog.module").then((m) => m.BlogModule),
//   },
  // {
  //   path: "notification",
  //   loadChildren: () =>
  //     import("./notifications/notifications.module").then((m) => m.NotificationsModule),
  // },

  // {
  //   path: "quiz",
  //   loadChildren: () =>
  //     import("./quiz/quiz.module").then((m) => m.QuizModule),
  // },
  {
    path: "authentication/internal-login",
    redirectTo: "/authentication/login",
  },
  {
    path: "**",
    redirectTo: "/authentication/404",
  },
  
 
];