import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import { NgIf, JsonPipe } from "@angular/common";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatNativeDateModule } from "@angular/material/core";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { DomSanitizer } from "@angular/platform-browser";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { catchError, map, startWith } from "rxjs/operators";
import { Router } from "@angular/router";
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from "@angular/common/http";
import { Observable, range, throwError } from "rxjs";
import { DatePipe } from "@angular/common";
export interface Tags {
  name: string;
}
@Component({
  selector: "app-create-coupon-model",
  templateUrl: "./create-coupon-model.component.html",
  styleUrls: ["./create-coupon-model.component.css"],
})
export class CreateCouponModelComponent implements OnInit {
  @ViewChild(MatDatepicker) picker: MatDatepicker<Date>;

  idCoupon = null;
  isLinear = true;
  selectedSpec: any = "";
  extn: any = false;
  disc: any = false;
  couponCodeGroup: FormGroup;
  emailOrPhone: any = "";
  couponCode: any = "";
  description: any = "";
  couponTypeName: any = "";
  extensionDuration: any = Number;
  discount: any = "";
  startDate: any = "";
  endDate: any = Date;
  totalCount: any = "";
  batchName: any = "";
  oldBatchName: any = "";
  edit: any = false;
  // idCoupon:any = ''
  couponTypes: any = [{ couponTypeName: "SUBSCRIPTION_EXTENSION" }, { couponTypeName: "DISCOUNT_PRICE" }, { couponTypeName: "DISCOUNT_PERCENT" }, { couponTypeName: "TRIAL" }];
  constructor(
    private _formBuilder: FormBuilder,
    public toaster: ToastrService,
    public datepipe: DatePipe,

    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateCouponModelComponent>,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    this.couponCodeGroup = this._formBuilder.group({
      couponCode: ["", [Validators.maxLength(40)]],
      description: ["", Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      couponType: ["", Validators.required],
      extensionDuration: [0, [Validators.maxLength(40)]],
      discount: ["", [Validators.maxLength(40)]],
      totalCount: ["", [Validators.maxLength(40)]],
      usedCount: [0, [Validators.maxLength(40)]],
      batchName: [""],
      emailOrPhone: [""],
    });
  }

  ngOnInit(): void {
    this.selectedSpec = this.data.specSelected;
    if (this.data.couponData != null) {
      this.edit = true;

      let data = this.data.couponData;
      this.couponCodeGroup.get("couponCode").setValue(data.couponCode);
      this.couponCodeGroup.get("description").setValue(data.description);
      this.couponCodeGroup.get("emailOrPhone").setValue(data.emailOrPhone);
      this.couponCodeGroup.get("couponType").setValue(data.couponType);
      this.couponCodeGroup.get("extensionDuration").setValue(data.extensionDuration);
      this.couponCodeGroup.get("discount").setValue(data.discount);
      this.couponCodeGroup.get("totalCount").setValue(data.totalCount);
      this.couponCodeGroup.get("usedCount").setValue(data.usedCount);
      this.couponCodeGroup.get("batchName").setValue(data.batchName);
      this.oldBatchName = data.batchName;
      if (data.couponType === "SUBSCRIPTION_EXTENSION") {
        this.extn = true;
        this.disc = false;
      } else {
        this.extn = false;
        this.disc = true;
      }

      const originaStDate = new Date(this.datepipe.transform(data.startDate, "yyyy-MM-dd"));
      this.couponCodeGroup.get("startDate").setValue(new Date(this.datepipe.transform(originaStDate, "yyyy-MM-dd")));
      const originaEnDate = new Date(this.datepipe.transform(data.endDate, "yyyy-MM-dd"));
      this.couponCodeGroup.get("endDate").setValue(new Date(this.datepipe.transform(originaEnDate, "yyyy-MM-dd")));
      // this.endDate = new Date(this.datepipe.transform(data.endDate, "dd/mm/yyyy"));
    }
  }
  dateValueChangedTo(from: HTMLInputElement, to: HTMLInputElement) {
    const startDate = new Date(this.datepipe.transform(from.value, "yyyy-MM-dd"));
    this.startDate = startDate.toISOString();
    const endDate = new Date(this.datepipe.transform(to.value, "yyyy-MM-dd"));
    this.endDate = endDate.toISOString();
  }
  setCouponType(event) {
    console.log("event", event);
    this.couponTypeName = event;
    if (event === "SUBSCRIPTION_EXTENSION") {
      this.extn = true;
      this.disc = false;
    } else if (event === "TRIAL") {
      this.extn = true;
      this.disc = false;
    } else {
      this.extn = false;
      this.disc = true;
    }
  }
  createCoupon() {
    if (this.couponCodeGroup.valid) {
      let requestObj = this.couponCodeGroup.value;

      if (this.data.specSelected === "For a student") {
        if (!requestObj.emailOrPhone) {
          this.toaster.error("Please enter email or phone number");
          return;
        }
      } else if (this.data.specSelected === "Bulk Coupon") {
        if (!requestObj.batchName) {
          this.toaster.error("Please enter batch name");
          return;
        }
      }

      if (this.data.couponData) {
        requestObj.isActive = this.data.couponData.isActive;
        requestObj.isVisible = this.data.couponData.isVisible;
      } else {
        requestObj.isActive = true;
        requestObj.isVisible = true;
      }
      if (!this.edit) {
        if (this.data.specSelected === "Bulk Coupon") {
          this.createBulkCoupon(requestObj);
        } else {
          this.createRegularCoupon(requestObj);
        }
      } else {
        this.idCoupon = this.data.couponData.idCoupon;
        this.UpdateCoupon(requestObj, this.idCoupon);
      }
    }
  }
  private UpdateCoupon(requestObj, coupon) {
    let apiEndPoint = `/api/v1/special-offer/update-coupon/${coupon}`;

    this.http.put(apiEndPoint, requestObj).subscribe(
      (res) => {
        this.toaster.success(" coupon updated successfully");
        this.dialogRef.close();
      },
      (err) => {
        this.toaster.error("Failed to update coupons");
        this.dialogRef.close();
      }
    );
  }
  private UpdateBulkCoupon(requestObj) {
    let apiEndPoint = `/api/v1/special-offer/update-coupon-batch/${requestObj.batchName}`;

    this.http.put(apiEndPoint, requestObj).subscribe(
      (res) => {
        this.toaster.success(" coupon updated successfully");
        this.dialogRef.close();
      },
      (err) => {
        this.toaster.error("Failed to update coupons");
        this.dialogRef.close();
      }
    );
  }

  private createBulkCoupon(requestObj) {
    let apiEndPoint = `/api/v1/special-offer/create-coupons?size=${requestObj.totalCount}`;

    this.http.post(apiEndPoint, requestObj).subscribe(
      (res) => {
        this.toaster.success("Bulk coupons created successfully");
        this.dialogRef.close();
      },
      (err) => {
        this.toaster.error("Failed to create bulk coupons");
        this.dialogRef.close();
      }
    );
  }

  private createRegularCoupon(requestObj) {
    let apiEndPoint = "/api/v1/special-offer/create-coupon";

    if (this.data.specSelected === "For a student" && requestObj.emailOrPhone) {
      apiEndPoint += `?emailOrPhone=${requestObj.emailOrPhone}`;
    }

    this.http.post(apiEndPoint, requestObj).subscribe(
      (res) => {
        this.toaster.success("Coupon created successfully");
        this.dialogRef.close();
      },
      (err) => {
        this.toaster.error("Failed to create coupon");
        this.dialogRef.close();
      }
    );
  }
  batchNameIsRequired(): boolean {
    return this.selectedSpec === "Bulk Coupon" && this.couponCodeGroup.get("batchName").value !== null;
  }
}
