<div class="make-center">
    <div class="row">
        <div class="col-lg-12">
            <form class="form" (ngSubmit)="reAssignTeacher()">
                <div class="form-group">
                    <select name="teacher" id="teacher" class="form-control" [(ngModel)]="teacher">
                        <option value="">Choose Teacher to Re-Assign</option>
                        <option *ngFor="let teacher of teacherLists" [ngValue]="teacher">{{teacher.teacherName}}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-info">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>