<div class="container-fluid">
  <mat-card class="container-card">
    <mat-card-header>
      <mat-card-title>User Analytics Info</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
      <div class="toggle-buttons">
        <div style="padding-left: 20px;">
          <button mat-raised-button [color]="selectedTimeType === 'hourly' ? 'primary' : 'basic'" (click)="changeTimeType('hourly')">Hourly</button>
          <button mat-raised-button [color]="selectedTimeType === 'Daily' ? 'primary' : 'basic'" (click)="changeTimeType('Daily')">Daily</button>
          <button mat-raised-button [color]="selectedTimeType === 'Weekly' ? 'primary' : 'basic'" (click)="changeTimeType('Weekly')">Weekly</button>
          <button mat-raised-button [color]="selectedTimeType === 'Monthly' ? 'primary' : 'basic'" (click)="changeTimeType('Monthly')">Monthly</button>
          <button (click)="downloadCanvas()" mat-button color="primary">
            <mat-icon>cloud_download</mat-icon> Download
        </button>     </div>
        <div class="right-side">
          <mat-form-field appearance="fill" *ngIf="selectedTimeType === 'hourly'">
            <mat-label>Choose Hour range</mat-label>
            <mat-select [(ngModel)]="idHour" (selectionChange)="setHour($event.value)">
              <mat-option *ngFor="let hr of hourData" [value]="hr.idHour">
                {{ hr.hour }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div>
            <mat-form-field class="example-full-width" appearance="fill" *ngIf="selectedHour === 24">
              <mat-label>Choose a date</mat-label>
              <input matInput [matDatepicker]="picker" [max]="maxDate" [min]="minDate" (dateChange)="selectedDay($event)">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="fill" *ngIf="selectedTimeType === 'Daily'">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker" [max]="maxDate" [min]="minDate" [formGroup]="dateRange">
                <input matStartDate matInput placeholder="Start date" formControlName="start"/>
                <input matEndDate matInput placeholder="End date" formControlName="end" (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"/>
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker (closed)="onClose()"></mat-date-range-picker>
            </mat-form-field>
            <div *ngIf="selectedTimeType === 'Weekly' || selectedTimeType === 'Monthly'">
              <app-month-year-calendar (dateSelected)="handleFromDateSelected($event)"></app-month-year-calendar>
              <app-month-year-calendar style="margin-left: 10px;" (dateSelected)="handleDateToSelected($event)"></app-month-year-calendar>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 400px;">
        <canvas id="liveCountChart"></canvas>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
