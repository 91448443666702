import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reassign-teacher-popup',
  templateUrl: './reassign-teacher-popup.component.html',
  styleUrls: ['./reassign-teacher-popup.component.css']
})
export class ReassignTeacherPopupComponent implements OnInit {

  public webExPoolLists: any = [];
  public pool: any = '';

  constructor(private http: HttpClient, private toaster: ToastrService, @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) { }

  ngOnInit(): void {
    // this.http.get("/api/v1/teacher/fetch-all-teachers")
    //   .subscribe(res => {
    //     this.teachersList = res['teachersList'];
    //   }, err => {
    //     console.log(err['error'].message, err);
    //   });
    this.http.get('/api/v1/webex/get-all-unassigned-teachers')
      .subscribe(res => {
        this.webExPoolLists = res['teachersList'];
      }, err => {
        console.log(err['error'].message, err);
      });
  }

  reAssignWebExPoolToTeacher() {
    this.http.get("/api/v1/webex/reAssignWebExPoolToTeacher?idWebExPool=" + this.pool.idWebExPool + "&idTeacher=" + this.data.teacher.idTeacher)
      .subscribe(res => {
        if (res['data']) {
          this.toaster.success("WebEx Host Re-Assigned to " + this.data.teacher.teacherName, "Success");
          setTimeout(() => {
            this.dialog.closeAll();
          }, 1000);
        }
      }, err => {
        this.toaster.error(err['error'].message, "Server Error");
      });
  }

}
