<div class="col-lg-12" style="text-align: center">
  <mat-tab-group
    mat-align-tabs="center"
    [(selectedIndex)]="tabIndex"
    (selectedTabChange)="getData($event)"
  >
    <mat-tab [disabled]="form1" label="Live Course">
      <form style="margin: 3%" [formGroup]="firstFormGroup">
        <div class="row">
          <div class="col-sm-3">
            <mat-form-field class="form-element">
              <mat-label>Batch Type</mat-label>
              <mat-select formControlName="idProductLine" required>
                <mat-option
                  *ngFor="let data of productLineData; let j = index"
                  [value]="data.idProductLine"
                  >{{ data.productLine }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  firstFormGroup.controls['idProductLine'].errors?.required
                "
                >Batch Type is mandatory.</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-form-field class="form-element">
              <mat-label>Syllabus</mat-label>
              <mat-select formControlName="idSyllabus" required>
                <mat-option
                  *ngFor="let data of syllabusData; let j = index"
                  [value]="data.idSyllabus"
                  >{{ data.syllabusName }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="firstFormGroup.controls['idSyllabus'].errors?.required"
                >Syllabus is mandatory.</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-form-field class="form-element">
              <mat-label>Class Standard</mat-label>
              <mat-select formControlName="idClassStandard" required>
                <mat-option
                  *ngFor="let data of classStandardData; let j = index"
                  [value]="data.idClassStandard"
                  >{{ data.classStandadName }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  firstFormGroup.controls['idClassStandard'].errors?.required
                "
                >Class Standard is mandatory.</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-form-field class="form-element">
              <mat-label>Product Group Name</mat-label>
              <input
                matInput
                placeholder="Enter Name"
                formControlName="productGroupName"
                required
              />

              <mat-error
                *ngIf="
                  firstFormGroup.controls['productGroupName'].errors?.required
                "
                >Product Group Name is mandatory.</mat-error
              >
              <mat-error
                *ngIf="
                  firstFormGroup.controls['productGroupName'].errors?.maxlength
                "
                >Maximum Character Length is 45.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <br />

        <div class="row">
          <div style="width: 100%" class="col-sm-3">
            <mat-form-field class="form-element">
              <mat-label>Monthly Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="monthlySubscrAmt"
              />

              <mat-error
                *ngIf="
                  firstFormGroup.controls['monthlySubscrAmt'].errors?.pattern
                "
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-form-field style="width: 100%" class="form-element">
              <mat-label>Quarterly Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="qtrSubscrAmt"
              />

              <mat-error
                *ngIf="firstFormGroup.controls['qtrSubscrAmt'].errors?.pattern"
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div style="width: 100%" class="col-sm-3">
            <mat-form-field class="form-element">
              <mat-label>Annual Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="annualSubscrAmt"
              />

              <mat-error
                *ngIf="
                  firstFormGroup.controls['annualSubscrAmt'].errors?.pattern
                "
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3"></div>
        </div>
        <br />

        <div align="end">
          <button mat-raised-button color="warn" mat-dialog-close>Close</button>
          &nbsp; &nbsp; &nbsp;
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="createProductGroup()"
            [disabled]="!firstFormGroup.valid"
          >
           {{updateFlag==true ? "Update" : "Create"}}
          </button>
        </div>
      </form>
    </mat-tab>

    <mat-tab [disabled]="form2" label="Academic Course">
      <form style="margin: 3%" [formGroup]="secondFormGroup">
        <div class="row">
          <div class="col-sm-4">
            <mat-form-field style="width: 100%" class="form-element">
              <mat-label>Syllabus</mat-label>
              <mat-select formControlName="idSyllabus" required>

                <ng-container *ngFor="let data of syllabusData; let j = index">

                  <mat-option
                  *ngIf="data.idSyllabus != 4"
                  [value]="data.idSyllabus"
                  >{{ data.syllabusName }}</mat-option
                >

                </ng-container>
              
              </mat-select>
              <mat-error
                *ngIf="secondFormGroup.controls['idSyllabus'].errors?.required"
                >Syllabus is mandatory.</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-4">
            <mat-form-field style="width: 100%" class="form-element">
              <mat-label>Class Standard</mat-label>
              <mat-select formControlName="idClassStandard" required>
                <ng-container *ngFor="let data of classStandardData; let j = index">

                  <mat-option
                  *ngIf="data.idClassStandard != 4"
                  [value]="data.idClassStandard"
                  >{{ data.classStandadName }}</mat-option>                     
                </ng-container>
               
              </mat-select>
              <mat-error
                *ngIf="
                  secondFormGroup.controls['idClassStandard'].errors?.required
                "
                >Class Standard is mandatory.</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-4">
            <mat-form-field style="width: 100%" class="form-element">
              <mat-label>Product Group Name</mat-label>
              <input
                matInput
                placeholder="Enter Name"
                formControlName="productGroupName"
                required
              />

              <mat-error
                *ngIf="
                  secondFormGroup.controls['productGroupName'].errors?.required
                "
                >Product Group Name is mandatory.</mat-error
              >
              <mat-error
                *ngIf="
                  secondFormGroup.controls['productGroupName'].errors?.maxlength
                "
                >Maximum Character Length is 45.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <br />

        <div class="row">
          <div style="width: 100%" class="col-sm-3">
            <mat-form-field style="width: 100%" class="form-element">
              <mat-label>Monthly Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="monthlySubscrAmt"
              />

              <mat-error
                *ngIf="
                  secondFormGroup.controls['monthlySubscrAmt'].errors?.pattern
                "
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-form-field style="width: 100%" class="form-element">
              <mat-label>Quarterly Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="qtrSubscrAmt"
              />

              <mat-error
                *ngIf="secondFormGroup.controls['qtrSubscrAmt'].errors?.pattern"
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div style="width: 100%" class="col-sm-3">
            <mat-form-field class="form-element">
              <mat-label>Annual Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="annualSubscrAmt"
              />

              <mat-error
                *ngIf="
                  secondFormGroup.controls['annualSubscrAmt'].errors?.pattern
                "
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3"></div>
        </div>
        <br />

        <div align="end">
          <button mat-raised-button color="warn" mat-dialog-close>Close</button>
          &nbsp; &nbsp; &nbsp;
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="createAcademicPG()"
            [disabled]="!secondFormGroup.valid"
          >
          {{updateFlag==true ? "Update" : "Create"}}
          </button>
        </div>
      </form>
    </mat-tab>

    <mat-tab [disabled]="form3" label="Extracurricular">
      <form style="margin: 3%" [formGroup]="thirdFormGroup">
        <div class="row">
          <div class="col-sm-3">
            <mat-form-field class="form-element" style="width: 100%; pointer-events: none;">
              <mat-label>Syllabus</mat-label>
              <mat-select formControlName="idSyllabus"   required>
                <mat-option
                  *ngFor="let data of syllabusData; let j = index"
                  [value]="data.idSyllabus"
                  >{{ data.syllabusName }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="thirdFormGroup.controls['idSyllabus'].errors?.required"
                >Syllabus is mandatory.</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-form-field class="form-element" style="width: 100%; pointer-events: none;">
              <mat-label>Class Standard</mat-label>
              <mat-select formControlName="idClassStandard" required >
                <mat-option
                  *ngFor="let data of classStandardData; let j = index"
                  [value]="data.idClassStandard"
                  >{{ data.classStandadName }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                thirdFormGroup.controls['idClassStandard'].errors?.required
                "
                >Class Standard is mandatory.</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-form-field class="form-element">
              <mat-label>Product Group Name</mat-label>
              <input
                matInput
                placeholder="Enter Name"
                formControlName="productGroupName"
                required
              />

              <mat-error
                *ngIf="
                thirdFormGroup.controls['productGroupName'].errors?.required
                "
                >Product Group Name is mandatory.</mat-error
              >
              <mat-error
                *ngIf="
                thirdFormGroup.controls['productGroupName'].errors?.maxlength
                "
                >Maximum Character Length is 45.</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-form-field class="form-element">
              <mat-label>Category</mat-label>
              <input
                matInput
                placeholder="Enter Name"
                formControlName="extraCurrCategory"
                required
              />

              <mat-error
                *ngIf="
                thirdFormGroup.controls['extraCurrCategory'].errors?.required
                "
                >Category is mandatory.</mat-error
              >
              <mat-error
                *ngIf="
                thirdFormGroup.controls['extraCurrCategory'].errors?.maxlength
                "
                >Maximum Character Length is 45.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <br />

        <div class="row">
          <div style="width: 100%" class="col-sm-3">
            <mat-form-field class="form-element">
              <mat-label>Monthly Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="monthlySubscrAmt"
              />

              <mat-error
                *ngIf="
                thirdFormGroup.controls['monthlySubscrAmt'].errors?.pattern
                "
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-form-field style="width: 100%" class="form-element">
              <mat-label>Quarterly Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="qtrSubscrAmt"
              />

              <mat-error
                *ngIf="thirdFormGroup.controls['qtrSubscrAmt'].errors?.pattern"
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div style="width: 100%" class="col-sm-3">
            <mat-form-field class="form-element">
              <mat-label>Annual Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="annualSubscrAmt"
              />

              <mat-error
                *ngIf="
                thirdFormGroup.controls['annualSubscrAmt'].errors?.pattern
                "
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3"></div>
        </div>
        <br />

        <div align="end">
          <button mat-raised-button color="warn" mat-dialog-close>Close</button>
          &nbsp; &nbsp; &nbsp;
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="createExtraPG()"
            [disabled]="!thirdFormGroup.valid"
          >
          {{updateFlag==true ? "Update" : "Create"}}
          </button>
        </div>
      </form>
    </mat-tab>

    <mat-tab [disabled]="form4" label="Exam Preparation Quiz">

      <form style="margin: 3%" [formGroup]="fouthFormGroup">
        <div class="row">
          <div class="col-sm-3">
            <mat-form-field class="form-element" style="width: 100%;">
              <mat-label>Syllabus</mat-label>
              <mat-select formControlName="idSyllabus"   required>
                <mat-option
                  *ngFor="let data of syllabusData; let j = index"
                  [value]="data.idSyllabus"
                  >{{ data.syllabusName }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="fouthFormGroup.controls['idSyllabus'].errors?.required"
                >Syllabus is mandatory.</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-form-field class="form-element" style="width: 100%; ">
              <mat-label>Class Standard</mat-label>
              <mat-select formControlName="idClassStandard" required >
                <mat-option
                  *ngFor="let data of classStandardData; let j = index"
                  [value]="data.idClassStandard"
                  >{{ data.classStandadName }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                fouthFormGroup.controls['idClassStandard'].errors?.required
                "
                >Class Standard is mandatory.</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-form-field class="form-element">
              <mat-label>Product Group Name</mat-label>
              <input
                matInput
                placeholder="Enter Name"
                formControlName="productGroupName"
                required
                maxlength="45"
              />

              <mat-error
                *ngIf="
                fouthFormGroup.controls['productGroupName'].errors?.required
                "
                >Product Group Name is mandatory.</mat-error
              >
              <mat-error
                *ngIf="
                fouthFormGroup.controls['productGroupName'].errors?.maxlength
                "
                >Maximum Character Length is 45.</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3">

            <!-- <mat-form-field class="form-element">
              <mat-label>Category</mat-label>
              <input
                matInput
                placeholder="Enter Name"
                formControlName="extraCurrCategory"
                required
              />

              <mat-error
                *ngIf="
                fouthFormGroup.controls['extraCurrCategory'].errors?.required
                "
                >Category is mandatory.</mat-error
              >
              <mat-error
                *ngIf="
                fouthFormGroup.controls['extraCurrCategory'].errors?.maxlength
                "
                >Maximum Character Length is 45.</mat-error
              >
            </mat-form-field> -->

            <mat-form-field class="form-element" style="width: 100%; ">
              <mat-label> Category </mat-label>
              <mat-select formControlName="extraCurrCategory" required >
                <mat-option value="DIFFICULTY_LEVEL_1">Difficulty Level 1</mat-option>
                <mat-option value="DIFFICULTY_LEVEL_2" >Difficulty Level 2</mat-option>
                <mat-option value="DIFFICULTY_LEVEL_3">Difficulty Level 3</mat-option>
                <mat-option value="DIFFICULTY_LEVEL_4">Difficulty Level 4</mat-option>
              </mat-select>
              <mat-error
              *ngIf="
              fouthFormGroup.controls['extraCurrCategory'].errors?.required
              "
              >Category is mandatory.</mat-error
            >
            <mat-error
              *ngIf="
              fouthFormGroup.controls['extraCurrCategory'].errors?.maxlength
              "
              >Maximum Character Length is 45.</mat-error
            >
            </mat-form-field>


          </div>
        </div>
        <br />

        <div class="row">
          <div style="width: 100%" class="col-sm-3">
            <mat-form-field class="form-element">
              <mat-label>Monthly Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="monthlySubscrAmt"
              />

              <mat-error
                *ngIf="
                fouthFormGroup.controls['monthlySubscrAmt'].errors?.pattern
                "
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-form-field style="width: 100%" class="form-element">
              <mat-label>Quarterly Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="qtrSubscrAmt"
              />

              <mat-error
                *ngIf="fouthFormGroup.controls['qtrSubscrAmt'].errors?.pattern"
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div style="width: 100%" class="col-sm-3">
            <mat-form-field class="form-element">
              <mat-label>Annual Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="annualSubscrAmt"
              />

              <mat-error
                *ngIf="
                fouthFormGroup.controls['annualSubscrAmt'].errors?.pattern
                "
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-3"></div>
        </div>
        <br />

        <div align="end">
          <button mat-raised-button color="warn" mat-dialog-close>Close</button>
          &nbsp; &nbsp; &nbsp;
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="createExamPreparation()"
            [disabled]="!fouthFormGroup.valid"
          >
          {{updateFlag==true ? "Update" : "Create"}}
          </button>
        </div>
      </form>
    </mat-tab>

    <mat-tab [disabled]="form5" label="Vista Challanger Test">

      <form style="margin: 3%" [formGroup]="fifthFormGroup">
        <div class="row">
          <div class="col-sm-4">
            <mat-form-field class="form-element" style="width: 100%;">
              <mat-label>Syllabus</mat-label>
              <mat-select formControlName="idSyllabus"   required>
                <mat-option
                  *ngFor="let data of syllabusData; let j = index"
                  [value]="data.idSyllabus"
                  >{{ data.syllabusName }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="fouthFormGroup.controls['idSyllabus'].errors?.required"
                >Syllabus is mandatory.</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-4">
            <mat-form-field class="form-element" style="width: 100%; ">
              <mat-label>Class Standard</mat-label>
              <mat-select formControlName="idClassStandard" required >
                <mat-option
                  *ngFor="let data of classStandardData; let j = index"
                  [value]="data.idClassStandard"
                  >{{ data.classStandadName }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                fouthFormGroup.controls['idClassStandard'].errors?.required
                "
                >Class Standard is mandatory.</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-4">
            <mat-form-field class="form-element"  style="width: 100%; ">
              <mat-label>Product Group Name</mat-label>
              <input
                matInput
                placeholder="Enter Name"
                formControlName="productGroupName"
                required
                maxlength="45"
              />

              <mat-error
                *ngIf="
                fouthFormGroup.controls['productGroupName'].errors?.required
                "
                >Product Group Name is mandatory.</mat-error
              >
              <mat-error
                *ngIf="
                fouthFormGroup.controls['productGroupName'].errors?.maxlength
                "
                >Maximum Character Length is 45.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <br />

        <div class="row">
          <div  class="col-sm-3">
            <mat-form-field class="form-element" style="width: 100%">
              <mat-label>Monthly Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="monthlySubscrAmt"
              />

              <mat-error
                *ngIf="
                fouthFormGroup.controls['monthlySubscrAmt'].errors?.pattern
                "
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-4">
            <mat-form-field style="width: 100%" class="form-element">
              <mat-label>Quarterly Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="qtrSubscrAmt"
              />

              <mat-error
                *ngIf="fouthFormGroup.controls['qtrSubscrAmt'].errors?.pattern"
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div  class="col-sm-4">
            <mat-form-field class="form-element" style="width: 100%">
              <mat-label>Annual Amount</mat-label>
              <input
                matInput
                placeholder="Enter Amount"
                formControlName="annualSubscrAmt"
              />

              <mat-error
                *ngIf="
                fouthFormGroup.controls['annualSubscrAmt'].errors?.pattern
                "
                >Invalid Amount!</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-sm-4"></div>
        </div>
        <br />

        <div align="end">
          <button mat-raised-button color="warn" mat-dialog-close>Close</button>
          &nbsp; &nbsp; &nbsp;
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="createVCT()"
            [disabled]="!fifthFormGroup.valid"
          >
          {{updateFlag==true ? "Update" : "Create"}}
          </button>
        </div>
      </form>q
    </mat-tab>

  </mat-tab-group>
</div>
