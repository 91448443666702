import { BrowserModule } from "@angular/platform-browser";
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Routes, RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { FullComponent } from "./layouts/full/full.component";
import { NavigationComponent } from "./shared/header-navigation/navigation.component";
import { SidebarComponent } from "./shared/sidebar/sidebar.component";
import { BreadcrumbComponent } from "./shared/breadcrumb/breadcrumb.component";
import { ToastrModule } from "ngx-toastr";
import { Approutes } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SpinnerComponent } from "./shared/spinner.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { DatePipe } from "@angular/common";
import { RecordedSessionsComponent } from "./recorded-sessions/recorded-sessions.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatStepperModule } from "@angular/material/stepper";
import { MatChipsModule } from "@angular/material/chips";
import { TokenInterceptor } from "./core/interceptor";
import { AuthguardService } from "./authguard.service";
import { MatTableModule } from "@angular/material/table";
import { LoadingSpinnerComponent } from "./loading-spinner/loading-spinner.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { LoadingInterceptor } from "./core/loading.interceptor";
import { LoadingService } from "./core/loading.service";
import { QuizSubmitModelComponent } from "./quiz/quiz-submit-model/quiz-submit-model.component";
import { NgxPaginationModule } from "ngx-pagination";
import { NgRedux, NgReduxModule } from "@angular-redux/store";
import { initialState, InitialState, ShopReducer } from "./reducers/reducer";
import { DataService } from "./dataservice";
import { NotificationPopupComponent } from "./notification/notification-popup/notification-popup.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ClipboardModule } from "@angular/cdk/clipboard";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from "angularx-social-login";
import { CalendarModule, DateAdapter } from "angular-calendar";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angularx-social-login";
import { MatPaginatorModule } from "@angular/material/paginator";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LiveStreamingService } from "./live-course/live-streaming-course/live-streaming-course.service";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { MessengerService } from "./ecommerce/cart/messenger/messenger.service";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { MatSortModule } from "@angular/material/sort";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { environment } from "src/environments/environment";
import { QuillModule } from "ngx-quill";
import { CheckLoginService } from "./check-login.service";
import { SharedmoduleModule } from "./sharedmodule/sharedmodule.module";
import { MatTabsModule } from "@angular/material/tabs";
import { ApplicationPipesModuleModule } from "./application-pipes-module/application-pipes-module.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { SeoService } from "./seo-service";
import { CodeInputModule } from "angular-code-input";
import { MatTableExporterModule } from "mat-table-exporter";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { SocialVideoPopupComponent } from "./moderator-social/social-video-popup/social-video-popup.component";
import { AddExamPreparationProductComponent } from "./add-exam-preparation-product/add-exam-preparation-product.component";
import { CreateExamPreparationQuizComponent } from "./create-exam-preparation-quiz/create-exam-preparation-quiz.component";
import { QuizBatchUploadModalComponent } from "./quiz-batch-upload-modal/quiz-batch-upload-modal.component";
import { VctProductComponent } from "./vct-product/vct-product.component";
import { AddVctProductComponent } from "./add-vct-product/add-vct-product.component";
import { VctQuizBatchUploadModalComponent } from "./vct-quiz-batch-upload-modal/vct-quiz-batch-upload-modal.component";
import { AddVctQuizQuestionComponent } from "./add-vct-quiz-question/add-vct-quiz-question.component";
import { OVCQuizBatchUploadModalComponent } from "./ovc-quiz-batch-upload-modal/ovc-quiz-batch-upload-modal.component";
import { EditQuizQuestionComponent } from "./edit-quiz-question/edit-quiz-question.component";
import { AdminOrderDetailsComponent } from "./admin-order-details/admin-order-details.component";
import { MatCardModule } from "@angular/material/card";
import { BulkSignupComponent } from './bulk-signup/bulk-signup.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { SubscribedUserDetailsComponent } from './subscribed-user-details/subscribed-user-details.component';
import { CouponInfoComponent } from './coupon-info/coupon-info.component';
import { ForgotPasswordComponent } from "./authentication/forgot-password/forgot-password.component";
import { LoginSignupModalComponent } from "./authentication/login-signup-modal/login-signup-modal.component";
import { GoogleSignupMetadataComponent } from "./authentication/login/google-signup-metadata/google-signup-metadata.component";
import { LoginComponent } from "./authentication/login/login.component";
import { SignupMetadataComponent } from "./authentication/login/signup-metadata/signup-metadata.component";
import { MobileLoginModalComponent } from "./authentication/mobile-login-modal/mobile-login-modal.component";
import { ResetPasswordComponent } from "./authentication/reset-password/reset-password.component";
import { BookPersonalCoachingBatchComponent } from "./book-personal-coaching-batch/book-personal-coaching-batch.component";
import { ComingSoonBroadcastComponent } from "./coming-soon-broadcast/coming-soon-broadcast.component";
import { ComingSoonFuniverseComponent } from "./coming-soon-funiverse/coming-soon-funiverse.component";
import { EntertainmentComponent } from "./entertainment/entertainment.component";
import { FullWindowVideoPlayerComponent } from "./full-window-video-player/full-window-video-player.component";
import { KidsProfileComponent } from "./kids-profile/kids-profile.component";
import { WeOfferComponent } from "./landing/we-offer/we-offer.component";
import { MaterialModelComponent } from "./material-model/material-model.component";
import { NotificationComponent } from "./notification/notification.component";
import { OrderIssueTicketsComponent } from "./order-issue-tickets/order-issue-tickets.component";
import { CreateBatchQuizComponent } from "./quiz/create-batch-quiz/create-batch-quiz.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { HtmlVideoPlayerModalComponent } from "./videojs/html-video-player-modal/html-video-player-modal.component";
import { StudentOfflineQuizReviewComponent } from "./quiz/student-offline-quiz-review/student-offline-quiz-review.component";
import { OrderSubscriptionModalComponent } from "./admin-order-details/order-subscription-modal/order-subscription-modal.component";
import { VideoEditingModelComponent } from "./video-editing-model/video-editing-model.component";
import { UploadExtarcurricularVideoComponent } from "./upload-extarcurricular-video/upload-extarcurricular-video.component";
import { VideoPlayerComponent } from "./video-player/video-player.component";
import { ProductGroupModalComponent } from "./product-group/product-group-modal/product-group-modal.component";
import { CreateProductAmountComponent } from "./component/create-product-amount/create-product-amount.component";
import { CreateProductDurationComponent } from "./component/create-product-duration/create-product-duration.component";
import { CreateProductPricingComponent } from "./component/create-product-pricing/create-product-pricing.component";
import { SubjectCreationModalComponent } from "./component/subject-creation-modal/subject-creation-modal.component";
import { AddSubjectChapterComponent } from "./subject-chapter/add-subject-chapter/add-subject-chapter.component";
import { AddExtraCurricularSubjectChapterComponent } from "./subject-chapter/extra-curricular-subject-chapter/add-extra-curricular-subject-chapter/add-extra-curricular-subject-chapter.component";
import { CreateCouponModelComponent } from "./component/create-coupon-model/create-coupon-model.component";
import { ReassignTeacherPopupComponent } from "./create-webex-pool/reassign-teacher-popup/reassign-teacher-popup.component";
import { ReAssignTeacherYoutubeMasterComponent } from "./assign-youtube-master-staff/re-assign-teacher-youtube-master/re-assign-teacher-youtube-master.component";
import { ReAssignTeacherUpcomingWebinarComponent } from "./admin-schedule-upcoming-webinar/re-assign-teacher-upcoming-webinar/re-assign-teacher-upcoming-webinar.component";
import { UserAnalyticsInfoComponent } from "./component/user-analytics-info/user-analytics-info.component";
import { AdminVideoEditComponent } from "./admin-video-edit/admin-video-edit.component";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20,
};

const routes: Routes = [];
declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    RecordedSessionsComponent,
    LoadingSpinnerComponent,
    AddExamPreparationProductComponent,
    CreateExamPreparationQuizComponent,
    QuizBatchUploadModalComponent,
    VctProductComponent,
    AddVctProductComponent,
    QuizSubmitModelComponent,
    VctQuizBatchUploadModalComponent,
    AddVctQuizQuestionComponent,
    OVCQuizBatchUploadModalComponent,
    EditQuizQuestionComponent,
    BulkSignupComponent,
    AdminOrderDetailsComponent,
    UserInfoComponent,
    SubscribedUserDetailsComponent,
    CouponInfoComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    LoginComponent,
    TermsAndConditionsComponent,
    MobileLoginModalComponent,
    SignupMetadataComponent,
    CreateBatchQuizComponent,
    NotificationComponent,
    NotificationPopupComponent,
    KidsProfileComponent,
    MaterialModelComponent,
    FullWindowVideoPlayerComponent,
    BookPersonalCoachingBatchComponent,
    LoginSignupModalComponent,
    HtmlVideoPlayerModalComponent,
    GoogleSignupMetadataComponent,
    EntertainmentComponent,
    ComingSoonBroadcastComponent,
    OrderIssueTicketsComponent,
    WeOfferComponent,
    ComingSoonFuniverseComponent,
    StudentOfflineQuizReviewComponent,
    AdminVideoEditComponent,
      ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBFxP5l1fitmYVucgFMIcXTkl2MoO-0aMs'
    }),
    AgmJsMarkerClustererModule,
    
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    MatButtonModule,
    ToastrModule.forRoot(),
 
    RouterModule.forRoot(Approutes, { scrollPositionRestoration: "enabled" }),
    PerfectScrollbarModule,
    Ng2SearchPipeModule,
    MatDividerModule,

    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatTabsModule,
    MatExpansionModule,
    MatIconModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatListModule,
    MatCheckboxModule,

   
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatStepperModule,
    MatChipsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    NgxPaginationModule,
    NgReduxModule,
    MatMenuModule,
    ClipboardModule,
    SocialLoginModule,
    MatTooltipModule,
    MatPaginatorModule,
    FlexLayoutModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SlickCarouselModule,
    MatSortModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
    }),
    QuillModule.forRoot(),
    SharedmoduleModule,
    MatIconModule,
    MatTabsModule,
    ApplicationPipesModuleModule,
    MatExpansionModule,
    CodeInputModule,
    MatTableExporterModule,
    MatCardModule  ],

  providers: [
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(""),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    LoadingService,
    SeoService,
    MessengerService,
    DatePipe,
    AuthguardService,
    DataService,
    LiveStreamingService,
    CheckLoginService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    LoadingSpinnerComponent,
    QuizSubmitModelComponent,
    NotificationPopupComponent,
    SocialVideoPopupComponent,
    OrderSubscriptionModalComponent,
    UserInfoComponent,
    VideoEditingModelComponent,
    UploadExtarcurricularVideoComponent,
    OVCQuizBatchUploadModalComponent,
    EditQuizQuestionComponent,
    VideoPlayerComponent,
    QuizBatchUploadModalComponent,
    VctQuizBatchUploadModalComponent,
    AddVctQuizQuestionComponent,
    ProductGroupModalComponent,
    CreateProductAmountComponent,
    CreateProductDurationComponent,
    CreateProductPricingComponent,
    SubjectCreationModalComponent,
    AddSubjectChapterComponent,
    AddExtraCurricularSubjectChapterComponent,
    CreateCouponModelComponent,
    ReassignTeacherPopupComponent,
    ReAssignTeacherYoutubeMasterComponent,
    ReAssignTeacherUpcomingWebinarComponent,
    UserAnalyticsInfoComponent,
    AdminVideoEditComponent    ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(ngRedux: NgRedux<InitialState>) {
    ngRedux.configureStore(ShopReducer, initialState);
  }
}