import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeFormat } from '../offline-courses/TimeFormat';
import { ShortNumberPipe } from '../pipes/short-number.pipe';
import { DateAgoPipe } from '../pipes/date-ago.pipe';



@NgModule({
  declarations: [TimeFormat,ShortNumberPipe,DateAgoPipe],
  imports: [
  ],
  exports: [TimeFormat,ShortNumberPipe,DateAgoPipe]
})
export class ApplicationPipesModuleModule { }
