import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";


@Injectable({
    providedIn: "root",
  })
  export class CheckLoginService 
  {
    _isAuthenticated: Observable<boolean>;
    private boolSubject: Subject<boolean>;

    constructor() {
        this.boolSubject = new Subject<boolean>();
        this._isAuthenticated = this.boolSubject.asObservable();
        this.checkUserLoggedIn();
    }

   checkUserLoggedIn()
   {
    let uid = localStorage.getItem("userSurId");
    let utk = localStorage.getItem("accessToken");
    let urol = localStorage.getItem("role");

    if (uid != null && utk != null && urol != null) {
        this.boolSubject.next(true);
    }
    else {
      let logInterval =   setInterval(() => {

     uid = localStorage.getItem("userSurId");
     utk = localStorage.getItem("accessToken");
     urol = localStorage.getItem("role");

            if (uid != null && utk != null && urol != null) {
                this.boolSubject.next(true);
                clearInterval(logInterval);
            }
            else{
                this.boolSubject.next(false);
            }

        },1000)
    }
   }

  }