<!-- <video width="400" controls>
    <source [src]="videoLink" type="video/mp4">    
  </video> -->


<!-- <div class="video">
	<video height="100%"  width="100%" controls #videoPlayer>
		<source [src]="videoLink" type="video/mp4" />
		Browser not supported
	</video>
</div> -->

<video id="my-video" controls height="100%"  width="100%" [src]="videoLink" autoplay="autoplay"  controlsList="nodownload">
  
  <p class="vjs-no-js">
      To view this video please enable JavaScript, and consider upgrading to
      a web browser that
      <a href="https://videojs.com/html5-video-support/" target="_blank"
        >supports HTML5 video</a
      >
    </p>
</video>