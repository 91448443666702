import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-subscription-modal',
  templateUrl: './order-subscription-modal.component.html',
  styleUrls: ['./order-subscription-modal.component.css']
})
export class OrderSubscriptionModalComponent implements OnInit {
 
  subscriptionData;
  orderStatusData: any;
  constructor( private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,private toaster: ToastrService, private http: HttpClient) 
    {
      this.subscriptionData = data;
      console.log(this.subscriptionData[0].idStudentOrder);
      this.getPaytmOrderStatus(this.subscriptionData[0].orderId )
     }

  ngOnInit(): void {
  }

  getPaytmOrderStatus(orderId)
  {
    this.http.get('/api/v1/subscription/view-order-status?orderId='+orderId)
      .subscribe((res:any) => {
        if (res.statusCode === 200) {
          
          console.log("Order data",res.data);
           this.orderStatusData = res.data.body;
          
        }
      }, err => {
        this.toaster.error(err['error'].message, "Error occurred while fetching payment status.");
      });
  }

  createSubscription(idStudentOrder) 
  {  
    if (this.orderStatusData.resultInfo.resultCode != "01" && this.orderStatusData.resultInfo.resultStatus != "TXN_SUCCESS") 
    {
      let flag = confirm("Payment status is not successful. \n Are you sure you want to create this subscription?");
      if(flag && idStudentOrder)
      {
        this.http.get('/api/v1/subscription/create-manually?idStudentOrder='+idStudentOrder)
      .subscribe((res:any) => {
        if (res.statusCode === 200) {
          
          console.log("New Subscription",res.data);
          this.toaster.success(res.message,"Success");
          
        }
      }, err => {
        this.toaster.error(err['error'].message, "Error occurred");
      });
      }
    }  else{

      
      if(idStudentOrder)
      {
        this.http.get('/api/v1/subscription/create-manually?idStudentOrder='+idStudentOrder)
      .subscribe((res:any) => {
        if (res.statusCode === 200) {
          
          console.log("New Subscripiton",res.data);
          this.toaster.success(res.message,"Success");
          
        }
      }, err => {
        this.toaster.error(err['error'].message, "Error occurred");
      });
      }
    }
     
  }


  upgradeSubscription(planType,idStudentOrder) 
  {  
    console.log(idStudentOrder);
    let type = planType === "ENP_AN" ? "Enterprise Plan" : "Enterprise Plus Plan";

   
      let flag = confirm("Are you sure you want to upgrade this subscription to "+ type +" ?");

      if(flag)
      {
       this.http.get('/api/v1/subscription/upgrade-purchase-level?idStudentOrder='+idStudentOrder+"&planType="+planType)
      .subscribe((res:any) => {
        if (res.statusCode === 200) {
          this.toaster.success(res.message,"Success");
        }
      }, err => {
        this.toaster.error(err['error'].message, "Error Occurred");
      });
      }
    }  


    refreshStudentOrder(idStudentOrder) 
  {  
       this.http.get('/api/v1/student-order/payment-order-status?idStudentOrder='+idStudentOrder)
      .subscribe((res:any) => {
        if (res.statusCode === 200) {
          this.toaster.success(res.message,"Success");
        }
      }, err => {
        this.toaster.error(err['error'].message, "Error Occurred");
      });
      
  }  
   
  }
