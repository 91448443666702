import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ModeratorSocialService } from '../moderator-social.service';

@Component({
  selector: 'app-social-video-popup',
  templateUrl: './social-video-popup.component.html',
  styleUrls: ['./social-video-popup.component.css']
})
export class SocialVideoPopupComponent implements OnInit {

  videoLink:any
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  clearInterval: any;

  constructor(public dialogRef: MatDialogRef<SocialVideoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ser : ModeratorSocialService,
    public toaster: ToastrService,) {
      this.getVideoObject()
     }

  ngOnInit(): void {
   
  }

  getVideoObject(){
    this.ser.getVidepObject(this.data).subscribe(
      (res : any =[]) => {   
       console.log('VideoObject') ;
       console.log(res);  
         
       
         this.videoLink = res.data.idSocialVideoResolution[2].videoLink;
         this.clearInterval = setInterval(() => {this.checkVideoStatus(res.data.idSocialVideo) }, 1000);
      
      },
     
      (error) => {    
       
      }
    );
  }


  

  checkVideoStatus(idSocialVideo){
    const video = document.getElementById('my-video');
    if (video  != null)
    {
      video.onended = (event) => {
        console.log('Video stopped either because 1) it was over, ' +
        'or 2) no further data is available.');

        clearInterval(this.clearInterval);
        this.dialogRef.close()
        };
    }
    
  }


  updatePlayStatus(idSocialVideo,status){  
    // this.ser.upDatePlayStatus(idSocialVideo,status).subscribe(
    //   (res : any =[]) => {   
    //     console.log("Paly_status");
    //     console.log(res)
    //   },     
    //   (error) => {            
    //   }
    // );   
  }


  

}
