<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <form class="form" (ngSubmit)="reAssignWebExPoolToTeacher()">
                <div class="form-group">
                    <label for="webex_pool">Select WebEx Host to Re-Assign</label>
                    <select name="webex_pool" id="webex_pool" [(ngModel)]="pool" class="form-control">
                        <option value="">Choose a WebEx Host to Re-Assign</option>
                        <option *ngFor="let pool of webExPoolLists" [ngValue]="pool">{{pool.webExUserId}}
                        </option>
                    </select>
                </div>
                <button type="submit" class="btn btn-info">Submit</button>
            </form>
        </div>
    </div>
</div>