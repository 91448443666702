import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
@Injectable({
  providedIn: "root",
})
export class VideoService {

  public baseUrl: any = "/api/v1/";
  httpOptions: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toaster: ToastrService
  ) {
    let accesstoken = localStorage.getItem("accessToken");
    if (
      accesstoken != null &&
      accesstoken != "" &&
      typeof accesstoken != undefined
    ) {
      this.httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
          "No-Auth": "True",
        }),
      };
    }
  }

  getClassStandard() {
    return this.http.get(this.baseUrl + "classStandard/", this.httpOptions);
  }

  getallProductLines(productCategory) {
    return this.http.get(
      "/api/v1/Product/getProductLine?productCategory=" + productCategory,
      this.httpOptions
    );
  }

  getSubjects() {
    return this.http.get(this.baseUrl + "subject/list", this.httpOptions);
  }

  getAllSyllabus() {
    return this.http.get("/api/v1/lead/syllabusLists", this.httpOptions);
  }
  setShowReview(idOvc,idUser,show){
    return this.http.put("/api/v1/offlineCourse/is-rating-visible?idOfflineVideoCourse="+idOvc+"&userSurId="+idUser+"&visibleFlag="+show, this.httpOptions);

  }
   getAllState(){
    return this.http.get("/api/v1/auth/statesList", this.httpOptions);
  }
  getAcademicSubjects(idClassStandard,idSyllabus,idState) {
    return this.http.get(
      this.baseUrl + "subject/academic/list?idClassStandard=" + idClassStandard +"&idSyllabus="+idSyllabus+"&idState="+idState,
      this.httpOptions
    );
  }
  getAvgChapterRating(idSubChapter){
    return this.http.get(
      this.baseUrl + "offlineCourse/get-average-chapter-rating?idSubjectChapter=" + idSubChapter,
      this.httpOptions
    );
  }
  getAvgSubRating(idProduct){
    return this.http.get(
      this.baseUrl + "offlineCourse/get-average-subject-rating?idProduct=" + idProduct,
      this.httpOptions
    );
  }

  getallChapter(idSubject,idClassStandard,idSyllabus,idState) {
    return this.http.get(
      this.baseUrl + "subject/" + idSubject + 
      "/chapters?idClassStandard="+idClassStandard+"&idSyllabus="+idSyllabus+"&idState="+idState,
      this.httpOptions
    );
  }

  createVideo(request: any) {
    return this.http.post(
      this.baseUrl + "offlineCourse/upload",
      request,
      this.httpOptions
    );
  }
getReviewDetails(idOVC,userSurId,userName,schoolName, from, to) {
  return this.http.get(
    this.baseUrl + "offlineCourse/get-videocourse-rating?idOfflineVideoCourse="+idOVC+"&userSurId="+userSurId+"&userName="+userName+"&schoolName="+schoolName+"&from="+from+"&to="+to,
    this.httpOptions
  );
}
  getVideoHistory() {
    return this.http.get(
      this.baseUrl + "offlineCourse/top10/history",
      this.httpOptions
    );
  }

  getVideoStreaminginfo(videoId) {
    return this.http.get(
      this.baseUrl + "offlineCourse/video/info?videoId=" + videoId,
      this.httpOptions
    );
  }

  getAllLanguages() {
    return this.http.get(this.baseUrl + "auth/languages", this.httpOptions);
  }

  getAllProductGroupByProductLineId(productLineId) {
    return this.http.get(
      this.baseUrl + "Product/" + productLineId + "/product-line",
      this.httpOptions
    );
  }

  getAllProductByIdProductGroup(idProductGroup) {
    return this.http.get(
      this.baseUrl + "Product/" + idProductGroup + "/product-group",
      this.httpOptions
    );
  }

  getProductByProductLine(productLineId, idClassStandard, idSubject, idSyllabus, idState) {
    return this.http.get(
      this.baseUrl +
        "Product/" +
        productLineId +
        "/class/" +
        idClassStandard +
        "/subject/" +
        idSubject +
        "/syllabus/"+
        idSyllabus+
        "/state/"+
        idState,
      this.httpOptions
    );
  }

  getClassStandardByProductGroup() {
    return this.http.get(
      this.baseUrl + "classStandard/academic/product-group",
      this.httpOptions
    );
  }

  getAllSubejctBasedonBatchGroup(idClassStandard) {
    return this.http.get(
      "/api/v1/batch/subjects?idClassStandard=" + idClassStandard,
      this.httpOptions
    );
  }

  uploadVideo(
    videoFile: File,
    idProduct: number,
    idChapter: number,
    idBatch: number,
    description: string,
    type: String
  ) {
    var formData: any = new FormData();
    formData.append("idProduct", idProduct);
    formData.append("type", type);
    formData.append("file", videoFile);
    formData.append("idChapter", idChapter);
    formData.append("description", description);
    formData.append("idBatch", idBatch);
    return this.http.post("/api/v1/Product/sample-video", formData);
  }

  getAllProductLineBasedonBatch(idClassStandard, idSubject) {
    return this.http.get(
      "/api/v1/batch/product-line?idClassStandard=" +
        idClassStandard +
        "&idSubject=" +
        idSubject,
      this.httpOptions
    );
  }

  getBatchDetails(formData) {
    let params = new HttpParams();
    params = params.append("productLineId", formData.productLineId);
    params = params.append("subjectId", formData.subjectId);
    return this.http.get(
      this.baseUrl + "batch/class/" + formData.classId + "/info",
      {
        params: params,
      }
    );
  }

  getAllAcademicVideo(idChap, idProduct) {
    console.log('idProduct', idProduct)

    return this.http.get(
      "/api/v1/offlineCourse/getCourseVideos/" + idProduct + "/" + idChap,
      this.httpOptions
    );
  }

  getAcademicVideoData(idOfflineCourse) {
    return this.http.get(
      "/api/v1/offlineCourse/" + idOfflineCourse,
      this.httpOptions
    );
  }

  updateVideo(request: any, idOfflineVideoCourse: number) {
    return this.http.post(
      this.baseUrl +
        "offlineCourse/updateVideoCourses?idOfflineVideoCourse=" +
        idOfflineVideoCourse,
      request,
      this.httpOptions
    );
  }

  getAllBatchesByIdTeacher(idTeacher){
    return this.http.get("/api/v1/batch/batch-details-by-teacher?idTeacher="+idTeacher,this.httpOptions)
  }
  getAllBatchConductedDetails(idBatch){
    return this.http.get("api/v1/batch/batch-run?idBatch="+idBatch,this.httpOptions);
  }

  deleteOfflineVideo(idOfflineVideoCourse: any) {
    return this.http.delete("api/v1/offlineCourse/remove-ovc?idOfflineVideoCourse="+idOfflineVideoCourse,this.httpOptions);
  }


  getAllECASubjects(){
    return this.http.get("/api/v1/subject/eca",this.httpOptions)
  }

  getAllECAProduct(){
    return this.http.get("/api/v1/Product/eca",this.httpOptions)
  }

  getAcadmicClassStandard(idState,idSyllabus){
    return this.http.get("api/v1/classStandard/academic-list?idState="+idState+"&idSyllabus="+idSyllabus,this.httpOptions)
  }
}
