import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { StudentOfflineQuizReviewComponent } from "../student-offline-quiz-review/student-offline-quiz-review.component";

@Component({
  selector: "app-quiz-submit-model",
  templateUrl: "./quiz-submit-model.component.html",
  styleUrls: ["./quiz-submit-model.component.css"],
})
export class QuizSubmitModelComponent implements OnInit , OnDestroy {
  score: any;
  totalNumerOfQuestions: any;
  category:any;

  constructor(
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private router: Router,
    private dialogRef: MatDialogRef<QuizSubmitModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = []
  ) {
    this.score = data.percentage;
    this.totalNumerOfQuestions = data.totalQuestions;
    this.category = data.category;

    console.error("dilog data",data);
  }

  ngOnInit(): void {}

  gotoCourse() {
    this.ngOnDestroy();
  }

  /**
   * @author Naveen Kumar 
   */
  // when model is closed explicitly the return to student progress page
  ngOnDestroy()
  {
    ///dont remove this code without authore permission 
    if(this.category == "Academic"){
      this.router.navigate(["/academic-progress"]);
    }
    else{
      this.router.navigate(["extra-curricular/progress"]);
    }
  }

  quizReview() {

    // let item = this.data.data.studentChapterQuiz;
    let item = this.data.studentChapterQuiz;
    item.category = this.data.category;
    console.error("item", item);
    let config = {
      closeOnNavigation: true,
      position: {
        top: "10px",
      },
      height: "80% ",
      width: "50vw",
      maxWidth: "100vw !important",
      //maxHeight:'80%',
      //  panelClass: 'full-screen-modal',

      data: item,
    };
    console.error("data from quiz submit",this.data);
    const dailog = this.dialog.open(StudentOfflineQuizReviewComponent, config);
  }

}
