<button
  mat-icon-button
  mat-dialog-close
  color="primary"
  aria-label="Example icon button with a home icon">
  <mat-icon>arrow_back</mat-icon>
</button>
<h3 align="center">
 VCT Quiz Question
</h3>
<br />
<div class="container">
    <div class="row" [ngClass]="updateFlag ? 'disable': ''">
        <div class="col-md-4">
          <mat-form-field >
            <mat-label>Select Type</mat-label>
            <mat-select [(ngModel)]="q.questionType" name="questionType" required (selectionChange)="onSelectionChange($event)">
              <mat-option>None</mat-option>
              <!-- subjective will be added in the future.-->
              <!-- <mat-option value="Subjective" hidden>Subjective</mat-option> -->
              <mat-option value="Objective">Objective</mat-option>
              <!-- <mat-option value="MultiSelect">MultiSelect</mat-option> -->
              <mat-option value="TrueOrFalse">True Or False</mat-option>
              <mat-option value="OneWord">One word</mat-option>
              <mat-option value="ProblemSolving">Problem Solving</mat-option>
              <mat-option value="Descriptive">Descriptive</mat-option>


            </mat-select>
          </mat-form-field>
        </div>
        
          <div class="col-md-4">
            <!-- <span>Number of options :  <input style="width: 5%;" class="form-control"></span> -->
            <label for="fname">Number of options : &nbsp;&nbsp;</label>
            <input
              style="width: 15%"
              type="number"
              id="fname"
              [(ngModel)]="q.noOfoptions"
              (change)="valuechange(q.noOfoptions)"
              name="fname"
              required
              [ngClass]="updateFlag ? 'disable': ''"
            />
          </div>
        
     
        <div class="col-md-4" >
          <label for="fname">Enter Marks : &nbsp;&nbsp;</label>
          <input
          min="1"
            style="width: 15%"
            type="number"
            id="fname"
            [(ngModel)]="q.marks"
            name="fname"
            required
            [ngClass]="updateFlag ? 'disable': ''"
          />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-6">
          <label for="fname"> Question Title : &nbsp;&nbsp;</label>
          <input
            style="width: 50%"
            type="text"
            id="fname"
            [(ngModel)]="q.questionTitle"
            name="fname"
            required
          />
        </div>
        <div class="col-md-6">
          <p>Your Selection: {{ q.questionTitle }}</p>
      </div>
      </div>
      <br/>
      <div class="row">
        <label for="fname">Enter Question : &nbsp;&nbsp;</label>
        <quill-editor [(ngModel)]="q.questionText" name="fname" trackChanges="all" [modules]="modules">
        </quill-editor> 
        <!-- <input
          style="width: 80%"
          class="form-control"
          type="text"
          id="fname"
          [(ngModel)]="q.questionText"
          name="fname"
          required
        /> -->
       
      </div>
     
      <br>
      <br>

      <div class="row">
        <label for="fname">Upload Diagram( Optional) &nbsp;&nbsp;</label>
        <input style="width: 80%" type="text" id="fname" name="fname"[(ngModel)]="diagramLoc" placeholder="S3 Bucket Public URL"/>
      </div>
  
      <br />
      <div class="row">
        <label for="fname">Enter Answer Text (Optional) &nbsp;&nbsp;</label>
        <input
          style="width: 70%"
          class="form-control"
          type="text"
          maxlength="250"
          id="fname"
          [(ngModel)]="q.answerText"
          name="fname"
        />
      </div>
      <br />
      <div class="row">
        <label for="fname">Enter Answer Options &nbsp;&nbsp;</label>
  
        <div class="row" *ngIf="!updateFlag">
          <div
            style="margin-left: 20px"
            *ngFor="let class of answerArray; let i = index"
          >
            <div class="row">
              <input
                style="width: 50%"
                maxlength="250"
                class="form-control"
                [(ngModel)]="at[class]"
                name="con"
              />
              &nbsp;&nbsp;
              <section
                style="margin-top: 5px"
                class="example-section"
                *ngIf="q.questionType != 'Subjective'"
              >
                <mat-checkbox
                  (change)="checkCorrectAnswer($event)"
                  [(ngModel)]="ac[class]"
                  name="con"
                  >Correct</mat-checkbox
                >
              </section>
              <br />
            </div>
          </div>
        </div>

        <div class="row" *ngIf="updateFlag">

          <div
            style="margin-left: 20px"
            *ngFor="let class of answerArray; let i = index"
          >
          <div class="row">
            <input
              style="width: 50%"
              maxlength="250"
              class="form-control"
              [(ngModel)]="class.textFieldValue"
              name="con"
            />
            &nbsp;&nbsp;
            <section
              style="margin-top: 5px"
              class="example-section"
              *ngIf="q.questionType != 'Subjective'"
            >
              <mat-checkbox
                [(ngModel)]="class.correctValueFlag"
                name="con"
                >Correct</mat-checkbox
              >
            </section>
            <br />

          </div>
          </div>

         

          </div>



      </div>
      <br />
      <div class="row">
        <a  *ngIf="!updateFlag" mat-stroked-button color="primary" (click)="Save(q, at, ac)">Save</a>
        <a  *ngIf="updateFlag" mat-stroked-button color="primary" (click)="update()">Update</a>
        <!-- &nbsp;
          <a mat-stroked-button color="primary" (Click)="addAnotherQuestion()">+ Add Another Question</a> -->
      </div>
</div>
