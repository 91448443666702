import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ToastrService } from "ngx-toastr";
import { ProductGroupService } from "../product-group.service";

@Component({
  selector: "app-product-group-modal",
  templateUrl: "./product-group-modal.component.html",
  styleUrls: ["./product-group-modal.component.css"],
})
export class ProductGroupModalComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fouthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  productLineData: any;
  classStandardData: any;
  tabIndex: number;
  syllabusData: any;
  numberPattern: string = "^[0-9]*$";
  dataFlag: boolean = false;
  form1: boolean = false;
  form2: boolean = false;
  form3: boolean = false;
  form4: boolean = false;
  form5: boolean = false;
  updateFlag: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<ProductGroupModalComponent>,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    public toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private serv: ProductGroupService
  ) {
    if (this.data != null) {
      this.getAllClassStandards();
      this.getAllProductLine("Batch");
      this.getAllSyllabus();
      this.updateFlag = true;
      if (this.data.idProductLine < 5 || (this.data.idProductLine > 6 && this.data.idProductLine < 10)) {

        this.firstFormGroup = this._formBuilder.group({
          idProductGroup: [this.data.idProductGroup],
          idProductLine: [this.data.idProductLine, Validators.required],
          idSyllabus: [this.data.idSyllabus, Validators.required],
          idClassStandard: [this.data.idClassStandard, Validators.required],
          monthlySubscrAmt: [
            this.data.monthlySubscrAmt,
            Validators.pattern(this.numberPattern),
          ],
          productGroupName: [
            this.data.productGroupName,
            [Validators.required, Validators.maxLength(45)],
          ],
          qtrSubscrAmt: [
            this.data.qtrSubscrAmt,
            Validators.pattern(this.numberPattern),
          ],
          annualSubscrAmt: [
            this.data.annualSubscrAmt,
            Validators.pattern(this.numberPattern),
          ],
        });

        this.createSecondEmptyForm();
        this.createThirdEmptyForm();
        this.createFourthEmptyForm();
        this.createFifthEmptyForm();

        this.tabIndex = 0;
        this.form2 = true;
        this.form3 = true;
        this.form4=true;
        this.form5=true;

      } else if (this.data.idProductLine == 5) {
        
        
        this.secondFormGroup = this._formBuilder.group({
          idProductGroup: [this.data.idProductGroup],
          idProductLine: [5, Validators.required],
          idSyllabus: [this.data.idSyllabus, Validators.required],
          idClassStandard: [this.data.idClassStandard, Validators.required],
          monthlySubscrAmt: [
            this.data.monthlySubscrAmt,
            Validators.pattern(this.numberPattern),
          ],
          productGroupName: [
            this.data.productGroupName,
            [Validators.required, Validators.maxLength(45)],
          ],
          qtrSubscrAmt: [
            this.data.qtrSubscrAmt,
            Validators.pattern(this.numberPattern),
          ],
          annualSubscrAmt: [
            this.data.annualSubscrAmt,
            Validators.pattern(this.numberPattern),
          ],
        });

        this.createFirsEmptyForm();
        this.createThirdEmptyForm();
        this.createFourthEmptyForm();
        this.createFifthEmptyForm();

        this.tabIndex = 1;
        this.form1 = true;
        this.form3 = true;
        this.form4=true;
        this.form5=true;

      } 
      else if (this.data.idProductLine == 6) {
      
     
        this.thirdFormGroup = this._formBuilder.group({
          idProductGroup: [this.data.idProductGroup],
          idProductLine: [6, Validators.required],
          idSyllabus: [this.data.idSyllabus, Validators.required],
          idClassStandard: [this.data.idClassStandard, Validators.required],
          extraCurrCategory: [this.data.extraCurrCategory, Validators.required],
          monthlySubscrAmt: [
            this.data.monthlySubscrAmt,
            Validators.pattern(this.numberPattern),
          ],
          productGroupName: [
            this.data.productGroupName,
            [Validators.required, Validators.maxLength(45)],
          ],
          qtrSubscrAmt: [
            this.data.qtrSubscrAmt,
            Validators.pattern(this.numberPattern),
          ],
          annualSubscrAmt: [
            this.data.annualSubscrAmt,
            Validators.pattern(this.numberPattern),
          ],
        });

        this.createFirsEmptyForm();
        this.createSecondEmptyForm();
        this.createFourthEmptyForm();
        this.createFifthEmptyForm();


        this.tabIndex = 2;
        this.form1 = true;
        this.form2 = true;
        this.form4=true;
        this.form5=true;
      }
      else if (this.data.idProductLine == 12) {

       

        this.createFirsEmptyForm();
        this.createSecondEmptyForm();
        this.createThirdEmptyForm();
        this.createFifthEmptyForm();

        this.fouthFormGroup = this._formBuilder.group({
          idProductGroup: [this.data.idProductGroup],
          idProductLine: [12, Validators.required],
          idSyllabus: [this.data.idSyllabus, Validators.required],
          idClassStandard: [this.data.idClassStandard, Validators.required],
          extraCurrCategory: [this.data.extraCurrCategory, Validators.required],
          monthlySubscrAmt: [this.data.monthlySubscrAmt, Validators.pattern(this.numberPattern)],
          productGroupName: [
            this.data.productGroupName,
            [Validators.required, Validators.maxLength(45)],
          ],
          qtrSubscrAmt: [ this.data.qtrSubscrAmt, Validators.pattern(this.numberPattern)],
          annualSubscrAmt: [this.data.annualSubscrAmt, Validators.pattern(this.numberPattern)],
        });


        this.tabIndex = 3;
        this.form1 = true;
        this.form2 = true;
        this.form3 = true;
        this.form5=true;
      }

      else if (this.data.idProductLine == 13)
      {
        this.fifthFormGroup = this._formBuilder.group({
          idProductGroup: [this.data.idProductGroup],
          idProductLine: [13, Validators.required],
          idSyllabus: [this.data.idSyllabus, Validators.required],
          idClassStandard: [this.data.idClassStandard, Validators.required],
          extraCurrCategory: [this.data.extraCurrCategory, Validators.required],
          monthlySubscrAmt: [this.data.monthlySubscrAmt, Validators.pattern(this.numberPattern)],
          productGroupName: [
            this.data.productGroupName,
            [Validators.required, Validators.maxLength(45)],
          ],
          qtrSubscrAmt: [ this.data.qtrSubscrAmt, Validators.pattern(this.numberPattern)],
          annualSubscrAmt: [this.data.annualSubscrAmt, Validators.pattern(this.numberPattern)],
        });

        this.createFirsEmptyForm();
        this.createSecondEmptyForm();
        this.createThirdEmptyForm();
        this.createFourthEmptyForm();

        this.tabIndex = 4;
        this.form1 = true;
        this.form2 = true;
        this.form3 = true;
        this.form4 = true;
      }


    } else {

      this.createFirsEmptyForm();
      this.createSecondEmptyForm();
      this.createThirdEmptyForm();
      this.createFourthEmptyForm();
      this.createFifthEmptyForm();

      this.tabIndex = 0;
      this.getAllClassStandards();
      this.getAllProductLine("Batch");
      this.getAllSyllabus();
    }
  }

  ngOnInit(): void {}

  getData(tabChangeEvent: MatTabChangeEvent) {
    if (tabChangeEvent.index == 0) {
    } else if (tabChangeEvent.index == 1) {
      // get extra curicular  videos
    } else if (tabChangeEvent.index == 2) {
    } else {
      console.warn("invalid index:" + tabChangeEvent.index);
    }

  }

  getAllProductLine(productCategory) {
    this.productLineData = null;
    this.serv.getallProductLines(productCategory).subscribe((res: any = []) => {
      if (res != null) {
        this.productLineData = res;
      }
    });
  }

  getAllClassStandards() {
    this.classStandardData = null;
    this.serv.getClassStandard().subscribe((res: any = []) => {
      if (res.data != null) {
        console.log(res.data);
        this.classStandardData = res.data;
      }
    });
  }

  getAllSyllabus() {
    this.syllabusData = null;
    this.serv.getAllSyllabus().subscribe((res: any = []) => {
      if (res.data != null) {
        console.log(res.data);
        this.syllabusData = res.data;
      }
    });
  }

  createProductGroup() {
    if (this.updateFlag) {
      if (this.firstFormGroup.valid) {
        this.serv
          .updateProductGroup(this.firstFormGroup.value)

          .subscribe(
            (res: any) => {
              if (res.statusCode == "200" && res.data != null) {
                this.toaster.success(
                  "Product Group updated Successfully!",
                  "Success"
                );
                this.dataFlag = true;
                this.dialogRef.close();
              }
            },
            (error) => {
              console.log(error);
              this.toaster.error(error.error.message);
            }
          );
      }
    } else {
      if (this.firstFormGroup.valid) {
        this.serv
          .saveProductGroup(this.firstFormGroup.value)

          .subscribe(
            (res: any) => {
              if (res.statusCode == "200" && res.data != null) {
                this.dataFlag = true;
                this.dialogRef.close();
              }
            },
            (error) => {
              console.log(error);
              this.toaster.error(error.error.message);
            }
          );
      }
    }
  }

  createAcademicPG() {
    if (this.updateFlag) {
      if (this.secondFormGroup.valid) {
        this.serv
          .updateProductGroup(this.secondFormGroup.value)

          .subscribe(
            (res: any) => {
              if (res.statusCode == "200" && res.data != null) {
                this.toaster.success(
                  "Product Group updated Successfully!",
                  "Success"
                );
                this.dataFlag = true;
                this.dialogRef.close();
              }
            },
            (error) => {
              console.log(error);
              this.toaster.error(error.error.message);
            }
          );
      }
    } else {
      if (this.secondFormGroup.valid) {
        this.serv
          .saveProductGroup(this.secondFormGroup.value)

          .subscribe(
            (res: any) => {
              if (res.statusCode == "200" && res.data != null) {
                this.dataFlag = true;

                this.dialogRef.close();
              }
            },
            (error) => {
              console.log(error);
              this.toaster.error(error.error.message);
            }
          );
      }
    }
  }

  createExtraPG() {
    if (this.updateFlag) {
      if (this.thirdFormGroup.valid) {
        this.serv
          .updateProductGroup(this.thirdFormGroup.value)

          .subscribe(
            (res: any) => {
              if (res.statusCode == "200" && res.data != null) {
                this.toaster.success(
                  "Product Group updated Successfully!",
                  "Success"
                );
                this.dataFlag = true;
                this.dialogRef.close();
              }
            },
            (error) => {
              console.log(error);
              this.toaster.error(error.error.message);
            }
          );
      }
    } else {
      if (this.thirdFormGroup.valid) {
        this.serv
          .saveProductGroup(this.thirdFormGroup.value)

          .subscribe(
            (res: any) => {
              if (res.statusCode == "200" && res.data != null) {
                this.dataFlag = true;
                this.dialogRef.close();
              }
            },
            (error) => {
              console.log(error);
              this.toaster.error(error.error.message);
            }
          );
      }
    }
  } 
  
  
  createExamPreparation() {

    if (this.updateFlag) {
      if (this.fouthFormGroup.valid) {
        this.serv
          .updateProductGroup(this.fouthFormGroup.value)

          .subscribe(
            (res: any) => {
              if (res.statusCode == "200" && res.data != null) {
                this.toaster.success(
                  "Product Group updated Successfully!",
                  "Success"
                );
                this.dataFlag = true;
                this.dialogRef.close();
              }
            },
            (error) => {
              console.log(error);
              this.toaster.error(error.error.message);
            }
          );
      }
    } else {

      if (this.fouthFormGroup.valid) {
        this.serv
          .saveProductGroup(this.fouthFormGroup.value)

          .subscribe(
            (res: any) => {
              if (res.statusCode == "200" && res.data != null) {
                this.dataFlag = true;
                this.dialogRef.close();
              }
            },
            (error) => {
              console.log(error);
              this.toaster.error(error.error.message);
            }
          );
      }

    }
  }

  createVCT() {

    if (this.updateFlag) {
      if (this.fifthFormGroup.valid) {
        this.serv
          .updateProductGroup(this.fifthFormGroup.value)

          .subscribe(
            (res: any) => {
              if (res.statusCode == "200" && res.data != null) {
                this.toaster.success(
                  "Product Group updated Successfully!",
                  "Success"
                );
                this.dataFlag = true;
                this.dialogRef.close();
              }
            },
            (error) => {
              console.log(error);
              this.toaster.error(error.error.message);
            }
          );
      }
    } else {

      if (this.fifthFormGroup.valid) {
        this.serv
          .saveProductGroup(this.fifthFormGroup.value)

          .subscribe(
            (res: any) => {
              if (res.statusCode == "200" && res.data != null) {
                this.dataFlag = true;
                this.dialogRef.close();
              }
            },
            (error) => {
              console.log(error);
              this.toaster.error(error.error.message);
            }
          );
      }

    }
  }

  ngOnDestroy() {
    this.dialogRef.close({ data: this.dataFlag });
  }

  createFirsEmptyForm(){
    this.firstFormGroup = this._formBuilder.group({
      idProductGroup: [null],
      idProductLine: [null, Validators.required],
      idSyllabus: [null, Validators.required],
      idClassStandard: [null, Validators.required],
      monthlySubscrAmt: [null, Validators.pattern(this.numberPattern)],
      productGroupName: [
        null,
        [Validators.required, Validators.maxLength(45)],
      ],
      qtrSubscrAmt: [null, Validators.pattern(this.numberPattern)],
      annualSubscrAmt: [null, Validators.pattern(this.numberPattern)],
    });
  }

  createSecondEmptyForm(){
    this.secondFormGroup = this._formBuilder.group({
      idProductGroup: [null],
      idProductLine: [5, Validators.required],
      idSyllabus: [null, Validators.required],
      idClassStandard: [null, Validators.required],
      monthlySubscrAmt: [null, Validators.pattern(this.numberPattern)],
      productGroupName: [
        null,
        [Validators.required, Validators.maxLength(45)],
      ],
      qtrSubscrAmt: [null, Validators.pattern(this.numberPattern)],
      annualSubscrAmt: [null, Validators.pattern(this.numberPattern)],
    });
  }
  createThirdEmptyForm(){
    this.thirdFormGroup = this._formBuilder.group({
      idProductGroup: [null],
      idProductLine: [6, Validators.required],
      idSyllabus: [4, Validators.required],
      idClassStandard: [4, Validators.required],
      extraCurrCategory: [null, Validators.required],
      monthlySubscrAmt: [null, Validators.pattern(this.numberPattern)],
      productGroupName: [
        null,
        [Validators.required, Validators.maxLength(45)],
      ],
      qtrSubscrAmt: [null, Validators.pattern(this.numberPattern)],
      annualSubscrAmt: [null, Validators.pattern(this.numberPattern)],
    });

  }
  createFourthEmptyForm(){

    this.fouthFormGroup = this._formBuilder.group({
      idProductGroup: [null],
      idProductLine: [12, Validators.required],
      idSyllabus: [null, Validators.required],
      idClassStandard: [null, Validators.required],
      extraCurrCategory: [null, Validators.required],
      monthlySubscrAmt: [null, Validators.pattern(this.numberPattern)],
      productGroupName: [
        null,
        [Validators.required, Validators.maxLength(45)],
      ],
      qtrSubscrAmt: [null, Validators.pattern(this.numberPattern)],
      annualSubscrAmt: [null, Validators.pattern(this.numberPattern)],
    });


  }
  createFifthEmptyForm(){

    this.fifthFormGroup = this._formBuilder.group({
      idProductGroup: [null],
      idProductLine: [13, Validators.required],
      idSyllabus: [null, Validators.required],
      idClassStandard: [null, Validators.required],
      extraCurrCategory: ["VCT", Validators.required],
      monthlySubscrAmt: [null, Validators.pattern(this.numberPattern)],
      productGroupName: [
        null,
        [Validators.required, Validators.maxLength(45)],
      ],
      qtrSubscrAmt: [null, Validators.pattern(this.numberPattern)],
      annualSubscrAmt: [null, Validators.pattern(this.numberPattern)],
    });
  }

}
