import { NgRedux } from '@angular-redux/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { InitialState, initialState } from './reducers/reducer';
import { AddToCart, LoadItems, RemoveAllFromCart } from './store/actions';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  , OnDestroy{
  title = 'app';

  cartItems = [];
  scrollTopPositions: { [url: string]: number } = {};
  classStandardData: any;
  stateObject: any;
  syllabusObject: any;
  currentRoute : any;
  _isAuthenticated: boolean;
  userInterval: any;
  checkInterval:  any;

  constructor(private ngRedux: NgRedux<InitialState>, 
     private http: HttpClient,
     private router: Router, 
     viewportScroller: ViewportScroller,
     private titleService: Title,
     private activatedRoute: ActivatedRoute,
     private meta: Meta,public toaster: ToastrService
     ) {
    if (localStorage.getItem("userSurId") != null && localStorage.getItem("role") =="Student") {
      this.getUserSubscription();
      if(window.location.pathname != "/newCart"){
        this.getallCartItems();
      }
    }

    /**
     * @author Naveen Kumar A
     * 
     * below code will check for user is active or not 
     * when page is loaded.
     */
    let uid = localStorage.getItem("userSurId");
    let utk = localStorage.getItem("accessToken");
    let urol = localStorage.getItem("role");
   // console.log("app compoenet loading");
    if (uid != null && utk != null && urol != null) {
      this.invokeUserStatusCheck();
      this._isAuthenticated = true;
    }else 
    {
     this.userInterval = setInterval(() => {
        let uid1 = localStorage.getItem("userSurId");
        let utk1 = localStorage.getItem("accessToken");
        let urol1 = localStorage.getItem("role");

        if (uid1 != null && utk1 != null && urol1!= null) {
          this._isAuthenticated = true;
          this.invokeUserStatusCheck();
        } else {
          this._isAuthenticated = false;
           clearInterval(this.checkInterval);
        }
      }, 1000);
      
    }
   


    console.log("environment", environment.apiURL);
    router.events.pipe(
      filter((e: Scroll) => e instanceof Scroll)
    ).subscribe({
      next: (e: Scroll) => {
        if (e.position) {
          // backward navigation
          viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          // anchor navigation
          viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation
          viewportScroller.scrollToPosition([0, 0]);
        }
      }
    });

   

    
  }
  ngOnDestroy(): void {
    
  }
  ngOnInit(): void {

    /**
     * @author Naveen Kumar A
     * 
     * This fuctionallity will feed meta data of respective component
     * to index.html 
     */

     this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const childRoute = this.getChild(this.activatedRoute);
      childRoute.data.subscribe(data => {
        this.titleService.setTitle(data.title);
        this.meta.updateTag({ name: 'description', content: data.description});
        this.meta.updateTag({ name: 'keywords', content: data.keywords});
      });
    });
   
  }

  getallCartItems() {
    this.ngRedux.dispatch(RemoveAllFromCart());
    let userSurId = parseInt(localStorage.getItem("userSurId"));
    return this.http.get("/api/v1/usercart/list2?userSurId=" + userSurId).subscribe((res: any) => {
        if (res.data) {
          res.data.cartList.forEach(element => {
            this.ngRedux.dispatch(AddToCart(element));
          });
        }
      }, (err: any) => {
        console.error(err);
      });
  }

  getUserSubscription() {
    let userSurId = parseInt(localStorage.getItem("userSurId"));
    return this.http.get("/api/v1/subscription/user-subscription-postlogin?userSurId=" + userSurId).subscribe((res: any) => {
      if (res.statusCode == 200) {
        localStorage.setItem("subscriptionFlag", res.data.subscribedFlag);
        localStorage.setItem("__ff", btoa(unescape(encodeURIComponent(JSON.stringify(res.data.trialFlag)))));
      }
    }, (err: any) => {
      console.error(err);
    });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    }
    else {
      return activatedRoute;
    }
}

invokeUserStatusCheck()
{ //creating interval for every 1 mins
  // console.log("invokeUserStatusCheck");
  this.checkInterval = setInterval(() => {
    if(this._isAuthenticated)
    {
      this.checkUserStatus();
    }
    
  },60000)
}

checkUserStatus()
{ // user active status last checked time is local storage variable 
  let __uaslct = localStorage.getItem("__uaslct");
  if (__uaslct != null && __uaslct != undefined && __uaslct !="")
  {  
    let oldTime  =  JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem("__uaslct"))))); 
    let currentTime = new Date().valueOf();

        let uid1 = localStorage.getItem("userSurId");
        let utk1 = localStorage.getItem("accessToken");
        let urol1 = localStorage.getItem("role");

        if (uid1 == null ||  utk1 == null || urol1 == null)
        {
          return null;
        }
    
    if (currentTime > oldTime)
    {
    // console.log("checking user status at"+Date.now()); 
    let headers = new HttpHeaders();
    headers = headers.append('skipLoader', 'true');
    this.http.get("/api/v1/user/status",{headers}).subscribe((res: any) => {
      if (res.statusCode == 200) {
        
        if (!res.data.isActive)
        { 
          // this.toaster.warning(res.message,"Autherization Error");
          alert(res.message);
          this.router.navigate(['/logout']);
          clearInterval(this.checkInterval);
        }
        else 
        {  let date = new Date();
          date.setMinutes(date.getMinutes() + 5);
          //console.log("next check time: ",date.valueOf());
          clearInterval(this.checkInterval);
          localStorage.setItem("__uaslct", btoa(unescape(encodeURIComponent(JSON.stringify(date.valueOf())))));
        }
      }
    }, (err: any) => {
      console.error(err);
      if (err.error.status == "401")
      {
        if(this._isAuthenticated)
        {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Sorry, there was an error authorizing your account, Kindly logout and login once again.',
            allowOutsideClick:false,
            confirmButtonText:"Logout"
          }).then((result) => {
            /* Read more about isConfirme */
            if (result.isConfirmed) {
              clearInterval(this.checkInterval);
              this.router.navigate(['/logout']);
            }
          })
        }
      
      }
    });
    }

  }
  else {
    let date = new Date();
    date.setMinutes(date.getMinutes() + 5);
   // console.log("next check time: ",date.valueOf());
    localStorage.setItem("__uaslct", btoa(unescape(encodeURIComponent(JSON.stringify(date.valueOf())))));
  }
}


}