import { CartItem } from "../ecommerce/cart/cart-items/cart-items.model";
import { ActionTypes } from "../store/actions";

export interface InitialState {
    items: Array<CartItem>;
    cart: Array<CartItem>;
}
export const initialState = {
    items: [],
    cart: []
};

export function ShopReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LoadSuccess:
            return {
                ...state,
                items: [...action.payload]
            };
        case ActionTypes.Add:
            return {
                ...state,
                cart: [...state.cart, action.payload]
            };
        case ActionTypes.Remove:
            return {
                ...state,
                cart: [...state.cart.filter(item => item.idUserCart !== action.payload.idUserCart)]
            };
        case ActionTypes.RemoveAll:
            return {
                ...state,
                cart: []
            };
        default:
            return state;
    }

}
