import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';



@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor( private router: Router,private httpClient: HttpClient) {
    this.logout();
   }

  ngOnInit(): void {   
    //do nothing
  }


  logout(){ 
    let redirectUrl = ""
    if ( localStorage.getItem("role") != null){
      let role = localStorage.getItem("role");

      if (role === "Admin" || role === "Teacher" || role === "Telecaller" || role === "Blogger" || role === "Marketer"){
        redirectUrl= "authentication/login";

      }else {
        redirectUrl = "";
      }
    }
    this.logoutUpdation();
    localStorage.removeItem("subscriptionFlag");
    localStorage.removeItem("_tp");
    localStorage.removeItem("lastNavigation");
    localStorage.removeItem("_csusr");
    localStorage.removeItem("secondary_language");
    localStorage.removeItem("googleOAuthLoggedIn");
    localStorage.removeItem("role");
    localStorage.removeItem("lastName");
    localStorage.removeItem("userSurId");
    localStorage.removeItem("classStandardObject");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("mobileNumber");
    localStorage.removeItem("tokenType");
    localStorage.removeItem("userProfilePic");
    localStorage.removeItem("firstName");
    localStorage.removeItem("syllabusObject");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("_sas");
    localStorage.removeItem("_ses");
    localStorage.removeItem("stateObject");
    localStorage.removeItem("_idsss");
    localStorage.removeItem("_idecs");
    localStorage.removeItem("_rwv");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("__ff");
    localStorage.removeItem("__uaslct");
    localStorage.removeItem("idTeacher");
    localStorage.removeItem("idMarketer");
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate([redirectUrl]);
  }


  /**
   * @author NaveenKumar A
   * This is api will be called during the time logout 
   * to delete the stored token in databases;
   */
  logoutUpdation(){
    this.httpClient.post("api/v1/logout?secret=V0VC",{})
      .subscribe((res :any) => {
       
        console.error(res.data)
      } ,
      err => {
        console.error("An Error Occured!",err.error.message);
      }
      );
  }

}
