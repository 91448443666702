import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-re-assign-teacher-youtube-master',
  templateUrl: './re-assign-teacher-youtube-master.component.html',
  styleUrls: ['./re-assign-teacher-youtube-master.component.css']
})
export class ReAssignTeacherYoutubeMasterComponent implements OnInit {

  teacherLists: any = [];

  teacher: any = '';

  youtubeMaster: any = '';

  constructor(private http: HttpClient, private dialog: MatDialog, private toaster: ToastrService, @Inject(MAT_DIALOG_DATA) public data:any) {
  }

  ngOnInit(): void {
    this.http.get("/api/v1/auth/teacherLists").subscribe(
      (res) => {
        this.teacherLists = res["data"];
      },
      (err) => {
        console.error(err['error'].message, err);
      }
    );

    // this.youtubeMaster = this.data;
  }

  reAssignTeacher() {

    if (!this.teacher) {
      this.toaster.error("Please Select a Teacher", "Select Teacher from dropdown");
      return;
    }

    this.http.get('/api/v1/liveClass/reAssignTeacherToYoutubeMaster?idSelectedTeacher=' + this.teacher.idTeacher + "&idYoutubeMaster=" + this.data.youtubeMaster.idYoutubeMaster)
      .subscribe(res => {
        if (res['statusCode'] === 200 || 201) {
          this.dialog.closeAll();
          window.location.reload();
          this.toaster.success("Teacher Assigned Successfully", "Success");
        }
      }, err => {
        this.toaster.error(err['error'].message, "Unable to Assign Teacher");
      });
  }

}
