import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UserInfoComponent>, @Inject(MAT_DIALOG_DATA) 
  public data,public http: HttpClient) { }

  ngOnInit(): void {
  }

  updateActiveStatus(isActive:any)
  {  
    this.http.put('/api/v1/user/update-flag?userSurId=' + this.data.userSurid + '&activeFlag=' + isActive, '').subscribe((res: any) => {
      if (res.data != null) {
    this.dialogRef.close();
      }
    });
    this.dialogRef.close();
  }

  updateStudentFlag() {
    this.http.put('/api/v1/user/update-student-edit-flag?userSurId='+this.data.userSurid , '').subscribe((res: any) => {
      if (res.data != null) {
      }
    })
    this.dialogRef.close();
  }


}
