import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-product-pricing',
  templateUrl: './create-product-pricing.component.html',
  styleUrls: ['./create-product-pricing.component.css']
})
export class CreateProductPricingComponent implements OnInit {
  productPricingForm: FormGroup;

  productAmountList;
  productDurationList;
  productList: any;

  updateFlag = false;

  constructor(public http: HttpClient,private formBuilder: FormBuilder,private dialogRef: MatDialogRef<CreateProductPricingComponent>,private toaster: ToastrService,@Inject(MAT_DIALOG_DATA) public data) 
  { 
    this.getAllProducts();
    this.getAllProductAmount();
    this.getAllProductDuration();

   if (data != null)
   {
    this.updateFlag = true;
   }

  }

  ngOnInit(): void {
    if(this.updateFlag)
    {
      this.productPricingForm = this.formBuilder.group({
        idProductPricing: [this.data.idProductPricing],
        promoText: [this.data.promoText],
        planDescription: [this.data.planDescription,[Validators.required]],
        idProduct: [this.data.idProduct, [Validators.required]],
        idProductDuration: [this.data.idProductDuration, [Validators.required]],
        idProductAmount: [this.data.idProductAmount, [Validators.required]],
        activeFlag: [this.data.activeFlag]
      });
    } else
    {
      this.productPricingForm = this.formBuilder.group({
        idProductPricing: [null],
        promoText: [null],
        planDescription: [null,[Validators.required]],
        idProduct: [null, [Validators.required]],
        idProductDuration: [null, [Validators.required]],
        idProductAmount: [null, [Validators.required]],
        activeFlag: [true]
      });
    }
    
  }

  getAllProductAmount()
  {
    this.http.get('/api/v1/Product/product-amount',).subscribe((res: any) => {
      if (res.data != null) {
        console.log("result", res.data);
         this.productAmountList=res.data;
      }
    })
  }

  getAllProductDuration()
  {
    this.http.get('/api/v1/Product/product-duration',).subscribe((res: any) => {
      if (res.data != null) {
        console.log("result", res.data);
         this.productDurationList=res.data;
      }
    })
  }

  getAllProducts()
  {
    this.http.get('/api/v1/Product/list?idProductLine=11&idState=6&idSyllabus=4&idClassStandard=4',).subscribe((res: any) => {
      if (res.data != null) {
        console.log("result", res.data);
         this.productList=res.data;
      }
    })
  }

  insertDesc()
  {
    this.productPricingForm.controls['planDescription'].setValue("##Self-Assessment & Study Materials##Academic Live Classes for all Subjects##Access to all Academic Animated Videos##Learn from Conceptual Learning Videos##Talent Showcasing Platform##Extra Curricular Training##Spoken English Classes");
  }

  submit()
  {
    if(this.productPricingForm.valid)
    {
         
       if(this.updateFlag)
       {
        let payload = this.productPricingForm.value;
        payload.promoText =   payload.promoText == null ? null : payload.promoText.trim().length > 0 ?  payload.promoText.trim() :  null
        console.log(payload);
        this.http.put('/api/v1/Product/'+payload.idProductPricing+'/product-pricing',payload).subscribe((res: any) => {
          if (res.data != null) {
            this.toaster.success(res.message, "Success");
            this.dialogRef.close();
          }
        },(error:any) =>
        {
          this.toaster.error(error['error'].message, "Error");
        });
       }
      else{

        let payload = this.productPricingForm.value;

        this.http.post('/api/v1/Product/product-pricing',payload).subscribe((res: any) => {
          if (res.data != null) {
            this.toaster.success(res.message, "Success");
            this.dialogRef.close();
          }
        },(error:any) =>
        {
          this.toaster.error(error['error'].message, "Error");
        });
      }
     
    }

    else{
      this.toaster.error("Please fill all the mandatory fields. ", "Error");
    }

  }

}
