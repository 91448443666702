<h4 align="center">ExtraCurricular Video Upload Panel</h4>
<mat-horizontal-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="firstFormGroup">


    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Product Info</ng-template>
      <mat-dialog-content class="mat-typography">


        <div class="row">
          <div class="col-sm-4">
            <mat-form-field style="width: 100%">
              <mat-label>Topic</mat-label>
              <input
                matInput
                placeholder="Enter Your Video Topic"
                formControlName="topic"
                required
              />
            </mat-form-field>
          </div>

          <div class="col-sm-8">

            <mat-form-field style="width: 100%">
              <mat-label>Description</mat-label>
              <textarea
                formControlName="description"
                matInput
                matTextareaAutosize
                matAutosizeMinRows="1"
                placeholder="Video Description"
              >
              </textarea>
            </mat-form-field>

            <br />
          </div>



        </div>

        <div class="row">
          <!-- <div class="col-sm-4">
            <mat-form-field style="width: 100%">
              <mat-label>Category</mat-label>
              <mat-select
                (selectionChange)="getProduct($event.value)"
                required
                formControlName="productGroup"
              >
                <mat-option
                  *ngFor="let pg of productGroupData"
                  [value]="pg.idProductGroup"
                >
                  {{ pg.extraCurrCategory }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
  
          <div  class="col-sm-4">
            <mat-form-field style="width: 100%">
              <mat-label>Subject</mat-label>
              <mat-select
                required
                formControlName="product"
                (selectionChange)="getSubjectsChapter($event.value)"
              >
                <mat-option *ngFor="let product of productData" [value]="product">
                  {{ product.subjectName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
  
          <div *ngIf="playlistFalg" class="col-sm-4">
            <mat-form-field style="width: 100%">
              <mat-label>Chapter</mat-label>
              <mat-select required formControlName="chapter">
                <mat-option
                  *ngFor="let chapter of chapterData"
                  [value]="chapter.idSubjectChapter"
                >
                  {{ chapter.chapterName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>


        <div class="row">
          <div *ngIf="playlistFalg" class="col-sm-4">
            <mat-form-field style="width: 100%">
              <mat-label>Video Sequence Number</mat-label>
              <input
                type="number"
                matInput
                placeholder="Enter Your Video Sequence Number."
                formControlName="videoSeqNum"
                min="1"
                required
              />
            </mat-form-field>
          </div>
        </div>
      </mat-dialog-content>
      <div>
        <mat-dialog-actions align="end">
          <button [disabled]="!firstFormGroup.valid" mat-raised-button color="primary" align="end" matStepperNext>
            Next
          </button>
        </mat-dialog-actions>
      </div>
    </form>



    
  </mat-step>
  <mat-step [stepControl]="secondFormGroup" label="Video Info">
    <form [formGroup]="secondFormGroup">
      <div class="row">
        <div class="col-sm-4">
          <div *ngIf="linkPage">
            <mat-form-field style="width: 100%">
              <mat-label>Video Id</mat-label>
              <input
                formControlName="videoId"
                matInput
                title="English Video id goes here."
                placeholder="Enter the video Id"
                (blur)="getVideoStreaminginfo()"
                required
              />
            </mat-form-field>
          </div>
        </div>
		<div class="col-sm-8">
          <mat-form-field style="width: 100%">
            <mat-label>Heading</mat-label>
            <input
              matInput
              placeholder="Enter the Heading"
              formControlName="heading"
              required
            />
         <mat-error *ngIf="secondFormGroup.controls['heading'].errors?.required">This field is mandatory.</mat-error>
            <mat-error *ngIf="secondFormGroup.controls['heading'].errors?.maxlength">Maximum Character Length is 100.</mat-error> 
          </mat-form-field>
        </div> 
		</div>

    <div class="row"style="margin-top:10px">
      <div class="col-sm-2">
        <label for="file">Defualt Pdf</label>   
      </div>
      <div class="col-sm-6">
        <input type="file"id="file"placeholder="Upload file" accept=".pdf,.doc,.docx" (change)="uploadPdf($event)"formControlName="pdfURL">  
      </div>
      <div
      *ngIf="pdfURL != null"
      style="
        background-color: #e0e0e0;
        background-color: #e0e0e0;
        width: 200px;
        border-radius: 20px;
        align-content: center;
        text-align: center;
        padding-bottom: 10px;
      "
    >
      <!-- <span style="color: black; font-weight: 500">
       PDF_URL
      </span> -->
      <a href="{{pdfURL }}" target="_blank">View PDF</a>

      <button
        (click)="removepdf() "
        style="height: 10px; width: 10px"
        mat-icon-button
        aria-label="Example icon button with a vertical three dot icon"
      >
        <mat-icon style="font-size: 18px">cancel</mat-icon>
      </button>
    </div>
    </div>

    <div class ="row">
      <div class="col-sm-12">
        <mat-form-field style="width: 100%">
          <mat-label>Question</mat-label>
          <input
            matInput
            placeholder="Enter the Question Here"
            formControlName="question"
            required
          />
        <mat-error *ngIf="secondFormGroup.controls['question'].errors?.required">This field is mandatory.</mat-error>
            <mat-error *ngIf="secondFormGroup.controls['question'].errors?.maxlength">Maximum Character Length is 500.</mat-error> 
        </mat-form-field>
      </div>  
  </div>

  <div class="row">
    <div class="col-sm-10">
      <mat-form-field style="width: 100%;">
        <mat-label>Answer</mat-label>
        <textarea
                    required
                    formControlName="answer"
                    matInput
                    matTextareaAutosize
                    matAutosizeMinRows="1"
                    placeholder="Enter your answer"
                  >
                  </textarea>

                  <mat-error *ngIf="secondFormGroup.controls['answer'].errors?.required">This field is mandatory.</mat-error>
                  <mat-error *ngIf="secondFormGroup.controls['answer'].errors?.maxlength">Maximum Character Length is 1500.</mat-error> 
      </mat-form-field>
    </div>  

    <div class="col-sm-2">
        
      <button mat-raised-button type="button" (click)="generateStringValue()" align="end" >Generate</button>

    </div>


</div>

<div *ngIf="generatedValueFlag" style ="position: relative;"class="row">

  <code> {{generatedValue}}</code>
  <button style="position: absolute;  right: 20px; top: 0px;" [attr.cdkCopyToClipboard]="generatedValue" title="Copy to clipboard" mat-icon-button type="button">
    <mat-icon style="color: darkgrey;">content_copy</mat-icon>
  </button>
</div>
  
      <br />
      <div>
        <mat-dialog-actions>
          <button mat-raised-button matStepperPrevious>Back</button>
          <button mat-raised-button color="primary" align="end" matStepperNext [disabled]="!secondFormGroup.valid" >
            Next

          </button>
        </mat-dialog-actions>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="thirdFormGroup" label="Multi-Language Video Info">
    <form [formGroup]="thirdFormGroup">
      <div class="row">
        <div style ="position: relative;" class="col-sm-10">
          <mat-form-field style="width: 100%">
            <mat-label>Translated Text</mat-label>
            <textarea
                    formControlName="insertText"
                    matInput
                    matTextareaAutosize
                    matAutosizeMinRows="1"
                    placeholder="Enter your text"
                    style="height: 60px;"
                  >
                  </textarea>
                   
                </mat-form-field >

                <button style="position: absolute;  right: 20px; top: 0px;" (click)="onNavigateTranslator()" title="Open Google Translator" mat-icon-button type="button">
                  <mat-icon style="color: darkgrey;">g_translate</mat-icon>
                </button>

      </div>
    
        
        <div class="col-sm-2">
          <button mat-raised-button type="button" (click)="insertText()" align="end" style="padding-top:5px;" >Insert</button>
        </div>
      </div>

      <div class ="row">
        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>Language</mat-label>
            <mat-select formControlName="language">
              <mat-option
                *ngFor="let data of languageList; let j = index"
                [value]="data.language"
                >{{ data.language }}</mat-option
              >
            </mat-select>
          </mat-form-field>
       </div>


       <div class="col-sm-8">
        <mat-chip-list #chipList>
          <mat-chip
            *ngFor="let subChip of videoLinks; let k = index"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="removeSub(subChip, k)"
          >
            {{ subChip.language }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>

      </div>


      <div class="row">
        <div class="col-sm-4">
          <div *ngIf="linkPage">
            <mat-form-field style="width: 100%">
              <mat-label>Video Id</mat-label>
              <input
                formControlName="videoId"
                matInput
                title="English Video id goes here."
                placeholder="Enter the video Id"
                (blur)="getVideoStreaminginfo()"
                required
              />
            </mat-form-field>
          </div>
        </div>
		<div class="col-sm-8">
          <mat-form-field style="width: 100%">
            <mat-label>Heading</mat-label>
            <input
              matInput
              placeholder="Enter the Heading"
              formControlName="heading"
              required
            />
            <mat-error *ngIf="thirdFormGroup.controls['heading'].errors?.required">This field is mandatory.</mat-error>
            <mat-error *ngIf="thirdFormGroup.controls['heading'].errors?.maxlength">Maximum Character Length is 100.</mat-error>
          </mat-form-field>
        </div> 
		</div>

    <div class="row"style="margin-top:10px">
      <div class="col-sm-2">
        <label for="file">Language Pdf</label>   
      </div>
      <div class="col-sm-4">
        <input type="file"id="file"placeholder="Upload file" accept=".pdf,.doc,.docx" (change)="uploadLanguagePdf($event)" formControlName="langPdf">  
      </div>
    </div>

    <div class ="row">
      <div class="col-sm-12">
        <mat-form-field style="width: 100%">
          <mat-label>Question</mat-label>
          <input
            matInput
            placeholder="Enter the Question Here"
            formControlName="question"
            required
          />
          <mat-error *ngIf="thirdFormGroup.controls['question'].errors?.required">This field is mandatory.</mat-error>
            <mat-error *ngIf="thirdFormGroup.controls['question'].errors?.maxlength">Maximum Character Length is 500.</mat-error>
        </mat-form-field>
      </div>  
  </div>

  <div class="row">
    <div class="col-sm-10">
      <mat-form-field style="width: 100%">
        <mat-label>Answer</mat-label>
        <textarea
                    required
                    formControlName="answer"
                    matInput
                    matTextareaAutosize
                    matAutosizeMinRows="1"
                    placeholder="Enter your answer"
                  >
                  </textarea>

                  <mat-error *ngIf="thirdFormGroup.controls['answer'].errors?.required">This field is mandatory.</mat-error>
                  <mat-error *ngIf="thirdFormGroup.controls['answer'].errors?.maxlength">Maximum Character Length is 1500.</mat-error> 
      </mat-form-field>
    </div>  

    <div class="col-sm-2" align="center" style="padding-top: 15px;">
      <button mat-icon-button type="button" (click)="addVideoLink()">
        <mat-icon style="font-size: 40px;"> add_circle_outline</mat-icon>
      </button>
    </div>
</div>
  
      <br />
      <div>
        <mat-dialog-actions>
          <button  type="button" mat-raised-button matStepperPrevious>Back</button>
          <button
            [disabled]="!firstFormGroup.valid && !secondFormGroup.valid"
            (click)="createVideo()"
            mat-raised-button
            color="primary"
            align="end"
            matStepperNext
             type="button"
          >
            Submit
          </button>
        
        </mat-dialog-actions>
      </div>
    </form>
  </mat-step>

</mat-horizontal-stepper>
