import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { hierarchy } from "d3";
import { ToastrService } from "ngx-toastr";
import { UploadExtarcurricularVideoComponent } from "../upload-extarcurricular-video/upload-extarcurricular-video.component";
import { VideoEditingModelComponent } from "../video-editing-model/video-editing-model.component";
import { VideoService } from "../video-editing-model/video.service";
import { VideoPlayerComponent } from "../video-player/video-player.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-admin-video-edit",
  templateUrl: "./admin-video-edit.component.html",
  styleUrls: ["./admin-video-edit.component.css"],
})
export class AdminVideoEditComponent implements OnInit {
  idClassStandard: any;
  academicSubjectFlag: boolean;
  subjectData: any;
  productGroupData: any;
  classData: any;
  chapterFlag: boolean;
  chapterData: any;
  tabIndex: number;
  academicProductData: any;
  academicIdProduct: any;
  showAcademicVideoFlag: boolean = false;
  dataSource;
  idSubject:any;
  chapterRating:any;
  subRating:any;
  academicVideoData: any;
  size = 2;
  pageIndex = 0;
  config: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  productData: any;
  prodFlag: boolean;
  extraChapterData: any;
  extraChapterFlag: boolean;
  extraIdProduct: any;
  extraVideoData: any;
  idSyllabus: any;
  idChapter:any;
  config2: { itemsPerPage: number; currentPage: number; totalItems: any; };
  showExtraVideoFlag: boolean;
  idExtraProductGroup: any;
  syllabusData: any;
  classStandardFlag: boolean;
  secondFormGroup: FormGroup;
  idState: any;
  syllabusFlag: boolean;
  stateData: any;
  extraSubjectData: any;
  ecaSelectedChapter: any;
  constructor(
    public dialog: MatDialog,
    public toaster: ToastrService,
    private serv: VideoService,
    private _formBuilder: FormBuilder,
    private router: Router

  ) {
    this.secondFormGroup = this._formBuilder.group({
      idState :["",Validators.required],
      idSyllabus: ["", Validators.required],
      classStandard: ["", Validators.required],
      subject: ["", Validators.required],
      chapter: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.getClassStandardData();
    this.getAllSyllabus();
    this.getAllState();
  }
  getAllSyllabus() {
    this.serv.getAllSyllabus().subscribe((res: any = []) => {
      if (res.data != null) {
        this.syllabusData = res.data;

        for (let i = 0; i < this.syllabusData.length; i++) {
          if (this.syllabusData[i].idSyllabus == 4) {
            this.syllabusData.splice(i, 1);
            
          }
        }
      }
    });
  }

  getAllState() {
    this.serv.getAllState().subscribe((res: any = []) => {
      if (res.data != null) {
        this.stateData = res.data;

        for (let i = 0; i < this.stateData.length; i++) {
          if (this.stateData[i].idState == 6) {
            this.stateData.splice(i, 1);
            
          }
        }
      }
    });
  }

  getData(tabChangeEvent: MatTabChangeEvent) {
    if (tabChangeEvent.index == 0) {
      this.getClassStandardData();
      // academic videos
    } else if (tabChangeEvent.index == 1) {
      // get extra curicular  videos
      this.getExtraProduct();
    } else {
      console.warn("invalid index:" + tabChangeEvent.index);
    }
  }

  getClassStandardData() {
    this.classData = null;

    this.serv.getClassStandardByProductGroup().subscribe(
      (res: any) => {
        if (res.data != null) {
          this.classData = res.data;
        }
      },
      (error) => {
        this.toaster.error(error.error.message, "An Error Occured!");
      }
    );
  }
  routeToReviewDetails(idOfflineVideoCourse){
    this.router.navigate(['component/review-details'], { queryParams: {idOfflineVideoCourse:idOfflineVideoCourse,
      idState:this.idState,
      idSyllabus:this.idSyllabus,
      idClassStandard:this.idClassStandard,
      idSubject:this.idSubject,
      idSubChapter:this.idChapter,
      idProd:this.academicIdProduct
    }});
  }

  getSubjectsByClassStandard(idClassStandard) {
    if (idClassStandard == null) return;
    this.idClassStandard = idClassStandard;
    this.academicSubjectFlag = false;
    this.subjectData = null;
    this.chapterData = null;
    this.dataSource = null;
    this.showAcademicVideoFlag = false;
    this.serv.getAcademicSubjects(idClassStandard,this.idSyllabus,this.idState).subscribe(
      (res: any) => {
        if (res.data != null && res.statusCode == 200) {
          this.subjectData = res.data;
          this.academicSubjectFlag = true;
        }
      },
      (error) => {
        this.toaster.error(error.error.message, "An Error Occured!");
      }
    );
  }

  getProductGroup(idProductLine) {
    this.serv
      .getAllProductGroupByProductLineId(idProductLine)
      .subscribe((res: any = []) => {
        if (res.data != null) {
          this.productGroupData = res.data;
        }
      });
  }

  getSubjects(event) {
    this.chapterFlag = false;
    this.chapterRating = null

    if (event != null || event != "") {
      this.idSubject = event
      this.chapterData = null;
      this.dataSource = null;
      this.showAcademicVideoFlag = false;
      this.getProduct(5, this.idClassStandard, event,this.idSyllabus, this.idState);
      this.serv.getallChapter(event,this.idClassStandard,this.idSyllabus,this.idState).subscribe(
        (res: any) => {
          if (res.data != null && res.statusCode == "200") {
            this.chapterFlag = true;
            this.chapterData = res.data;
          }
        },
        (error) => {
          this.toaster.error(error.error.message, "An Error Occured!");
        }
      );
    }
  }

  getProduct(idProductLine, idClassStandard, idSubject, idSyllabus, idState) {
    this.academicProductData = null;
    this.academicIdProduct = null;
    this.subRating=null
    // id Syllabus has been hard coded
    this.serv
      .getProductByProductLine(idProductLine, idClassStandard, idSubject,idSyllabus, idState)
      .subscribe(
        (res: any = []) => {
          if (res.data != null) {
            this.academicProductData = res.data;

            this.academicIdProduct = res.data.idProduct;
            this.serv.getAvgSubRating(this.academicIdProduct).subscribe(
              (res:any)=>{
                  if (res.data != null){
                      this.subRating =res.data
                  }
              }
            )
          }
        },
        (error) => {
          console.warn(error);
          this.toaster.error(error.error.message, "An Error Occured!");
        }
      );
  }

  getAcademeicVideo(idChapter) {
    this.chapterRating =null
    this.idChapter = idChapter
    this.showAcademicVideoFlag = false;
    this.serv.getAvgChapterRating (idChapter).subscribe (
      (res:any= {})=>{
        if (res.data != null && res.data.statusCode!=500) {
            {
            this.chapterRating = res.data
        }
    }
      }
    )
    this.serv.getAllAcademicVideo(idChapter, this.academicIdProduct).subscribe(
      (res: any = []) => {
        if (res.data != null) {
          this.dataSource = res.data.offlineVideoCourseWithRating;
          this.academicVideoData = res.data.offlineVideoCourseWithRating;
          this.config = {
            itemsPerPage: 5,
            currentPage: 1,
            totalItems: this.dataSource.length,
          };
          this.showAcademicVideoFlag = true;
        }
      },
      (error) => {
        console.warn(error);
        this.toaster.error(error.error.message, "An Error Occured!");
      }
    );
  }
  showIcon(rating:number,index:number) {
    if (Math.round(rating) >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
  pageChanged(event) {
    this.config.currentPage = event;
  }

  editAcademicVideo(data) {
    this.serv.getAcademicVideoData(data.idOfflineVideoCourse).subscribe(
      (res: any = []) => {
        if (res.data != null) {
          const dialogRef = this.dialog.open(VideoEditingModelComponent, {
            width: "800px",
            maxHeight:"70vh",
            data: {
              linkUpload: true,
              offData: res.data,
              idOfflineVideoCourse: data.idOfflineVideoCourse,
            },
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result == null || result == undefined) return;
            if (result.data != null) {
              for (let i = 0; i < this.dataSource.length; i++) {
                if (
                  JSON.stringify(this.dataSource[i]) === JSON.stringify(data)
                ) {
                  this.dataSource[i] = result.data;
                }
              }
              this.toaster.success(
                "VideoStreaming Data Updated Successfully!",
                "Success"
              );
            }
            this.getAcademeicVideo(res.data.idSubjectChapter)
          });
        }
      },
      (error) => {
        console.warn(error);
        this.toaster.error(error.error.message, "An Error Occured!");
      }
    );
  }

  getVidePlayer(event) {
    let videoMetaObj: any = {
      video_otp: event.videoOtp,
      video_id: event.videoEnLink,
      video_theme: event.videoTheme,
      videoDuration: event.videoDuration,
      prev_position_timing: 0,
    };

    const dialogRef = this.dialog.open(VideoPlayerComponent, {
      panelClass: "custom-dialog-container",
      data: videoMetaObj,
    });
  }

  getExtraProduct() {
    
      this.productData = null;
      this.prodFlag = false;
      this.serv.getAllECAProduct().subscribe(
        (res: any) => {
          if (res.data != null) {
            this.productData = res.data;
            this.prodFlag = true;
          }
        },
        (error) => {
          console.warn(error);
          this.toaster.error(error.error.message, "An Error Occured!");
        }
      );
    
  }

  getAllSubjects() {
    this.serv.getAllECASubjects().subscribe((res: any = []) => {
      if (res.data != null) {
        this.extraSubjectData = res.data;
      }
    });
  }


  getSubjectsChapter(event) {
    if (event != null && event != "") {
      this.extraChapterData = null;
      this.extraIdProduct = event.idProduct;
      //idClassStandard has hardcoded for NA
      this.serv.getallChapter(event.idSubject,4,4,6).subscribe(
        (res: any) => {
          if (res.data != null && res.statusCode == "200") {
            this.extraChapterFlag = true;
            this.extraChapterData = res.data;
          }
        },
        (error) => {
          this.toaster.error(error.error.message, "An Error Occured!");
        }
      );
    }
  }

  getExtraVideo(idChapter) {

    

    this.showExtraVideoFlag = false;
    this.serv.getAllAcademicVideo(idChapter, this.extraIdProduct).subscribe(
      (res: any = []) => {
        if (res.data != null) {

          this.extraVideoData = res.data.offlineVideoCourseWithRating;
          this.config2 = {
            itemsPerPage: 5,
            currentPage: 1,
            totalItems: this.extraVideoData.length,
          };
          this.showExtraVideoFlag = true;
         
          this.ecaSelectedChapter = idChapter;
        }
      },
      (error) => {
        console.warn(error);
        this.toaster.error(error.error.message, "An Error Occured!");
      }
    );
  }

  pageChanged2(event) {
    this.config2.currentPage = event;
  }

  
  editExtraVideo(data) {
    this.serv.getAcademicVideoData(data.idOfflineVideoCourse).subscribe(
      (res: any = []) => {
        if (res.data != null) {
          const dialogRef = this.dialog.open(UploadExtarcurricularVideoComponent, {
            width: "800px",
            maxHeight:"70vh",
            data: {
              linkUpload: true,
              offData: res.data,
              idOfflineVideoCourse: data.idOfflineVideoCourse,
              idProductGroup: this.idExtraProductGroup,
            },
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result.data != null) {
              for (let i = 0; i < this.extraVideoData.length; i++) {
                if (
                  JSON.stringify(this.extraVideoData[i]) === JSON.stringify(data)
                ) {
                  this.extraVideoData[i] = result.data;
                }
              }
              this.toaster.success(
                "VideoStreaming Data Updated Successfully!",
                "Success"
              );
            }
          });
        }
      },
      (error) => {
        console.warn(error);
        this.toaster.error(error.error.message, "An Error Occured!");
      }
    );
  }
  setSyllabus(idSyllabus:number){
    this.idSyllabus = idSyllabus;
    this.classStandardFlag = true;
    this.secondFormGroup.controls["classStandard"].reset();
    this.academicSubjectFlag =false;
    this.chapterFlag = false;
    this.subjectData = null;
    this.chapterData = null;
  }
  setState(idState:any){
    this.idState= idState;
    this.secondFormGroup.controls["idSyllabus"].reset();
    this.classStandardFlag = true;
    this.secondFormGroup.controls["classStandard"].reset();
    this.academicSubjectFlag =false;
    this.chapterFlag = false;
    this.subjectData = null;
    this.chapterData = null;
  }

  deleteVideo(idOfflineVideoCourse)
  { 
    if (confirm("\n Are you sure you want to delete?  \n  \n This cannot be undone!  \n This operation will delete all information related to this video and it will impact overall student progress."))
    {
      this.serv.deleteOfflineVideo(idOfflineVideoCourse).subscribe(
        (res: any) => {
          if (res.statusCode == "200") {
            this.toaster.success(res.message, "Deleted");
            this.getAcademeicVideo(this.secondFormGroup.controls["chapter"].value)
          } 
        },
        (error) => {
          this.toaster.error(error.error.message, "An Error Occured!");
        }
      );
    }
  }

  deleteECAVideo(idOfflineVideoCourse)
  { 
    if (confirm("\n Are you sure you want to delete?  \n  \n This cannot be undone!  \n This operation will delete all information related to this video and it will impact overall student progress."))
    {
      this.serv.deleteOfflineVideo(idOfflineVideoCourse).subscribe(
        (res: any) => {
          if (res.statusCode == "200") {
            this.toaster.success(res.message, "Deleted");
            this.getExtraVideo(this.ecaSelectedChapter);
          } 
        },
        (error) => {
          this.toaster.error(error.error.message, "An Error Occured!");
        }
      );
    }
  }
}
