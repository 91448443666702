import { Subject } from "rxjs"

export class MessengerService {

    subject = new Subject()
  
    constructor() { }
  
    sendMsg(product) {
      this.subject.next(product)
    }
  
    getMsg() {
      return this.subject.asObservable()
    }
    
  }