import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-re-assign-teacher-upcoming-webinar',
  templateUrl: './re-assign-teacher-upcoming-webinar.component.html',
  styleUrls: ['./re-assign-teacher-upcoming-webinar.component.css']
})
export class ReAssignTeacherUpcomingWebinarComponent implements OnInit {

  teacherLists: any = [];

  teacher: any = '';
  youtubeMasterObj: any;
  showYoutubeMasterAssignedErrorMessage: boolean;

  constructor(private http: HttpClient, private dialog: MatDialog, private toaster: ToastrService, @Inject(MAT_DIALOG_DATA) public data:any) {

    console.log("liveclass-data",data);
  }

  ngOnInit(): void {
   
    this.getTeachers();
  }

  getTeachers()
  {
    if (this.data.liveClass.idLiveClassCategory == 1)
    {
      this.http.get("api/v1/teacher/getteacherByCategory?category="+"ACADEMIC").subscribe(
        (res) => {
          this.teacherLists = res["data"];
        },
        (err) => {
          console.error(err['error'].message, err);
        }
      );
    }
    else {

      this.http.get("api/v1/teacher/getteacherByCategory?category="+"EXTRA_CUR").subscribe(
        (res) => {
          this.teacherLists = res["data"];
        },
        (err) => {
          console.error(err['error'].message, err);
        }
      );
    }
  }

  
  getYoutubeMatserDataByIdTeacher(idTeacher) {
    console.log("idTeacher ",idTeacher);
    this.showYoutubeMasterAssignedErrorMessage = true;
    this.http.get('/api/v1/liveClass/getYoutubeMatserDataByIdTeacher?idTeacher=' + idTeacher)
      .subscribe(res => {
        this.youtubeMasterObj = res['data'];
        this.showYoutubeMasterAssignedErrorMessage = false;
      }, (err:any) => {
        this.toaster.error(err.error.message, "Internal Server Error");
        this.showYoutubeMasterAssignedErrorMessage = true;
      });
  }

  reAssignTeacher() {

    if (!this.teacher) {
      this.toaster.error("Please Select a Teacher", "Select Teacher from dropdown");
      return;
    }

    if (this.teacher.idTeacher == this.data.liveClass.idTeacher) {
      this.toaster.error("Please Select a Different Teacher", "Selected Teacher is already assigen to this live class");
      return;
    }


    if (this.showYoutubeMasterAssignedErrorMessage) {
      this.toaster.error("Youtube credentials not found for the selected teacher. ", "Validation Error");
      return;
    }
    this.http.get('/api/v1/liveClass/reAssignTeacherToLiveClass?idSelectedTeacher=' + this.teacher.idTeacher + "&idLiveClass=" + this.data.liveClass.idLiveClass)
      .subscribe(res => {
        if (res['statusCode'] === 200 || 201) {
          this.dialog.closeAll();
          this.toaster.success("Teacher Assigned Successfully", "Success");
          window.location.reload();
        }
      }, err => {
        this.toaster.error(err['error'].message, "Unable to Assign Teacher");
      });
  }

}
