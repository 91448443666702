export enum ActionTypes {
    Add = '[CartItem] Add to cart',
    Remove = '[CartItem] Remove from cart',
    RemoveAll = '[CartItem] Remove all from cart',
    LoadSuccess = '[CartItems] Load success'
}
export const AddToCart = payload => {
    return {
        type: ActionTypes.Add,
        payload
    };
};
export const RemoveFromCart = payload => {
    return {
        type: ActionTypes.Remove,
        payload
    };
};
export const RemoveAllFromCart = () => {
    return {
        type: ActionTypes.RemoveAll,
    };
};
export const LoadItems = payload => ({
    type: ActionTypes.LoadSuccess,
    payload
});