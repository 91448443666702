<h4 align="center" *ngIf="edit">Edit Coupon</h4>
<h4 align="center" *ngIf="!edit">Create Coupon</h4>

<form [formGroup]="couponCodeGroup" (ngSubmit)="createCoupon()">
  <mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="col-sm-3" *ngIf="selectedSpec !== 'Bulk Coupon'">
        <mat-form-field style="width: 100%">
          <mat-label>Coupon Code</mat-label>
          <input matInput placeholder="Enter Your coupon code" formControlName="couponCode" required maxlength="40" />
          <mat-error *ngIf="couponCodeGroup.controls['couponCode'].errors?.maxLength">Max length exceeded</mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-9">

        <mat-form-field style="width: 50%">
          <mat-label>Description</mat-label>
          <input formControlName="description" matInput placeholder="Coupon Description" maxlength="500" />
        </mat-form-field>

        <br />
      </div>
    </div>

      <div class="row">
      <div class="col-sm-4" *ngIf="selectedSpec === 'For a student'">
        <mat-form-field style="width: 100%">
          <mat-label>Enter Student Email or Phone No</mat-label>
          <input formControlName="emailOrPhone" matInput placeholder="Email or Phone No" maxlength="500" />

        </mat-form-field>
      </div>
      <div class="col-sm-4">
        <mat-form-field style="width: 100%">
          <mat-label>Coupon Type</mat-label>
          <mat-select (selectionChange)="setCouponType($event.value)" required formControlName="couponType" >
            <mat-option *ngFor="let coupon of couponTypes" [value]=coupon.couponTypeName >
              {{ coupon.couponTypeName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-4" *ngIf="extn">
        <mat-form-field style="width: 100%">
          <mat-label>Extension Duration</mat-label>
          <input matInput placeholder="Enter Extension Duration" formControlName="extensionDuration" required maxlength="40" />
<!-- user count zero, create coupons api use insted of create coupon  -->
        </mat-form-field>
      </div>

      <div  class="col-sm-4" *ngIf="disc">
        <mat-form-field style="width: 100%">
          <mat-label>Discount (Percent / Price)</mat-label>
          <input matInput placeholder="Enter discount value" formControlName="discount" required maxlength="40" />

        </mat-form-field>
      </div>


    </div>

      <div class="row">
      <div  class="col-sm-6">
        <mat-form-field style="width: 90%;">
          <mat-label>Select Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Start date" formControlName="startDate" #dateRangeStart>
              <input matEndDate placeholder="End date" formControlName="endDate" #dateRangeEnd (dateChange)="dateValueChangedTo(dateRangeStart, dateRangeEnd)">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      </div>


    </div>
    <div class="row">
      <div  class="col-sm-6" *ngIf="selectedSpec != 'For a student'">
        <mat-form-field style="width: 100%">
          <mat-label>Total count</mat-label>
          <input matInput placeholder="Enter total count" formControlName="totalCount" required maxlength="40"/>

        </mat-form-field>
      </div>

      <div  class="col-sm-6" *ngIf="selectedSpec === 'Bulk Coupon'">
        <mat-form-field style="width: 100%">
          <mat-label>Batch Name</mat-label>
          <input type="String" matInput placeholder="Enter Your Batch Name."
                  formControlName="batchName" [required]="batchNameIsRequired()" />
        </mat-form-field>
      </div>
    </div>
    <button type="submit" class="btn btn-info">Submit</button>

  </mat-dialog-content>
</form>
