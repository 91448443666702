import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
@Injectable({
  providedIn: "root",
})
export class ProductGroupService {
  public baseUrl: any = "/api/v1/";
  httpOptions: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toaster: ToastrService
  ) {
    let accesstoken = localStorage.getItem("accessToken");
    if (
      accesstoken != null &&
      accesstoken != "" &&
      typeof accesstoken != undefined
    ) {
      this.httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
          "No-Auth": "True",
        }),
      };
    }
  }

  getAllProductGroup() {
    return this.http.get("api/v1/product-group", this.httpOptions);
  }
  
  getAllStates() {
    return this.http.get("api/v1/state/list", this.httpOptions);
  }

  getClassStandard() {
    return this.http.get(this.baseUrl + "classStandard/", this.httpOptions);
  }

  getallProductLines(productCategory) {
    return this.http.get(
      "/api/v1/Product/getProductLine?productCategory=" + productCategory,
      this.httpOptions
    );
  }

  getAllSyllabus() {
    return this.http.get("/api/v1/lead/syllabusLists", this.httpOptions);
  }
  
  getAllSubjects() {
    return this.http.get("/api/v1/subject/list", this.httpOptions);
  }

  getAllProductGroups(idClassStandard,idSyllabus) {
    return this.http.get("/api/v1/product-group/exam-preparation?idClassStandard="+idClassStandard+"&idSyllabus="+idSyllabus, this.httpOptions);
  }

  getAllVCTProductGroups(idClassStandard,idSyllabus) {
    return this.http.get("/api/v1/product-group/vct?idClassStandard="+idClassStandard+"&idSyllabus="+idSyllabus, this.httpOptions);
  }

  saveProductGroup(data) {
    return this.http.post("/api/v1/product-group", data, this.httpOptions);
  }

  updateProductGroup(data) {
    return this.http.put("/api/v1/product-group", data, this.httpOptions);
  }

  getAllProductList(idProductLine,idState,idSyllabus,idClassStandard) {
    return this.http.get("/api/v1/Product/list?idProductLine="+idProductLine+"&idState="+idState+
    "&idSyllabus="+idSyllabus+"&idClassStandard="+idClassStandard, this.httpOptions);
  }

  getAllExamProductList(idProductLine,idState,idSyllabus,idClassStandard,categoryCode) {
    return this.http.get("/api/v1/Product/exam-preparation?idProductLine="+idProductLine+"&idState="+idState+
    "&idSyllabus="+idSyllabus+"&idClassStandard="+idClassStandard+"&categoryCode="+categoryCode, this.httpOptions);
  }

  saveProduct(data) {
    return this.http.post("/api/v1/Product", data, this.httpOptions);
  }

  updateProduct(data) {
    return this.http.put("/api/v1/Product", data, this.httpOptions);
  }

  getProductGroups(idProductLine) {
    return this.http.get("/api/v1/Product/"+idProductLine+"/product-line",  this.httpOptions);
  }
   
  getProductByProductGroup(idProductGroup)
  
  {
    return this.http.get("/api/v1/Product/"+idProductGroup+"/product-group",  this.httpOptions);

  }

  updateProductFlag(idProduct,activeFlag)
  {
    return this.http.put("/api/v1/Product/"+idProduct+"/active?isActive="+activeFlag,  this.httpOptions);
  }

}
