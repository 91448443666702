<h1 mat-dialog-title>Upload Batch Quiz File</h1>
<mat-dialog-content class="mat-typography">
    <div class="alert alert-info" role="alert">
        Note: Before proceeding with file upload directly , Please verify the data once by clicking verify button.
      </div>
    <div class="row">
    
        <div class="col-sm-6">
            <input
              type="file"
              accept=".csv"
              name="subIntroVideo"
              id="subIntroVideo"
              class="form-control"
              (change)="fileStore($event)"
              required
            />
          </div>
    
          <div class="col-sm-3">
            <button mat-raised-button color="basic" (click)="verifyFile()">Verify</button>
          </div>
    
    </div>
    <br>
    
    <h5 *ngIf="logs">Logs:</h5>
    <div class="row orow">
        <div class="col-sm-12">
         <pre *ngFor="let log of logs; let i = index" class="log"> <strong>{{i+1}}</strong>:&nbsp;{{log}}</pre>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="warn" [mat-dialog-close]="true">Close</button>
  <button mat-raised-button color="primary" cdkFocusInitial (click)="uploadFile()" [disabled]="disableUploadButton">Upload</button>
</mat-dialog-actions>
