<div mat-dialog-title>
    <div class="row">
        <div class="col-sm-4">
            <h2>Order Detailed Information</h2>
        </div>
        <div *ngIf="orderStatusData.remarks" class="col-sm-8 text-end" style="font-size: 12px;
        border-radius: 15px;">
            <div class="alert alert-info" role="alert">{{orderStatusData.remarks}}</div>
        </div>
    </div>
 
   
    <div class="row">
        <div class="col-sm-6">
            <div class="row header">
                <div class="col-sm-12">
                    <h4 class="text-center" style="font-weight: 800;
                        font-size: larger;"> Banking Transaction Details</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 key">
                    <label>Bank Name </label>
                </div>
                <div class="col-sm-6 value">
                    <p>{{subscriptionData[0].bankName || "NA"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 key">
                    <label>Bank Transaction ID </label>
                </div>
                <div class="col-sm-6 value">
                    <p>{{subscriptionData[0].bankTransactionId || "NA"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 key">
                    <label>Order ID </label>
                </div>
                <div class="col-sm-6 value">
                    <p>{{subscriptionData[0].orderId || "NA"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 key">
                    <label>Transaction Amount </label>
                </div>
                <div class="col-sm-6 value">
                    <p>{{(subscriptionData[0].transactionAmount | currency:"INR":"symbol") || "NA"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 key">
                    <label>Payment Mode </label>
                </div>
                <div class="col-sm-6 value">
                    <p>{{subscriptionData[0].paymentMode || "NA"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 key">
                    <label>Payment Status </label>
                </div>
                <div class="col-sm-6 value">
                    <p>{{subscriptionData[0].paymentStatus || "NA"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 key">
                    <label>Transaction Date </label>
                </div>
                <div class="col-sm-6 value">
                    <p>{{ (subscriptionData[0].transactionDate | date:'medium') || "NA"}}</p>
                </div>
            </div>
        </div>
        <!-- paytm payment order details   -->
        <div *ngIf="orderStatusData" class="col-sm-6">
            <div class="row header">
                <div class="col-sm-12">
                    <h4 class="text-center" style="font-weight: 800;
                        font-size: larger;"> Paytm Transaction Details</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 key">
                    <label>Gateway Name </label>
                </div>
                <div class="col-sm-6 value">
                    <p>{{orderStatusData.bankName || "NA"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 key">
                    <label>Transaction ID </label>
                </div>
                <div class="col-sm-6 value">
                    <p>{{orderStatusData.bankTxnId || "NA"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 key">
                    <label>Order ID </label>
                </div>
                <div class="col-sm-6 value">
                    <p>{{orderStatusData.orderId || "NA"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 key">
                    <label>Transaction Amount </label>
                </div>
                <div class="col-sm-6 value">
                    <p>{{(orderStatusData.txnAmount | currency:"INR":"symbol") || "NA"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 key">
                    <label>Payment Mode </label>
                </div>
                <div class="col-sm-6 value">
                    <p>{{orderStatusData.paymentMode || "NA"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 key">
                    <label>Payment Status </label>
                </div>
                <div class="col-sm-6 value">
                    <p>{{orderStatusData.resultInfo.resultStatus || "NA"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 key">
                    <label>Status Message </label>
                </div>
                <div class="col-sm-6 value">
                    <p>{{orderStatusData.resultInfo.resultMsg || "NA"}}</p>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-sm-4" style="text-align:justify; ">
            <pre>Subscription Details <mat-icon  style="color:#E9A6A6">fiber_manual_record</mat-icon></pre>
        </div>
        <div class="col-sm-4" style="text-align:justify; ">
            <pre>Product Details <mat-icon style="color:#BAABDA">fiber_manual_record</mat-icon></pre>
        </div>
        <div class="col-sm-4" style="text-align:justify;">
            <pre>Batch Details <mat-icon style="color:#A3DA8D">fiber_manual_record</mat-icon></pre>
        </div>
    </div>
</div>
<mat-dialog-content style="width: 100%;">
    <div class="row">
        <div class="col-md-12">
            <table>
                <thead class="black white-text">
                    <tr>
                        <th class="key sub">SI.No</th>
                        <th class="key sub">Purchase Date</th>
                        <th class="key sub">Next Payment Date</th>
                        <th class="key sub">Last Payment Date</th>
                        <th class="key sub">Subscription End Date</th>
                        <!-- <th class="key sub">Active</th> -->
                        <th class="key sub">Purchase Amount</th>
                        <th class="key sub">Subscription Type</th>
                        <th class="key sub">Purchase Level</th>
                        <th class="key sub">Purchase Type</th>
                        <th class="key sub">Special Offer</th>
                        <!-- <th class="key product">Monthly Amount</th>
                        <th class="key product">Quarterly Amount</th>
                        <th class="key product">Annual Amount</th> -->
                        <th class="key product">Product Name</th>
                        <!-- <th class="key product">Batch Size</th>
                        <th class="key product">Extracurricular Category</th> -->
                        <th class="key product">Class Standard</th>
                        <th class="key product">Syllabus</th>
                        <th class="key product">State</th>
                        <!-- <th class="key product">Subject</th>
                        <th class="key batch">Batch Name</th>
                        <th class="key batch">Batch End Date</th>
                        <th class="key batch">Batch Deactivated Date </th>
                        <th class="key batch">Batch Special Offer</th>
                        <th class="key batch">Batch Group Name</th> -->
                        <!-- <th class="key batch">Action</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr mdbTableCol *ngFor="let data of subscriptionData; let i = index">
                        <td class="value" scope="row">{{i+1}}</td>
                        <td class="value">{{ (data.purchaseDate | date:'medium') || "NA"}}</td>
                        <td class="value">{{ (data.nextPaymentDate | date:'medium') || "NA"}}</td>
                        <td class="value">{{ (data.lastPaymentDate | date:'medium') || "NA"}}</td>
                        <td class="value">{{ (data.subscriptionEndDate | date:'medium') || "NA"}}</td>
                        <!-- <td class="value">{{data.activeFlag? "Yes" : "No" }}</td> -->
                        <td class="value">{{(data.purchaseAmount | currency:"INR":"symbol") || "NA"}}</td>
                        <td class="value">{{data.subscriptionType || "NA"}}</td>
                        <td class="value">{{data.purchaseLevel || "NA" }}</td>
                        <td class="value">{{data.purchaseType || "NA" }}</td>
                        <td class="value">{{data.specialOfferFlag ? "Yes" : "No"}}</td>
                        <!-- <td class="value">{{ (data.monthlySubcrAmt | currency:"INR":"symbol") || "NA"}}</td>
                        <td class="value">{{ (data.qtrSubscrAmt | currency:"INR":"symbol") || "NA"}}</td>
                        <td class="value">{{ (data.annualSubscrAmt| currency: "INR":"symbol") || "NA"}}</td> -->
                        <td class="value">{{data.productName || "NA"}}</td>
                        <!-- <td class="value">{{data.batchSize || "NA"}}</td>
                        <td class="value">{{data.extraCurrCategory || "NA"}}</td> -->
                        <td class="value">{{data.classStandardName || "NA"}}</td>
                        <td class="value">{{data.syllabusName || "NA"}}</td>
                        <td class="value">{{data.stateName || "NA"}}</td>
                        <!-- <td class="value">{{data.subjectName || "NA"}}</td>
                        <td class="value">{{data.batchName || "NA"}}</td> -->
                        <!-- <td class="value">{{ (data.batchEndDate | date:'medium') || "NA"}}</td>
                        <td class="value">{{ (data.batchDeactivateDate | date:'medium') || "NA"}}</td>
                        <td class="value">{{data.batchSpecialOfferFlag? "Yes" : "No" }}</td>
                        <td class="value">{{data.batchGroupName || "NA"}}</td> -->
                        <!-- <td class="value"> <button mat-raised-button color="basic" (click)="createSubscription(subscriptionData[0].idStudentOrder)">create subscription</button></td>  -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</mat-dialog-content>
<br>

    <div class="row">
        <div class="col-sm-2"> 
            <button mat-raised-button color="basic" (click)="upgradeSubscription('ENP_AN',subscriptionData[0].idStudentOrder)">Upgrade To Enterprise</button>
        </div>
        <div class="col-sm-2">
            <button mat-raised-button color="basic" (click)="upgradeSubscription('ENP_AN_PLUS',subscriptionData[0].idStudentOrder)">Upgrade To Enterprise Plus </button>
        </div>
      

        <div class="col-sm-2">

        </div>

        <div class="col-sm-2"> 
            <button *ngIf="subscriptionData[0].paymentStatus == 'Pending' " mat-raised-button color="primary" (click)="refreshStudentOrder(subscriptionData[0]?.orderId)"><mat-icon>autorenew</mat-icon>  Refresh Order</button>
        </div>
      
        <div class="col-sm-2">
            <button mat-raised-button color="primary" (click)="createSubscription(subscriptionData[0].idStudentOrder)">Create Subscription</button>
        </div>

        <div class="col-sm-2"> 
            <button mat-raised-button mat-dialog-close color="warn">Close</button>
        </div>
    </div>
   
