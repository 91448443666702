
<div class="container">
    <h2 mat-dialog-title>  {{updateFlag == true ? 'Edit Subject' : 'Create Subject'}}</h2>

    <form  [formGroup]="subjectForm" novalidate>

    <div class="row" style="margin-top:2%">

        <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%">
              <mat-label>Subject  Name</mat-label>
              <input
                matInput
                placeholder="Enter Subject Name"
                formControlName="subjectName"
                required
                maxlength="45"
              />
  
              <mat-error
                *ngIf="subjectForm.controls['subjectName'].errors?.required"
                >Subject Name is mandatory.</mat-error
              >
              <mat-error
                *ngIf="subjectForm.controls['subjectName'].errors?.maxlength"
                >Maximum Subject Length is 45.</mat-error
              >
            </mat-form-field>
          </div>

      



        <div class="col-md-4">
            <mat-form-field   appearance="outline" style="width: 100%">
                <mat-label>Subject Header</mat-label>
                <input
                matInput
                placeholder="Enter Subject header"
                formControlName="header"
                required
                maxlength="100"
              />
                <mat-error
                *ngIf="
                  !subjectForm.controls['header'].valid &&
                  subjectForm.controls['header'].touched
                "
              >
              Subject header is mandatory.
              </mat-error>
            </mat-form-field>
        </div>


        <div class="col-md-4">
            <mat-form-field   appearance="outline" style="width: 100%">
                <mat-label>Introduction Video URL</mat-label>
                <input
                matInput
                placeholder="Enter Subject videoURL"
                formControlName="videoURL"
                required
                maxlength="500"
              />
                <mat-error
                *ngIf="
                  !subjectForm.controls['videoURL'].valid &&
                  subjectForm.controls['videoURL'].touched
                "
              >
              Subject video url is mandatory.
              </mat-error>
            </mat-form-field>
        </div>






        <div class="col-sm-10"> 
            <mat-form-field style="width:100%;" appearance="outline">
                <mat-label>Subject description </mat-label>
                <textarea matInput placeholder="Desciprion about subject" rows="3" formControlName="description" required maxlength="1000"></textarea>

                <mat-error
                *ngIf="
                  !subjectForm.controls['description'].valid &&
                  subjectForm.controls['description'].touched
                "
              >
                Subject description  is mandatory..
              </mat-error>
              </mat-form-field>
        </div>
      
     

        <div class="col-md-2">
            <mat-form-field   appearance="outline" style="width: 100%">
                <mat-label>Subject Color</mat-label>
                <input
                matInput
                placeholder="Enter Subject color"
                formControlName="color"
                required
                type="color"
                maxlength="100"
              />
                <mat-error
                *ngIf="
                  !subjectForm.controls['color'].valid &&
                  subjectForm.controls['color'].touched
                "
              >
                Please select color
              </mat-error>
            </mat-form-field>
        </div>



      
    </div>



    
<mat-dialog-actions align="end">
    <button  mat-raised-button color="warn" type="button" mat-dialog-close>Cancel</button>
    <button  (click)="callSubmit()" mat-raised-button color="primary"  type="button"  [disabled]="!subjectForm.valid">{{updateFlag == true ? 'Update' : 'Create'}}</button>
  </mat-dialog-actions>
</form>


</div>
