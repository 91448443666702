import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import * as Chart from "chart.js";
import { SubjectService } from 'src/app/subject/subject.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-student-offline-quiz-review',
  templateUrl: './student-offline-quiz-review.component.html',
  styleUrls: ['./student-offline-quiz-review.component.css']
})
export class StudentOfflineQuizReviewComponent implements OnInit {

  quizData: any;
  subjectDataReviewFlag: boolean;
  canvas: any;
  subjectQuizReviewData: any;
  config: any;
  category :any;

  constructor(
    public dialogRef: MatDialogRef<StudentOfflineQuizReviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: SubjectService,
    public toaster: ToastrService,
    private router: Router,
  ) {
    if (this.data != null) {
      console.error("data from student offline", data);
      this.quizData = data;
      console.error("quizdata",this.quizData);
      // this.quizData = data.data.studentChapterQuiz;
      this.getChapterQuizResultReview();
      
      this.category = this.data.category;
    }
  }

  ngOnInit(): void {}

  goToCourse(){
    this.ngOnDestroy();

  }
  ngOnDestroy()
  {
    ///dont remove this code without authore permission 
    if(this.category == "Academic"){
      this.router.navigate(["/academic-progress"]);
    }
    else{
      this.router.navigate(["extra-curricular/progress"]);
    }
    
  }

  getChapterQuizResultReview() {
    this.subjectDataReviewFlag = false;
    console.error("idStudentChapterQuiz", this.quizData.idStudentChapterQuiz)

    this.service
      .getChapterQuizResultReview(this.quizData.idStudentChapterQuiz)
      .subscribe(
        (res: any) => {
          if (res.statusCode == "200") {
            this.subjectQuizReviewData = res.data;
            this.subjectDataReviewFlag = true;

            console.warn(this.subjectQuizReviewData.reviewData.length);

            console.error("subjectQuizReviewData",this.subjectQuizReviewData.reviewData);

            this.config = {
              itemsPerPage: 5,
              currentPage: 1,
              totalItems: this.subjectQuizReviewData.reviewData.length,
            };

            setTimeout(() => {
              this.canvas = document.getElementById("chart");
              let total = this.subjectQuizReviewData.noOfQuestions;
              let correct = this.subjectQuizReviewData.correctAnswers;
              let wrong = total - correct;
              let per = this.subjectQuizReviewData.percentage;

              let resultChart = new Chart(this.canvas, {
                type: "doughnut",
                data: {
                  labels: ["Correct Answer", "Wrong Answer"],
                  datasets: [
                    {
                      label: "No.of.Questions",
                      backgroundColor: [
                        "rgba(84, 227, 70, 0.8)",
                        "rgba(255, 99, 132, 0.8)",
                      ],
                      //borderColor: ["rgba(84, 227, 70, 0.8)","rgba(255, 70, 70, 0.8 )"],
                      hoverBackgroundColor: ["#54e346", "#FF6384"],
                      borderWidth: 0.5,
                      data: [correct, wrong],
                    },
                  ],
                },
                options: {
                  title: {
                    display: true,
                    text: "Your Score " + per + " %",
                    position: "bottom",
                    fontSize: 18,
                  },
                },
              });
            }, 500);
          }
        },
        (error) => {
          console.error(error);
          this.toaster.error(error.error.message);
        }
      );
  }
  //
  checkObjective(student, answer) {
    // console.error("student", student);
    if (student[0].fieldId === answer.fieldId) {
      return true;
    }

    return false;
  }

  checkMultiSelect(student, answer) {
    let result = [];

    for (let i = 0; i < student.length; i++) {
      for (let j = 0; j < answer.length; j++) {
        if (student[i].fieldId == answer[j].fieldId) {
          result.push(answer[j].textFieldValue);
        }
      }
    }
    return result;
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }
}
