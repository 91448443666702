import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-month-year-calendar',
  templateUrl: './month-year-calendar.component.html',
  styleUrls: ['./month-year-calendar.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class MonthYearCalendarComponent {
  maxDate:any
  minDate:any
  @Input() 
  hint: string; 
  constructor(
  ) {
  this.maxDate = moment().endOf('month');
  this.minDate = moment().month("Jan").year(2024)
  console.log('hint', this.hint)
  }
  date = new FormControl(null);
  @Output() dateSelected = new EventEmitter<Moment>();
  
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value || moment();
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value || moment();
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();

  
    this.emitDateSelected(ctrlValue);

  }
  private emitDateSelected(date: Moment) {
    this.dateSelected.emit(date);
  }
}
