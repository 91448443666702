<div class="container">
    <h2 mat-dialog-title>Create Product Amount </h2>

<form class="form" [formGroup]="productAmountForm" #formDirective="ngForm" novalidate>
    <div class="row">

        <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%;">
              <mat-label>Enter Product Amount</mat-label>
              <input matInput  formControlName="amount" type="text">
            </mat-form-field>
            <mat-error
             *ngIf="
               !productAmountForm.controls['amount'].valid &&
               productAmountForm.controls['amount'].touched">
               Product Amount is mandatory
           </mat-error>
          </div>
          <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%;">
              <mat-label>Enter Old Amount</mat-label>
              <input matInput  formControlName="oldAmount" type="text">
            </mat-form-field>
          </div>

          <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%;">
              <mat-label>Enter Amount Name</mat-label>
              <input matInput  formControlName="amountName" type="text">
              <mat-error
             *ngIf="
               !productAmountForm.controls['amountName'].valid &&
               productAmountForm.controls['amountName'].touched">
              Product Amount Name is mandatory
           </mat-error>
            </mat-form-field>
          </div>


          <div class="col-md-4">
            <mat-form-field  appearance="outline" style="width: 100%;">
              <mat-label>Enter Amount Code</mat-label>
              <input matInput  formControlName="amountCode" type="text">
              <mat-error
             *ngIf="
               !productAmountForm.controls['amountCode'].valid &&
               productAmountForm.controls['amountCode'].touched">
              Amount code  is mandatory
           </mat-error>
            </mat-form-field>
            
          </div>

    </div>


</form>

<div mat-dialog-actions>
    <div class="row" style="width: 100%;">
        <div class="col-sm-3" style="text-align: left;">
            <button mat-raised-button color="warn"  mat-dialog-close>Close</button>
        </div>
        <div class="col-sm-3"></div>
        <div class="col-sm-3"></div>
        <div class="col-sm-3 " style="text-align: right;">
            <button mat-raised-button color="primary" [disabled]="!productAmountForm.valid"  (click)="submit()"> {{updateFlag ? 'Update':'Submit'}}</button>
        </div>
    </div>
   
  </div>

</div>
