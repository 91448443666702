//Added By Ahmed
import { Observable } from 'rxjs/index';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
//updated by NaveenKumar
@Injectable()
export class TokenInterceptor implements HttpInterceptor{
    
    constructor(private routes: Router, public toaster: ToastrService) {}
    intercept(request:HttpRequest<any>,next:HttpHandler,):Observable<HttpEvent<any>>{
        let token = localStorage.getItem('accessToken');


        if(token){
          // check token expired
           if ( this.tokenExpired(token))
           {
            console.log("User token expired");
            this.toaster.info("Your Session Got Expired!");
            this.routes.navigate(["/logout"]);
           }
           else {
            request = request.clone({
                setHeaders:{
                    Authorization:'Bearer ' + token
                }
            });
           }

          
        }
        return next.handle(request);
    }

    private tokenExpired(token: string) {
        const expiry = JSON.parse(atob(token.split(".")[1])).exp;
        return Math.floor(new Date().getTime() / 1000) >= expiry;
      }
}