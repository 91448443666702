import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RouteInfo } from './sidebar.metadata';
import { SidebarService } from './sidebar.service';
import { ROUTES, ROUTESStudent, ROUTESSTAFF, ROUTESPARENT, TelecallerROUTES, BloggerROUTES, MODERATOR_ROUTES, SALESADMIN_ROUTES, MARKETER_ROUTES } from './menu-items';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})


export class SidebarComponent {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: RouteInfo[] = [];
  path = '';
  public screenWidth: any;
  public collapseSidebar: boolean = false;
  public fullScreen: boolean = false;

  MENUITEMS: RouteInfo[] = ROUTES;

  items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);

  constructor(private menuServise: SidebarService, private router: Router) {

    if (localStorage.getItem('role') == "Admin") {
      this.MENUITEMS = ROUTES;
      this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);
    }

    if (localStorage.getItem('role') == "Student") {
      this.MENUITEMS = ROUTESStudent;
      this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);
    }

    if (localStorage.getItem('role') == "Teacher") {
      this.MENUITEMS = ROUTESSTAFF;
      this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);
    }

    if (localStorage.getItem('role') == "Parent") {
      this.MENUITEMS = ROUTESPARENT;
      this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);
    }

    if (localStorage.getItem('role') == "Telecaller") {
      this.MENUITEMS = TelecallerROUTES;
      this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);
    }

    if (localStorage.getItem('role') == "Blogger") {
      this.MENUITEMS = BloggerROUTES;
      this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);
    }

    if(localStorage.getItem('role')=="Moderator"){       
           
      this.MENUITEMS = MODERATOR_ROUTES; 
      this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);
  }


  if(localStorage.getItem('role')=="SalesAdmin"){       
           
    this.MENUITEMS = SALESADMIN_ROUTES; 
    this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);
}

if(localStorage.getItem('role')=="Marketer"){       
           
  this.MENUITEMS = MARKETER_ROUTES; 
  this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);
}



    this.items.subscribe(menuItems => {
      this.sidebarnavItems = menuItems;

      // Active menu 
      this.sidebarnavItems.filter(m => m.submenu.filter(
        (s) => {
          if (s.path === this.router.url) {
            this.path = m.title;
          }
        }
      ));
      this.addExpandClass(this.path);
    });
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = element;
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = element;
    } else {
      this.showSubMenu = element;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


}
