import { Injectable, Inject, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {Renderer2} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SeoService { 
     renderer: Renderer2;
   
  constructor(@Inject(DOCUMENT) private doc , private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  
  createLinkForCanonicalURL(url) {
     let link: HTMLLinkElement = this.doc.createElement('link');
     link.setAttribute('id', 'canonical');
     link.setAttribute('rel', 'canonical');
     this.doc.head.appendChild(link);
     link.setAttribute('href', url);
   }

   createHREFLink(url){
    const linkElt = this.renderer.createElement('link');
    this.renderer.setAttribute(linkElt,'id', 'lang');
    this.renderer.setAttribute(linkElt, 'rel', 'alternate');
    this.renderer.setAttribute(linkElt, 'hreflang', 'en-IN');
    this.renderer.setAttribute(linkElt, 'href', url);
    this.renderer.appendChild(this.doc.head, linkElt);
   }

   setMetaTags(url){
     
    let head = document.getElementsByTagName('head')[0]
    let canonical= document.getElementById('canonical');
    let lang= document.getElementById('lang');
    if(canonical && lang ){
      head.removeChild(canonical);
      head.removeChild(lang);
       
    }
       this.createHREFLink(url);
       this.createLinkForCanonicalURL(url);
   }

   setH1Tag(h1: string){
    const newLinkEl = document.createElement('h1');
    const newContent = document.createTextNode(h1);
    newLinkEl.appendChild(newContent);
    newLinkEl.setAttribute("style","display:none;")
    const currentDiv = document.getElementById("app-landing-navigation");
    document.body.insertBefore(newLinkEl,currentDiv);
   }
} 
