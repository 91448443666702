<nav class="sidebar-nav">
   
    <ul id="sidebarnav" style="flex-wrap: initial;">
        <li class="logo-list">
        <a href="/component/dashboard"> <img src="assets/images/logo-icon-2.png" alt="homepage" class="dark-logo" style="
        background: #233242;
        width: 103%;
        border-radius: 8px; width: 100%;"/>
        </a></li> 
        <!-- First level menu -->
        <li class="sidebar-item" [ngClass]="[sidebarnavItem.ddclass]" [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems" [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.title}}</div>
            <a class="sidebar-link waves-effect waves-dark" [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null" [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;" (mouseover)="addExpandClass(sidebarnavItem.title)" [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title}}</span>
            </a>
            <!-- Second level menu -->
            <ul aria-expanded="false" class="collapse first-level" *ngIf="sidebarnavItem.submenu.length > 0" [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li class="sidebar-item" *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu" [class.active]="showSubMenu === sidebarnavSubItem.title" [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
                    <a class="sidebar-link" [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]" [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'" [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink;"
                        (mouseover)="addActiveClass(sidebarnavSubItem.title)">
                        <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                        <span class="hide-menu">{{sidebarnavSubItem.title}}</span>
                    </a>
                    <!-- Third level menu -->
                    <ul aria-expanded="false" class="collapse second-level" *ngIf="sidebarnavSubItem.submenu.length > 0" [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
                        <li class="sidebar-item" *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu" routerLinkActive="active" [ngClass]="[sidebarnavSubsubItem.class]">
                            <a class="sidebar-link" [routerLink]="[sidebarnavSubsubItem.path]" *ngIf="!sidebarnavSubsubItem.extralink;" [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'router-link-active'">
                                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                                <span class="hide-menu">{{sidebarnavSubsubItem.title}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <li class="sidebar-item nav-item dropdown"   ngbDropdown placement="bottom-right">
            <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic" style="margin-right: -20px;" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class=" far fa-user font-18"></i>
                <!-- <span class="m-l-5 font-medium d-none d-sm-inline-block">{{fullName}}
                    <i class="mdi mdi-chevron-down"></i> 
                </span> -->
            </a>
            <div class="dropdown-menu dropdown-menu-right user-dd" ngbDropdownMenu>
                <span class="with-arrow">
                    <span class="bg-primary"></span>
                </span>
                <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                    <div class="m-l-10">
                        <!-- <h4 class="m-b-0">{{fullName}}</h4> -->
                        <!-- <p class=" m-b-0">{{email}}</p> -->
                    </div>
                </div>
                <div style="display: flex;justify-content: space-between;">
                    <a class="dropdown-item" href="component/user/profile">
                        <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
                    <!-- <h6 class="text-success mt-2 mr-3">{{role}}</h6> -->
                </div>
    
                <!-- <div class="dropdown-divider" *ngIf="(role!='Teacher') && (role!='Admin')"></div> -->
                <!-- <div style="display: flex;justify-content: space-between;" *ngIf="(role!='Teacher') && (role!='Admin') && (role!='Telecaller') && (role!='Blogger') && (role!='Moderator') && (role!='SalesAdmin') && (role!='Marketer')">
                    <a class="dropdown-item" href="ecom/orders">
                        <i class="ti-wallet m-r-5 m-l-5"></i> My Orders</a>
                </div> -->
                <!-- <a class="dropdown-item" href="javascript:void(0)">
                    <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>
                <a class="dropdown-item" href="javascript:void(0)">
                    <i class="ti-email m-r-5 m-l-5"></i> Inbox</a> -->
                <!-- <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript:void(0)">
                    <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a> -->
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="\logout">
                    <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
                <!-- <div class="dropdown-divider"></div>
                <div class="p-l-30 p-10">
                    <a href="component/user/profile" class="btn btn-sm btn-success btn-rounded">View Profile</a>
                </div> -->
            </div>
        </li>
    </ul>
</nav>