import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LiveStreamingService {
  public baseUrl: any = "/api/v1/";
  httpOptions: any;
  constructor(private http: HttpClient) {
    let accesstoken = localStorage.getItem("accessToken");
    if (
      accesstoken != null &&
      accesstoken != "" &&
      typeof accesstoken != undefined
    ) {
      this.httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
          "No-Auth": "True",
        }),
      };
    }
  }

  userConnect(userName) {
    return this.http.post("/api/v1/websocket/user-connect", { username: userName }, this.httpOptions);
  }

  userDisConnect(userName) {
    return this.http.post("/api/v1/websocket/user-disconnect", { username: userName }, this.httpOptions);
  }

  activeUsersExcept(user) {
    return this.http.get("/api/v1/websocket/active-users-except/" + user, this.httpOptions);
  }

  getAllQuestions(idLiveClass){
    return this.http.get("/api/v1/liveClass/getAllQuestions?idLiveClass="+idLiveClass, this.httpOptions);
  }

  getAllQuestionsAndAnswers(idLiveClass){
    return this.http.get("/api/v1/liveClass/getAllQuestionAndAnswer?idLiveClass="+idLiveClass, this.httpOptions);
  }

  getOngoingClasses(idLiveClass){
    return this.http.get("/api/v1/liveClass/ongoingliveclasses?idLiveClass="+idLiveClass, this.httpOptions);
  }

}