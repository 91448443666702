import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CreateProductPricingComponent } from '../create-product-pricing/create-product-pricing.component';



@Component({
  selector: 'app-create-product-amount',
  templateUrl: './create-product-amount.component.html',
  styleUrls: ['./create-product-amount.component.css']
})
export class CreateProductAmountComponent implements OnInit {
  productAmountForm: any;
  updateFlag = false;



  constructor(public http: HttpClient,private formBuilder: FormBuilder,private dialogRef: MatDialogRef<CreateProductPricingComponent>,private toaster: ToastrService,@Inject(MAT_DIALOG_DATA) public data) {
    if (data != null)
    {
     this.updateFlag = true;
    }

   }

  ngOnInit(): void {
    if(this.updateFlag){
      this.productAmountForm = this.formBuilder.group({
        idProductAmount: [this.data.idProductAmount],
        amount: [this.data.amount,[Validators.required]],
        amountName: [this.data.amountName, [Validators.required]],
        oldAmount: [this.data.oldAmount],
        amountCode: [this.data.amountCode, [Validators.required]],
      });
    } else {
      this.productAmountForm = this.formBuilder.group({
        idProductAmount: [null],
        amount: [null,[Validators.required]],
        amountName: [null, [Validators.required]],
        oldAmount: [null],
        amountCode: [null, [Validators.required]]
      });

    }
   
  }
  getAllProductAmount()
  {
    this.http.get('/api/v1/Product/product-amount',).subscribe((res: any) => {
      if (res.data != null) {
        console.log("result", res.data);
      }
    })
  }



  submit()
  {
    if(this.productAmountForm.valid)
    {
         
       if(this.updateFlag)
       {
        let payload = this.productAmountForm.value;
        console.log(payload);
        this.http.put('/api/v1/Product/'+payload.idProductAmount+'/product-amount',payload).subscribe((res: any) => {
          if (res.data != null) {
            this.toaster.success(res.message, "Success");
            this.dialogRef.close();
            this.getAllProductAmount();

          }
        },(error:any) =>
        {
          this.toaster.error(error['error'].message, "Error");
        });
       }
      else{

        let payload = this.productAmountForm.value;

        this.http.post('/api/v1/Product/product-amount',payload).subscribe((res: any) => {
          if (res.data != null) {
            this.toaster.success(res.message, "Success");
            this.dialogRef.close();
            this.getAllProductAmount();

          }
        },(error:any) =>
        {
          this.toaster.error(error['error'].message, "Error");
        });
      }
     
    }

    else{
      this.toaster.error("Please fill all the mandatory fields. ", "Error");
    }

  }

}
