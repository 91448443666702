import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-product-duration',
  templateUrl: './create-product-duration.component.html',
  styleUrls: ['./create-product-duration.component.css']
})
export class CreateProductDurationComponent implements OnInit {

  productDurationForm: any;
  updateFlag = false;



  constructor(public http: HttpClient,private formBuilder: FormBuilder,private dialogRef: MatDialogRef<CreateProductDurationComponent>,private toaster: ToastrService,@Inject(MAT_DIALOG_DATA) public data) {
    if (data != null)
    {
     this.updateFlag = true;
    }

   }

  ngOnInit(): void {
    if(this.updateFlag){
      this.productDurationForm = this.formBuilder.group({
        idProductDuration: [this.data.idProductDuration],
        duration: [this.data.duration,[Validators.required]],
        durationName: [this.data.durationName, [Validators.required]],
        durationCode: [this.data.durationCode],
        });
    } else {
      this.productDurationForm = this.formBuilder.group({
        idProductDuration: [null],
        duration: [null,[Validators.required]],
        durationName: [null, [Validators.required]],
        durationCode: [null],
      });

    }
   
  }

  submit()
  {
    if(this.productDurationForm.valid)
    {
         
       if(this.updateFlag)
       {
        let payload = this.productDurationForm.value;
        console.log('payload.....',payload);
        this.http.put('/api/v1/Product/'+payload.idProductDuration+'/product-duration',payload).subscribe((res: any) => {
          if (res.data != null) {
            this.toaster.success(res.message, "Success");
            this.dialogRef.close();
            this.getAllProductDuration();
          }
        },(error:any) =>
        {
          this.toaster.error(error['error'].message, "Error");
        });
       }
      else{

        let payload = this.productDurationForm.value;

        this.http.post('/api/v1/Product/product-duration',payload).subscribe((res: any) => {
          if (res.data != null) {
            this.toaster.success(res.message, "Success");
            this.dialogRef.close();
            this.getAllProductDuration();
          }
        },(error:any) =>
        {
          this.toaster.error(error['error'].message, "Error");
        });
      }
     
    }

    else{
      this.toaster.error("Please fill all the mandatory fields. ", "Error");
    }

  }
  getAllProductDuration()
  {
    this.http.get('/api/v1/Product/product-duration',).subscribe((res: any) => {
      if (res.data != null) {
        console.log("result", res.data);
      }
    })
  }

}
